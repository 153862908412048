/* eslint-disable import/no-anonymous-default-export */
import {
    GET_ALL_CATEGORY,
    GET_ALL_CATEGORY_FAIL,
    CREATE_CATEGORY,
    CREATE_CATEGORY_FAIL,
    GET_CATEGORY,
    GET_CATEGORY_FAIL,
    UPDATE_CATEGORY,
    UPDATE_CATEGORY_FAIL,
    DELETE_CATEGORY,
    DELETE_CATEGORY_FAIL,
} from '../constants/adminConstants';


const initialState = {
    categories : [],
    totalRows : 0,
    category : {},
    message : '',
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_CATEGORY:
            return {
                ...state,
                categories: payload.categories,
                totalRows : payload.totalRows,
            };
        case GET_ALL_CATEGORY_FAIL:
            return {
                ...state,
                // categories: [],
                message : payload.message ? payload.message : 'An error occured when trying to get all categories',
            };
        case CREATE_CATEGORY:
            return {
                ...state,
                otherCategory: payload.category,
            };
        case CREATE_CATEGORY_FAIL:
            return {
                ...state,
                // categories: [],
                message : payload.message ? payload.message : 'An error occured when trying to create category',
            };
        case GET_CATEGORY:
            return {
                ...state,
                otherCategory: payload.category,
            };
        case GET_CATEGORY_FAIL:
            return {
                ...state,
                message : payload.message ? payload.message : 'An error occured when trying to get this category',
            };
        case UPDATE_CATEGORY:
            return {
                ...state,
                otherCategory: payload.category,
            };
        case UPDATE_CATEGORY_FAIL:
            return {
                ...state,
                message : payload.message ? payload.message : 'An error occured when trying to get all categories',
            };
        case DELETE_CATEGORY:
        return {
            ...state,
            category: payload.category,
            message : 'Event ' + payload.category.name + ' deleted'
        };
        case DELETE_CATEGORY_FAIL:
            return {
                ...state,
                // categories: [],
                message : payload.message ? payload.message : 'An error occured when trying to delete the category',
            };
        default:
            return state;
    }
}