import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faSignOut } from '@fortawesome/free-solid-svg-icons';
import userServices from '../../../services/user/userServices';
import { UserContext } from '../../../context/userContext';
import InputField2 from '../../ui/InputField2';
import HintModal from '../Modal/HintModal';
import SubmitModal from '../Modal/SubmitModal';
import { Link } from 'react-router-dom';
import Loading from '../../ui/Loading';
import { sendNotify } from '../../../hooks/notify/notify';

function FormChallenges({ challenge, file }) {
  const { token } = useContext(UserContext);
  const [modalHint, setModalHint] = useState(false);
  const [modalSubmit, setModalSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [hint, setHint] = useState("No Hints!");
  const [files, setFiles] = useState([]);
  const [submit, setSubmit] = useState(null);
  const [error, setError] = useState({ challenge: "", submit: "" });
  const [loading, setLoading] = useState(true);

  const descriptionRef = useCallback(node => {
    if (node !== null) {
      node.innerHTML = challenge.description;
    }
  }, [challenge]);

  const fetchData = async () => {
    try {
      const result = await userServices.getHint(token, challenge._id);
      setHint(result);
    } catch (error) {
      setError({ ...error, challenge: "Invalid Request..." })
      console.error("Error loading data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    setFiles(file)
  }, []);

  // useEffect(() => {
  //   if (descriptionRef.current && challenge.description) {
  //     descriptionRef.current.innerHTML = challenge.description;
  //     console.log(challenge.description)
  //   }
  // }, [descriptionRef.current, challenge]);

  const showHintModal = () => setModalHint(true);
  const showSubmitModal = () => setModalSubmit(true);
  const hideModal = () => {
    setModalHint(false);
    setModalSubmit(false);
  };

  const handleDownloadClick = (filePath, fileName) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/${filePath}`)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error downloading the file: ', error);
      });
  };

  const renderFileList = () => {
    if (files.length === 0) {
      return <p>No files...</p>;
    }
    return (
      <ul className="list-disc pl-6">
        {files.map((file, index) => (
          <li key={index}>
            <button
              onClick={() => handleDownloadClick(file.path, file.originalname)}
              className="flex items-center hover:text-blue-500 hover:underline"
            >
              <FontAwesomeIcon icon={faFile} className="mr-2 text-blue-500" />
              {file.originalname}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const checkFlag = async () => {
    setSubmitLoading(true);
    const { status, message } = await userServices.checkFlag(token, challenge._id, {
      flag: submit
    });

    if (status === 200) {
      sendNotify('success', 'Submit Flag', "Correct flag submitted !");
      showSubmitModal();
    } else {
      sendNotify('error', 'Submit Flag', message);
      setError(prevErrors => ({ ...prevErrors, submit: message }));
    }
    setSubmitLoading(false);
  }

  const submitForm = () => {
    if (!submit) {
      setError(prevErrors => ({ ...prevErrors, submit: "required fields" }));
    } else {
      checkFlag();
    }
    // sendNotify("success", "submit", submit);
  };

  if (loading) {
    return <div className="h-[80vh] w-full flex justify-center items-center">
      <Loading />
    </div>;
  }
  if (error.challenge) {
    return <div className="h-[80vh] w-full flex flex-col gap-10 justify-center items-center">
      <p className='text-3xl font-bold'>{error.challenge}</p>
      <Link to={"/"} className="px-6 py-2 bg-blue-700 text-white hover:bg-blue-500">Back Home</Link>
    </div>;
  }

  return (
    <div className="w-full bg-gray-100/60 p-4 lg:shadow lg:shadow-gray-300 mb-10">
      <div className="lg:flex justify-between items-center hidden">
        <button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4" onClick={showHintModal}>
          {"Hint (cost : 99 pts)"}
        </button>
        <Link to={"/challenges"}>
          <FontAwesomeIcon icon={faSignOut} className="text-2xl p-6 text-red-500" />
        </Link>
      </div>

      <div className="flex flex-col lg:flex-row justify-between lg:mt-4">
        <div className="w-full p-6 lg:w-[68%] space-y-4 bg-white rounded-lg shadow-lg">
          <div className="lg:hidden flex justify-between items-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4"
              onClick={showHintModal}
            >
              {"Hint (cost : 99 pts)"}
            </button>
            <FontAwesomeIcon icon={faSignOut} className="text-2xl text-red-500" />
          </div>

          <h1 className="text-2xl font-semibold text-center">{challenge.name}</h1>
          <h3 className="text-2xl font-bold text-center">{challenge.value}</h3>

          {/* Description */}
          <div className="space-y-4">
            <h4 className="text-lg font-semibold underline">Description</h4>
            <div ref={descriptionRef}></div>
            <h4>Flag Format: epitech</h4>
          </div>

          <div className="flex items-start space-x-4">
            <InputField2
              type="text"
              name="name"
              placeholder="Enter your submission"
              value={submit}
              error={error.submit}
              onChange={(e) => setSubmit(e.target.value
              )}
              onBlur={() =>
                setError((prevErrors) => ({ ...prevErrors, submit: "" }))
              }
            />
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
              onClick={() => {
                !submitLoading && submitForm();
              }}
            >
              {submitLoading ?
                <div className='flex justify-center px-5'>
                  <Loading size={20} />
                </div>
                : "Submit"}
            </button>
          </div>

          <div className="block lg:hidden w-full">
            <div className="text-xl text-white text-center bg-blue-500 w-full p-2">
              Files
            </div>
            <div className="items-center p-2 w-full">
              <div>
                {/* <h4 className="text-lg font-semibold"><FontAwesomeIcon icon={faUpload} className="text-md text-black mr-4" /> Selected Files:</h4> */}
                {renderFileList()}
              </div>
            </div>
          </div>
        </div>

        <div className="hidden lg:block w-[30%] border-l-2">
          <div className="text-xl text-white text-center bg-blue-500 w-full p-2">
            Files
          </div>
          <div className="items-center p-2 w-full">
            <div>
              {/* <h4 className="text-lg font-semibold"><FontAwesomeIcon icon={faUpload} className="text-md text-black mr-4" /> Selected Files:</h4> */}
              {renderFileList()}
            </div>
          </div>
        </div>
      </div>

      <HintModal visible={modalHint} onClose={hideModal} message={hint} />
      <SubmitModal visible={modalSubmit} onClose={hideModal} response={submit} challenge={challenge} />
    </div>
  );
}

export default FormChallenges;