import axios from 'axios';
//import "dotenv";

const API_URL = process.env.REACT_APP_API_URL;

const getAllChallenges = async (token, q, page, perPage, order) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    try {
        const response = await axios
            .get(API_URL + '/challenges?q=' + q + '&page=' + page + '&perPage=' + perPage + '&order=' + order, { headers });
        return response.data;
    } catch (err) {
        throw err.response;
    }
};

const getChallenge = async (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    try {
        const response = await axios
            .get(API_URL + '/challenges/' + id, { headers });
        return response.data;
    } catch (err) {
        throw err.response;
    }
};

const getChallengeSolvers = async (token, q, page, perPage, order, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    try {
        const response = await axios
            .get(API_URL + '/challenges/' + id + '/solvers?q=' + q + '&page=' + page + '&perPage=' + perPage + '&order=' + order, { headers });
        return response.data;
    } catch (err) {
        throw err.response;
    }
};

const createChallenge = async (token, challenge) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data'
    };

    try {
        const response = await axios.post(API_URL + '/challenges/', challenge, { headers });
        return response.data;
    } catch (err) {
        throw err.response;
    }
}

const updateChallenge = async (token,id, challenge) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data'
    };
    try {
        const response = await axios.put(API_URL + '/challenges/' + id, challenge, { headers });
        return response.data;
    } catch (err) {
        throw err.response;
    }
};

const deleteChallenge = async (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    try {
        const response = await axios.delete(API_URL + '/challenges/' + id, { headers });
        return response.data;
    } catch (err) {
        throw err.response;
    }
};

const deleteChallengeFiles = async (token, files) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    try {
        const response = await axios.post(API_URL + '/files/delete', files, { headers });
        return response.data;
    } catch (err) {
        throw err.response;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAllChallenges,
    getChallenge,
    updateChallenge,
    deleteChallenge,
    createChallenge,
    deleteChallengeFiles,
    getChallengeSolvers
};