import {
    GET_ALL_CHALLENGES,
    GET_ALL_CHALLENGES_FAIL,
    GET_CHALLENGE,
    GET_CHALLENGE_FAIL,
    GET_CHALLENGE_SOLVERS,
    GET_CHALLENGE_SOLVERS_FAIL,
    UPDATE_CHALLENGE,
    UPDATE_CHALLENGE_FAIL,
    DELETE_CHALLENGE,
    DELETE_CHALLENGE_FAIL,
    CREATE_CHALLENGE,
    CREATE_CHALLENGE_FAIL,
    DELETE_CHALLENGE_FILES,
    DELETE_CHALLENGE_FILES_FAIL
} from '../constants/adminConstants';

import AdminService from '../../services/admin/admin-challenge-services';

import store from "..";

export const getAllChallenges = (token, q, page, perPage, order) => (dispatch) => {
    return AdminService.getAllChallenges(token, q, page, perPage, order).then(
        (data) => {
            store.dispatch({
                type: GET_ALL_CHALLENGES,
                payload: {
                    challenges: data.challenges,
                    currentRows: data.currentRows,
                    totalRows: data.totalRows,
                },
            });

            return Promise.resolve();
        }
    )
        .catch((error) => {
            store.dispatch({
                type: GET_ALL_CHALLENGES_FAIL,
                payload: { message: error.data.message, },
            });
            return Promise.reject();
        });
};


export const getChallenge = (token, id) => (dispatch) => {
    return AdminService.getChallenge(token, id).then(
        (data) => {
            store.dispatch({
                type: GET_CHALLENGE,
                payload: {
                    challenge: data,
                },
            });

            return Promise.resolve();
        }
    )
        .catch((error) => {
            store.dispatch({
                type: GET_CHALLENGE_FAIL,
                payload: { message: error.data.message, },
            });
            return Promise.reject();
        });
};

export const getChallengeSolvers = (token, q, page, perPage, order, id) => (dispatch) => {
    return AdminService.getChallengeSolvers(token, q, page, perPage, order, id).then(
        (data) => {
            store.dispatch({
                type: GET_CHALLENGE_SOLVERS,
                payload: {
                    solvers: data.solvers,
                    currentRows: data.currentRows,
                    totalRows: data.totalRows,
                },
            });

            return Promise.resolve();
        }
    )
        .catch((error) => {
            store.dispatch({
                type: GET_CHALLENGE_SOLVERS_FAIL,
                payload: { message: error.data.message, },
            });
            return Promise.reject();
        });
};

export const createChallenge = (token, challenge) => (dispatch) => {

    return AdminService.createChallenge(token, challenge).then(
        (data) => {
            store.dispatch({
                type: CREATE_CHALLENGE,
                payload: {
                    challenge: data
                }
            })
            return Promise.resolve();
        }
    ).catch((error) => {
        store.dispatch({
            type: CREATE_CHALLENGE_FAIL,
            payload: { message: error.data.message, },
        });
        return Promise.reject();
    });
}

export const updateChallenge = (token, id, challenge) => (dispatch) => {
    return AdminService.updateChallenge(token, id, challenge).then(
        (data) => {
            store.dispatch({
                type: UPDATE_CHALLENGE,
                payload: { challenge: data },
            });

            return Promise.resolve();
        }
    )
        .catch((error) => {
            store.dispatch({
                type: UPDATE_CHALLENGE_FAIL,
                payload: { message: error.data.message, },
            });
            return Promise.reject();
        });
};

export const deleteChallenge = (token, id) => (dispatch) => {
    return AdminService.deleteChallenge(token, id).then(
        (data) => {
            store.dispatch({
                type: DELETE_CHALLENGE,
                payload: { challenge: data },
            });

            return Promise.resolve();
        }
    )
        .catch((error) => {
            store.dispatch({
                type: DELETE_CHALLENGE_FAIL,
                payload: { message: error.data.message, },
            });
            return Promise.reject();
        });
};

export const deleteChallengeFiles = (token, files) => (dispatch) => {
    return AdminService.deleteChallengeFiles(token, files).then(
        (data) => {
            store.dispatch({
                type: DELETE_CHALLENGE_FILES,
                payload: { message: data.message },
            })

            return Promise.resolve();
        }
    )
        .catch((error) => {
            store.dispatch({
                type: DELETE_CHALLENGE_FILES_FAIL,
                payload: { message: error.data.message, },
            });
            return Promise.reject();
        });
}