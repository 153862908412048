import React from "react";
import COVER from "../../assets/Logo/logo-light.png";
import { Link } from "react-router-dom";

function AuthWrapper({ children }) {
  return (
    <div className="w-full h-screen lg:flex items-start">
      <div className="relative w-full h-full lg:flex lg:w-1/2 hidden flex-col bg-[#2693d0] px-[80px]">
        <div
          to="/"
          className="flex flex-col justify-center items-center h-full"
        >
          <Link
            to="/"
          >
            <img src={COVER} className="max-w-full max-h-full" alt="cover" />
          </Link>
        </div>
      </div>

      <div className="w-full h-full mx-auto lg:flex lg:w-1/2 bg-[#fff] flex flex-col  items-center lg:overflow-auto">
        {children}
      </div>
    </div>
  );
}

export default AuthWrapper;
