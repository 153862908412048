import CardHero from "../../components/layouts/home/CardHero";
import CardServices from "../../components/layouts/home/CardServices";
import CardAbout from "../../components/layouts/home/CardAbout";
import CardTestimony from "../../components/layouts/home/CardTestimony";
import Module from "../../components/layouts/home/Module";
import Footer from "../../components/layouts/home/Footer";
import CardNewsletter from "../../components/layouts/home/CardNewsletter";
import UserWrapper from "../../components/user/common/UserWrapper";
import CardEvents from "../../components/user/events/CardEvents";
import Module1 from "../../assets/Pictures/module1.jpg";
import Module2 from "../../assets/Pictures/module2.jpg";
import Module3 from "../../assets/Pictures/module3.jpg";

function HomeScreen() {
  return (
    <UserWrapper>
      {/* Hero */}
      <CardHero />

      {/* Services */}
      {/* <CardServices /> */}



      {/* Testimony */}
      <CardTestimony />

      {/* About */}
      <CardAbout />

      {/* Contents */}
      {/* <section id="content" className="w-full flex flex-row px-4 md:px-16 py-4">
        <Module />
      </section> */}

      {/* Contents */}
      <div className="text-4xl text-blue-700 font-semibold text-center my-8">Best Competition</div>
      <section id="events" className="w-full flex justify-center items-center gap-10 flex-wrap mb-20">
        <CardEvents banner={Module1} type={1} id={1} />
        <CardEvents banner={Module2} type={2} id={2} />
        <CardEvents banner={Module3} type={3} id={3} />
      </section>


      {/* Footer */}
      {/* <CardNewsletter /> */}
      <Footer />
    </UserWrapper>

  );
}

export default HomeScreen;
