import { combineReducers } from 'redux';
import authReducer from './authReducer';
import adminUsersReducer from './adminUsersReducer';
import adminEventsReducer from './adminEventsReducer';
import adminCategoryReducer from './adminCategoryReducer';
import adminChallengesReducer from './adminChallengesReducer';
import adminSubmissionsReducer from './adminSubmissionsReducer';


const rootReducer = combineReducers({
    auth : authReducer,
    adminUser : adminUsersReducer,
    adminEvent: adminEventsReducer,
    adminCategory: adminCategoryReducer,
    adminChallenge: adminChallengesReducer,
    adminSubmission: adminSubmissionsReducer
   })

export default rootReducer;
// export default authReducer;