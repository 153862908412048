/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Register
const register = async (data) => {
    try {
        await axios.post(`${API_URL}/users`, data);
        return { status: 200 };
    } catch (error) {
        console.error("An error occurred:", error);
        return { status: error.response.status };

    }
};

// Login
const login = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/users/login`, data);
        return { data: response.data, status: 200, message: "" };
    } catch (error) {
        console.error("An error occurred:", error);
        return { data: null, status: error.response.status, message: error.response.data.message };

    }
};

// Active Account 
const checkAccount = async (key) => {
    try {
        const response = await axios.get(`${API_URL}/users/confirm/${key}`);
        return response.status
    } catch (error) {
        console.error("An error occurred:", error);
        return error.response.status;

    }
};

// Reset Password 
const checkPassword = async (key) => {
    try {
        const response = await axios.get(`${API_URL}/users/reset/${key}`);
        return response.status
    } catch (error) {
        console.error("An error occurred:", error);
        return error.response.status;

    }
};

// Forgot Password
const forgotPassword = async (email) => {
    try {
        const response = await axios.post(`${API_URL}/users/forget`, { email: email });
        return response.status
    } catch (error) {
        console.error("An error occurred:", error);
        return error.response.status;

    }
};

// User Info
const userInfo = async (token) => {
    try {
        const response = await axios.get(`${API_URL}/users/me`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        return response.data
    } catch (error) {
        console.error("An error occurred:", error);
    }
};

export default {
    login,
    register,
    checkAccount,
    forgotPassword,
    checkPassword,
    userInfo

};
