import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";

const CountrySelect2 = ({country, onCountryChange, error, onBlur, label}) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(country);

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const formattedCountries = response.data.map((country) => ({
          value: country.cca3,
          label: country.name.common,
          flag: country.flags.png,
        }));
        setCountries(formattedCountries);
        const currentCountry = formattedCountries.find(item => item.label === country);
        setSelectedCountry(currentCountry);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    onCountryChange(selectedOption); // Passer la valeur sélectionnée au composant parent
  };

  return (
    <div className="w-full text-gray-900">
      {label && <label className="block mb-2 text-sm font-medium text-gray-900">{label}</label>}
      <Select
        value={selectedCountry}
        onChange={handleCountryChange}
        options={countries}
        placeholder="Selectionnez le pays"
        onBlur={onBlur}
        maxMenuHeight={200}
        getOptionLabel={(option) => (
          <div className="w-full flex">
            <img
              src={option.flag}
              alt={`${option.label} flag`}
              style={{ width: "30px", marginRight: "10px" }}
            />
            <span> {option.label}</span>
          </div>
        )}
      />
      {error && <div className="text-[#ff0000]">{error}</div>}
    </div>
  );
};

export default CountrySelect2;
