import React, { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import UserWrapper from "../../components/user/common/UserWrapper";
import userServices from "../../services/user/userServices";
import Loading from "../../components/ui/Loading";
import CardChallenges from "../../components/user/challenges/CardChallenges";
import { UserContext } from "../../context/userContext";
import FormChallenges from "../../components/user/challenges/FormChalleges";

function DetailChallengesScreen() {
  const { id } = useParams();
  const { user, token } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ challenge: "", submit: "" });
  const [data, setData] = useState({
    challenge: [],
    solved: false,
    require: [],
    files: []
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await userServices.getChallenge(token, id);

        const requireByUser = result.challenge.requirements
          .filter(element => !element.solvers.users.some(item => item._id === user._id));

        const challengeData = {
          challenge: result.challenge,
          solved: result.challenge.solvers.users.some(solver => solver._id === user._id),
          require: requireByUser,
          files: result.files
        };

        setData(challengeData);
      } catch (error) {
        setError({ ...error, challenge: "Invalid Request..." });
      } finally {
        setLoading(false);
      }
    };



    fetchData();
  }, [id, user._id]);

  return (
    <UserWrapper>
      {/* Loading */}
      {loading && (
        <div className="h-[80vh] w-full flex justify-center items-center">
          <Loading />
        </div>
      )}

      {/* Challenge Solved */}
      {data.solved && (
        <div className="h-[80vh] w-full flex flex-col gap-10 justify-center items-center px-4">
          <p className="text-xl lg:text-3xl text-center font-semibold">You have already solved this challenge!</p>
          <Link to="/challenges" className="px-6 py-2 bg-blue-700 text-white hover:bg-blue-500">
            Back
          </Link>
        </div>
      )}

      {/* Challenge Require */}
      {data.require.length !== 0 && (
        <div className="px-4 pt-10 flex flex-col justify-center items-center">
          <h1 className="text-xl font-semibold text-blue-700">Challenges Required</h1>
          <p className="text-base text-black/60 text-center my-4">These challenges must be completed to finish the current challenge.</p>
          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
            {data.require.map(item => (
              <div key={item._id} className="col-span-1 md:col-span-1">
                <CardChallenges challenge={item} require={true} />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Challenges form */}
      {!loading && !data.solved && data.require.length === 0 &&
        <div className="w-full lg:w-2/3 flex flex-col justify-center items-center mx-auto p-4 overflow-y-auto">
          <FormChallenges challenge={data.challenge} file={data.files} />
        </div>
      }
    </UserWrapper>
  );
}

export default DetailChallengesScreen;
