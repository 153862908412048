import { faCircleInfo, faClose, faInfo, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ChallengeModal({ visible, data, onClose }) {

  return (
    <div className={`fixed inset-0 flex items-center justify-center z-50 ${visible ? '' : 'hidden'}`} >
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="mx-4 lg:mx-0 lg:w-2/3 bg-white p-6 rounded-sm shadow-lg z-10">
        {/* 1 */}
        <div className="border-b-[1px] border-gray-300 pb-4">
          <div className="w-full flex justify-between items-center text-xl font-bold">
            <div className="">{data.name}</div>
            <div className="">
              <FontAwesomeIcon
                icon={faUser}
                className="text-black"
              /> | {`${data.value} points`}
              <FontAwesomeIcon
                icon={faClose}
                className="text-black ml-4 cursor-pointer hover:text-red-500"
                onClick={onClose}
              />
            </div>
          </div>
          <div className="text-md text-black/60 font-semibold mt-6">
            Category: <span className="p-2 bg-red-500 text-white font-normal rounded-lg">{data.category.name}</span>
          </div>
        </div>
        {/* 2 */}
        <div className="w-full flex  py-4">
          <div className="w-2/3">
            <div className="w-full pb-4 border-b-[1px] border-gray-300">
              <h3 className="text-md text-black/60 font-semibold ">Description</h3>
              <p className="text-justify">klcvvldbldbvldbsvlbdvdslvvjdsbvkdvsbkjdjvkdbsvkjdbskjvdsbkjvdbvkjdbvkjdbkjdbvkjjfvfjvjbvhjfvhjfvhjfbvhjfvhjhjdsvbhjdsv</p>
            </div>
          </div>
          <div className="w-1/3 flex justify-start items-center text-md text-black/60 font-semibold border-l-2 border-gray-300 px-4">
            Hints  <FontAwesomeIcon
              icon={faCircleInfo}
              className="text-xl text-blue-500 ml-4 cursor-pointer"
              // onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChallengeModal