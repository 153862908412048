import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function CardAbout() {
  return (
    <section id="about" className="bg-blue-900 text-white py-16 px-5">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">About Us</h2>
        <p className="text-lg mb-8">
          Discover who we are and our mission in cybersecurity.
        </p>
        <div className="flex flex-col gap-4 lg:flex-row lg:gap-0 justify-center items-center lg:space-x-4">
          <FontAwesomeIcon icon={faInfoCircle} className="text-5xl" />
          <p className="max-w-md lg:text-left">
            Our team of cybersecurity experts is dedicated to providing you
            with the best challenges and training to enhance your skills.
          </p>
        </div>
      </div>
    </section>
  );
}

export default CardAbout;
