import {
    GET_ALL_USERS,
    GET_ALL_USERS_FAIL,
    GET_USER,
    GET_USER_FAIL,
    UPDATE_USER,
    UPDATE_USER_FAIL,
    DELETE_USER,
    DELETE_USER_FAIL,
    MAKE_ADMIN,
    MAKE_ADMIN_FAIL,
    ENABLE_USER,
    ENABLE_USER_FAIL,
    EXPORT_USERS_TABLE,
    EXPORT_USERS_TABLE_FAIL,
    CREATE_USER,
    CREATE_USER_FAIL
} from '../constants/adminConstants';

import AdminService from '../../services/admin/admin-user-services';

import store from "..";
// import { Download } from '@mui/icons-material';

import {exportToCSV} from '../../hooks/exportCSV/ExportCSV'

export const getAllUsers = (token, q, page, perPage, order) => (dispatch) => {
    return AdminService.getAllUsers(token, q, page, perPage, order).then(
        (data) => {
            store.dispatch({
                type: GET_ALL_USERS,
                payload: { 
                    users : data.users,
                    currentRows : data.currentRows,
                    totalRows : data.totalRows,
                },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: GET_ALL_USERS_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};


export const getUser = (token, id) => (dispatch) => {
    return AdminService.getUser(token, id).then(
        (data) => {
            store.dispatch({
                type: GET_USER,
                payload: { 
                    user : data,
                },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: GET_USER_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};

export const createUser = (token, user) => (dispatch) =>{

    return AdminService.createUser(token, user).then(
        (data) => {
            store.dispatch({
                type: CREATE_USER,
                payload: {
                    user: data
                }
            })
            return Promise.resolve();
        }
    ).catch((error) => {
        store.dispatch({
            type: CREATE_USER_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
}

export const updateUser = (token, user) => (dispatch) => {
    return AdminService.updateUser(token, user).then(
        (data) => {
            store.dispatch({
                type: UPDATE_USER,
                payload: { user : data },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: UPDATE_USER_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};


export const deleteUser = (token, id) => (dispatch) => {
    return AdminService.deleteUser(token, id).then(
        (data) => {
            store.dispatch({
                type: DELETE_USER,
                payload: { user : data },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: DELETE_USER_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};


export const makeAdmin = (token, id) => (dispatch) => {
    return AdminService.makeAdmin(token, id).then(
        (data) => {
            store.dispatch({
                type: MAKE_ADMIN,
                payload: { user : data },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: MAKE_ADMIN_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};


export const enableUser = (token, id) => (dispatch) => {
    return AdminService.enableUser(token, id).then(
        (data) => {
            store.dispatch({
                type: ENABLE_USER,
                payload: { user : data },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: ENABLE_USER_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};


export const exportUsersExcel = (token) => (dispatch) => {
    return AdminService.exportUsersExcel(token).then(
        (data) => {

            exportToCSV(data, 'users-list-' + Date.now())
            
            store.dispatch({
                type: EXPORT_USERS_TABLE,
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: EXPORT_USERS_TABLE_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};