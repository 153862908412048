/* eslint-disable react-hooks/exhaustive-deps */
import {
  faBars,
  faBell,
  faClose,
  faEnvelope,
  faSignIn,
  faSignInAlt,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import COVER from "../../assets/Logo/logo-light.png";
import PROFIL from "../../assets/profil.jpg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../../context/userContext";
import userServices from "../../services/user/userServices";

const NavbarWrapper = () => {
  const { user, token } = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(false);
  const [connect, setConnect] = useState(null);
  const [showNotificationDropdown, setShowNotificationDropdown] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(true);
  const [notify, setNotify] = useState([]);
  const [error, setError] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleNotificationDropdown = () => {
    setShowNotificationDropdown(!showNotificationDropdown);
    setShowProfileDropdown(false);
  };

  const toggleProfileDropdown = () => {
    setShowProfileDropdown(!showProfileDropdown);
    setShowNotificationDropdown(false);
  };

  const fetchData = async () => {
    try {
      const res = await userServices.getNotify(token);
      if (res.length === 0) {
        setNotify(res.notifications);
      } else {
        setError("No Notification...");
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setError("Error loading notifications. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Gestion des états
  useEffect(() => {
    if (user) {
      setConnect(true)
      setUserName(user.name)

    } else {
      setConnect(false)
      setUserName("John Doe")
    }
    fetchData();
  }, [connect])


  // Notifications
  const renderList = () => {
    if (notify.length === 0) {
      return (
        <div className="">
          <p className="px-4 py-2 text-black/60 text-center">No Notification...</p>
          <Link
            to="/notification"
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            onClick={toggleNotificationDropdown}
          >
            All Notification
          </Link>
        </div>)
    }
    return (
      <div className="">
        {/* Contenu de notifications avec des icônes */}
        {notify.map((item, index) => (
          <Link
            to={`/notification/${item._id || index}`}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            onClick={toggleNotificationDropdown}
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              className="mr-2 text-blue-500"
            />{" "}
            [ Welcome to EPITECH]
          </Link >
        ))}
        <Link
          to="/notification"
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        >
          All Notification
        </Link>
      </div >
    );
  };

  return (
    <nav className="bg-blue-950 text-white p-4 m-0 fixed lg:top-0 w-full h-20 z-10">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/">
            <img src={COVER} className="w-40" alt="Logo" />
          </Link>
        </div>
        {/* List menu */}
        <div className="hidden lg:flex lg:items-center lg:justify-center space-x-4">
          {/* <Link to="#" className="hover:text-blue-500">
            Learning
          </Link> */}
          <Link to="/challenges" className="hover:text-blue-500">
            Practice
          </Link>
          <Link to="/events" className="hover:text-blue-500">
            Competition
          </Link>
          <Link to="/bluelock" className="hover:text-blue-500">
            BlueLock
          </Link>
          <Link to="/about" className="hover:text-blue-500">
            About Us
          </Link>
        </div>

        <div className="flex items-center justify-end space-x-3">
          {connect ?
            <>
              {/* Notification */}
              <div className="relative inline-block text-left">
                <button
                  className="text-white p-2 rounded-full text-xl font-semibold focus:outline-none relative"
                  onClick={toggleNotificationDropdown}
                >
                  <FontAwesomeIcon icon={faBell} />
                  {!showNotificationDropdown && notify.length !== 0 && (
                    <span className="absolute top-0 right-0 bg-red-500 text-white w-4 h-4 rounded-full text-xs flex items-center justify-center">
                      !
                    </span>
                  )}
                </button>
                {/* Menu déroulant de notifications */}
                {showNotificationDropdown && (
                  <>
                    <div className="fixed inset-0 bg-black opacity-50" onClick={toggleNotificationDropdown}></div>
                    <div className="origin-top-right absolute right-0 mt-2 w-[200px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        {renderList()}
                      </div>
                    </div>
                  </>


                )}
              </div>
              {/* Profil */}
              <div className="relative text-left">
                <div className="flex cursor-pointer justify-center items-center gap-3" onClick={toggleProfileDropdown}>
                  <button
                    className="text-white rounded-full text-xl font-semibold focus:outline-none relative"

                  >
                    {/* <img src={PROFIL} alt="Profil" className="w-10 h-10 rounded-full" /> */}
                    <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
                      {/* <span className="font-medium text-gray-600">{user.name.charAt(0)}</span> */}
                      <img src={`https://ui-avatars.com/api/?name=${user.name}&background=2693d0&color=fff&font-size=0.5&rounded=true&bold=true`} alt="profil" className="w-full" />

                    </div>
                    {/* <FontAwesomeIcon icon={faUser} /> */}
                    {!showProfileDropdown && (
                      <span className="absolute top-0 right-0 bg-green-500 text-white w-2 h-2 rounded-full text-xs flex items-center justify-center"></span>
                    )}
                  </button>
                  {/* Afficher le nom de l'utilisateur */}
                  <span className="hidden lg:block text-md font-semibold capitalize ">
                    {userName}
                  </span>{" "}

                </div>
                {/* Menu déroulant de profil */}
                {showProfileDropdown && (
                  <>
                    <div className="fixed inset-0 bg-black opacity-50" onClick={toggleProfileDropdown}></div>
                    <div className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <Link
                          to="/profil"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          onClick={toggleProfileDropdown}
                        >
                          Profil
                        </Link>
                        <Link
                          to="/logout"
                          className="block px-4 py-2 text-sm text-red-700 hover:bg-red-100 hover:text-red-900"
                          onClick={toggleProfileDropdown}

                        >
                          Logout
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
            :
            <>
              {/* Authentification */}
              <div className="hidden lg:flex lg:items-center lg:justify-center space-x-4">
                <Link to="/auth/login">
                  <button className="bg-white text-blue-900 py-2 px-4 rounded-full text-sm font-semibold hover:bg-blue-100">
                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Login
                  </button>
                </Link>
                <Link to="/auth/register">
                  <button className="bg-white text-blue-900 py-2 px-4 rounded-full text-sm font-semibold hover:bg-blue-100">
                    <FontAwesomeIcon icon={faUserPlus} className="mr-2" /> Register
                  </button>
                </Link>
              </div>
            </>
          }




          {/* Hamburger */}
          <button onClick={toggleMenu} className="lg:hidden text-white">
            {isOpen ? (
              <FontAwesomeIcon icon={faClose} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </button>
        </div>
      </div>
      {isOpen && (
        // List Menu
        <div className="lg:hidden w-full flex flex-col gap-3 mt-2 justify-center items-center bg-gray-900">
          {connect && <Link
            to="/profil"
            className="w-full text-center hover:text-white px-5 py-2 bg-blue-500 hover:bg-blue-500"
          >
            {userName}
          </Link>}
          {/* <Link
            to=""
            className="text-center hover:text-white w-full px-5 py-2 hover:bg-blue-500"
          >
            Learning
          </Link> */}
          <Link
            to="/challenges"
            className="text-center hover:text-white w-full px-5 py-2 hover:bg-blue-500"
          >
            Practice
          </Link>
          <Link
            to="/events"
            className="text-center hover:text-white w-full px-5 py-2 hover:bg-blue-500"
          >
            Competition
          </Link>
          <Link
            to=""
            className="text-center hover:text-white w-full px-5 py-2 hover:bg-blue-500"
          >
            BlueLock
          </Link>
          <Link
            to="/about"
            className="text-center hover:text-white w-full px-5 py-2 hover:bg-blue-500"
          >
            About Us
          </Link>
          {!connect &&
            (<>
              <Link
                to="/auth/register"
                className="text-center hover:text-white w-full px-5 py-2 hover:bg-blue-500 space-x-2"
              >
                <FontAwesomeIcon icon={faUser} /> <span>Sign Up</span>
              </Link>
              <Link
                to="/auth/login"
                className="text-center hover:text-white w-full px-5 py-2 hover:bg-blue-500 space-x-2"
              >
                <FontAwesomeIcon icon={faSignIn} /> <span>Sign In</span>
              </Link>
            </>
            )
          }
          {connect && <Link
            to="/logout"
            className="text-center hover:text-white w-full px-5 py-2 bg-red-500 hover:bg-red-500"
          >
            Logout
          </Link>}
        </div>
      )}
    </nav>
  );
};

export default NavbarWrapper;
