import React from 'react';
import PropTypes from 'prop-types';

const HintModal = ({ visible, message, onClose }) => {
  return (
    <div className={`fixed inset-0 flex items-center justify-center z-50 ${visible ? '' : 'hidden'}`} >
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="mx-4 lg:mx-0  text-center lg:w-1/3 bg-white p-8 rounded-lg shadow-lg z-10">
        <span className="absolute top-0 right-0 p-4 cursor-pointer" onClick={onClose}>&times;</span>
        <p className="text-md lg:text-xl mb-4">{message}</p>
        <div className="flex justify-center">
          {onClose && <button className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded mr-4" onClick={onClose}>Cancel</button>}
        </div>
      </div>
    </div>
  );
};

HintModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default HintModal;
