import React from 'react';
import Card from '../../components/layouts/about/Card';
import IMG from "../../assets/profil.jpg"
import UserWrapper from '../../components/user/common/UserWrapper';
import Footer from '../../components/layouts/home/Footer';

const developers = [
  { name: 'Développeur 1', role: 'Frontend Developer', image: IMG },
  { name: 'Développeur 2', role: 'UI/UX Designer', image: IMG },
  { name: 'Développeur 1', role: 'Frontend Developer', image: IMG },
  { name: 'Développeur 2', role: 'UI/UX Designer', image: IMG },
  { name: 'Développeur 2', role: 'UI/UX Designer', image: IMG },
  { name: 'Développeur 1', role: 'Frontend Developer', image: IMG },
  { name: 'Développeur 2', role: 'UI/UX Designer', image: IMG },
  { name: 'Développeur 1', role: 'Frontend Developer', image: IMG },
  { name: 'Développeur 2', role: 'UI/UX Designer', image: IMG },
  { name: 'Développeur 2', role: 'UI/UX Designer', image: IMG },
  { name: 'Développeur 2', role: 'UI/UX Designer', image: IMG },
  { name: 'Développeur 2', role: 'UI/UX Designer', image: IMG },
  // Ajoutez d'autres développeurs selon vos besoins
];

const AboutUS = () => {
  return (
    <UserWrapper>
      <div className="bg-white py-4 px-10">
        <h1 className="text-3xl text-center text-black/80 font-bold mb-6">Our Team</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-8 my-10">
          {developers.map((developer, index) => (
            <Card key={index} {...developer} />
          ))}
        </div>
      </div>
      <Footer />
    </UserWrapper>
  );
};

export default AboutUS;
