/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import Loading from "../components/ui/Loading";
import { useContext, useEffect } from "react";
import { UserRole } from "../constants/common/userRole";
import { UserContext } from "../context/userContext";

const InitialRedirect = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const redirect = () => {
        if (user) {
            switch (user.type) {
                case UserRole.ADMIN:
                    navigate('/admin/dashboard');
                    break;

                case UserRole.USER:
                    navigate('/home');
                    break;
                default:
                    break;
            }

        } else {
            navigate("/home");
        }
    }
    useEffect(() => {
        redirect();
    }, [])

    return (
        <div className="h-screen w-screen flex justify-center items-center">
            <Loading />
        </div>
    )
}

export default InitialRedirect;