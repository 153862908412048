import React from "react";

function ProgessBar({ category }) {

  let percentage = 0;
  if (category.nbChallenges !== 0) {
    percentage = (category.solved / category.nbChallenges) * 100;
  }
  return (
    <div class="relative pt-1">
      <div class="mb-2 flex items-center justify-between text-xs">
        <div class="text-gray-600">{category.category.name}</div>
        <div class="text-gray-600">{percentage}%</div>
      </div>
      <div class="flex h-2 overflow-hidden rounded bg-gray-100 text-xs">
        <div
          style={{ width: `${percentage}%` }}
          class="bg-[#172554] animate-progressBar"
        ></div>
      </div>
    </div>
  );
}

export default ProgessBar;
