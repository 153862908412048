import React, { useContext, useEffect, useState } from "react";
import AdminWrapper from "../../components/admin/AdminWrapper";
import Tabs from "../../components/ui/tabs/Tabs";
import Separator from "../../components/admin/Separator";
import { getLocalStorage } from "../../utils/storage";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getChallenge } from "../../store/actions/adminChallengesActions";
import store from "../../store";
import { sendNotify } from "../../hooks/notify/notify";
import Loading from "../../components/ui/Loading";
import DetailSectionInfo from "../../components/admin/challenges/DetailSectionInfo";
import ChallengeModal from "../../components/admin/challenges/ChallengeModal";
import { UserContext } from "../../context/userContext";
import DetailSectionSubmission from "../../components/admin/challenges/DetailSectionSubmission";

const initialState = {
    challenge: null,
    perPage: 10,
    page: 1,
    totalRows: 0,
    order: 'asc',
}

function DetailChallengeScreen() {
    let [state, setState] = useState(initialState);
    const [showModal, setshowModal] = useState(false);
    const [modalType, setModalType] = useState("edit");
    const [currentTab, setCurrentTab] = useState("Info")
    const { token } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const challengeId = useParams()?.id;

    const toggleModal = (type) => {
        setModalType(type);
        if (showModal === true) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setshowModal(!showModal); //
    }

    const onDismiss = (e) => {
        if (e.target === e.currentTarget) {
            toggleModal(e);
        }
    }

    async function getOneChallenge() {
        dispatch(getChallenge(token, challengeId))
            .then(() => {
                setState({ ...state, challenge: store.getState().adminChallenge.otherChallenge })
                setLoading(false);
            })
            .catch(() => {
                sendNotify('error', 'Challenges error !', store.getState().adminChallenge.message)
            })
    }

    useEffect(() => {
        getOneChallenge();
    }, [])

    return (
        <>
            <AdminWrapper>
                {!loading ?
                    <>
                        <div className="pb-5 flex flex-col items-center justify-center bg-[#2693d0]">
                            <div className="flex flex-col items-center space-y-3 mt-5">
                                <h1 className="cursor-pointer select-none text-white text-4xl font-medium">{state.challenge.challenge.name}</h1>
                                <h1 className="text-2xl text-white font-medium">{state.challenge.challenge.category.name}</h1>
                            </div>
                            <div className="bg-white h-0.5 w-1/3 my-4 bg-opacity-40" />
                            <div className="flex space-x-3 items-center">
                                <div className="cursor-pointer" onClick={() => {
                                    toggleModal("requirements")
                                }}>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7 text-white" viewBox="0 0 512 512">
                                        <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                                    </svg>

                                </div>

                                <div className="cursor-pointer" onClick={() => {
                                    toggleModal("files")
                                }}>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 text-white">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                    </svg>

                                </div>

                                <div className="cursor-pointer" onClick={() => {
                                    toggleModal("delete")
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7 text-white">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </div>
                            </div>

                        </div>

                        <div className="w-5/6 md:w-[70%] mx-auto my-10 overflow-y-hidden">
                            <Tabs items={["Info", "Solvers", "Fails"]} current={currentTab} onChange={(tab) => {
                                setCurrentTab(tab)
                            }} />

                            {currentTab === "Info" && <DetailSectionInfo challenge={state.challenge} />}
                            {currentTab === "Solvers" && <DetailSectionSubmission challenge={state.challenge} />}

                            {/* <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-10">
                                <table className="w-full text-sm text-left text-gray-500">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-100 ">
                                        <tr>
                                            <th scope="col" className="p-4">
                                                <div className="flex items-center">
                                                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" />
                                                    <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Event
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Challenge
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Category
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Type
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Value
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>

                            <nav className="flex items-center justify-between pt-5" aria-label="Table navigation">
                                <span className="text-sm font-normal text-gray-500">Showing <span className="font-semibold text-gray-900">1-10</span> of <span className="font-semibold text-gray-900">1000</span></span>
                                <ul className="inline-flex -space-x-px text-sm h-8">
                                    <li>
                                        <button href="#" className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700">Previous</button>
                                    </li>
                                    <li>
                                        <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">1</button>
                                    </li>
                                    <li>
                                        <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">2</button>
                                    </li>
                                    <li>
                                        <button href="#" aria-current="page" className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700">3</button>
                                    </li>
                                    <li>
                                        <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">4</button>
                                    </li>
                                    <li>
                                        <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">5</button>
                                    </li>
                                    <li>
                                        <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700">Next</button>
                                    </li>
                                </ul>
                            </nav> */}
                        </div>
                    </> :
                    <div className="w-full flex mt-24 justify-center">
                        <Loading />
                    </div>
                }

            </AdminWrapper>

            <ChallengeModal
                token={token}
                challenge={state.challenge}
                showModal={showModal}
                onDismiss={onDismiss}
                modalType={modalType}
                hideConfirmation={() => toggleModal()}
            />
        </>
    )
};

export default DetailChallengeScreen;