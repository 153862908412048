import CategoryIcon from "../../components/svg/CategoryIcon";
import ChallengeIcon from "../../components/svg/ChallengeIcon";
import DashboardIcon from "../../components/svg/DashboardIcon";
import EventIcon from "../../components/svg/EventIcon";
import NotificationIcon from "../../components/svg/NotificationIcon";
import ScoreboardIcon from "../../components/svg/ScoreboardIcon";
import SubmissionIcon from "../../components/svg/SubmissionIcon";
import UserIcon from "../../components/svg/UserIcon";
import InitialRedirect from "../../router/initialRedirect";
import CategoriesScreen from "../../screens/admin/CategoryScreen";
import ChallengesScreen from "../../screens/admin/ChallengesScreen";
import CreateCategoryScreen from "../../screens/admin/CreateCategoryScreen";
import CreateChallengeScreen from "../../screens/admin/CreateChallengeScreen";
import CreateEventScreen from "../../screens/admin/CreateEventScreen";
import CreateUserScreen from "../../screens/admin/CreateUserScreen";
import DetailChallengeScreen from "../../screens/admin/DetailChallengeScreen";
import DetailEventScreen from "../../screens/admin/DetailEventScreen";
import DetailUserScreen from "../../screens/admin/DetailUserScreen";
import EventsScreen from "../../screens/admin/EventsScreen";
import HomeScreen from "../../screens/admin/HomeScreen";
import NotificationsScreen from "../../screens/admin/NotificationScreen";
import ScoreboardScreen from "../../screens/admin/ScoreboardScreen";
import SubmissionsScreen from "../../screens/admin/SubmissionsScreen";
import UsersScreen from "../../screens/admin/UsersScreen";
import Logout from "../../screens/auth/logout";

const AdminRouterConstant = [
    {
        name: "/",
        path: "/",
        screen: InitialRedirect
    },
    {
        primary: true,
        icon: DashboardIcon,
        name: "Dashboard",
        path: "/admin/dashboard",
        screen: HomeScreen
    },
    {
        primary: true,
        icon: UserIcon,
        name: "Users",
        path: "/admin/users",
        screen: UsersScreen
    },
    {
        name: "Create User",
        path: "/admin/users/new",
        screen: CreateUserScreen
    },
    {
        name: "Detail User",
        path: "/admin/users/:id",
        screen: DetailUserScreen
    },
    {
        primary: true,
        icon: EventIcon,
        name: "Events",
        path: "/admin/events",
        screen: EventsScreen
    },
    {
        name: "Create Event",
        path: "/admin/events/new",
        screen: CreateEventScreen
    },
    {
        name: "Detail Event",
        path: "/admin/events/:id",
        screen: DetailEventScreen
    },
    {
        primary: true,
        icon: CategoryIcon,
        name: "Categories",
        path: "/admin/categories",
        screen: CategoriesScreen
    },
    {
        name: "Create category",
        path: "/admin/categories/new",
        screen: CreateCategoryScreen
    },
    {
        primary: true,
        icon: ChallengeIcon,
        name: "Challenges",
        path: "/admin/challenges",
        screen: ChallengesScreen,
    },
    {
        name: "Create Challenge",
        path: "/admin/challenges/new",
        screen: CreateChallengeScreen
    },
    {
        name: "Detail challenge",
        path: "/admin/challenges/:id",
        screen: DetailChallengeScreen
    },
    {
        primary: true,
        icon: ScoreboardIcon,
        name: "Scoreboard",
        path: "/admin/scoreboard",
        screen: ScoreboardScreen,
    },
    {
        primary: true,
        icon: SubmissionIcon,
        name: "Submissions",
        path: "/admin/submissions",
        screen: SubmissionsScreen
    },
    {
        primary: true,
        icon: NotificationIcon,
        name: "Notifications",
        path: "/admin/notifications",
        screen: NotificationsScreen
    },
    {
        name: "Logout",
        path: "/logout",
        screen: Logout
    },

];

export default AdminRouterConstant;