import {
    GET_ALL_EVENTS,
    GET_ALL_EVENTS_FAIL,
    GET_EVENT,
    GET_EVENT_FAIL,
    UPDATE_EVENT,
    UPDATE_EVENT_FAIL,
    DELETE_EVENT,
    DELETE_EVENT_FAIL,
    CREATE_EVENT,
    CREATE_EVENT_FAIL
} from '../constants/adminConstants';

import AdminService from '../../services/admin/admin-event-services';

import store from "..";

export const getAllEvents = (token, q, page, perPage, order) => (dispatch) => {
    return AdminService.getAllEvents(token, q, page, perPage, order).then(
        (data) => {
            store.dispatch({
                type: GET_ALL_EVENTS,
                payload: { 
                    events : data.events,
                    currentRows : data.currentRows,
                    totalRows : data.totalRows,
                },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: GET_ALL_EVENTS_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};


export const getEvent = (token, id) => (dispatch) => {
    return AdminService.getEvent(token, id).then(
        (data) => {
            store.dispatch({
                type: GET_EVENT,
                payload: { 
                    event : data,
                },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: GET_EVENT_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};

export const createEvent = (token, event) => (dispatch) =>{

    return AdminService.createEvent(token, event).then(
        (data) => {
            store.dispatch({
                type: CREATE_EVENT,
                payload: {
                    event: data
                }
            })
            return Promise.resolve();
        }
    ).catch((error) => {
        store.dispatch({
            type: CREATE_EVENT_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
}

export const updateEvent = (token, event) => (dispatch) => {
    return AdminService.updateEvent(token, event).then(
        (data) => {
            store.dispatch({
                type: UPDATE_EVENT,
                payload: { event : data },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: UPDATE_EVENT_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};


export const deleteEvent = (token, id) => (dispatch) => {
    return AdminService.deleteEvent(token, id).then(
        (data) => {
            store.dispatch({
                type: DELETE_EVENT,
                payload: { event : data },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: DELETE_EVENT_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};
