import InitialRedirect from "../../router/initialRedirect";
import HomeScreen from "../../screens/home/HomeScreen";
import ChallengesScreen from "../../screens/users/ChallengesScreen";
import DetailChallengesScreen from "../../screens/users/DetailChallengesScreen";
import DetailEventsScreen from "../../screens/users/DetailEventsScreen";
import EventsScreen from "../../screens/users/EventsScreen";
import ProfilScreen from "../../screens/users/ProfilScreen";
import Logout from "../../screens/auth/logout";
import NotifyScreen from "../../screens/users/NotifyScreen";
import AboutUS from "../../screens/home/AboutUS";
import ComingSoonScreen from "../../screens/common/ComingSoonScreen";

const UserRouterConstant = () => {
    const routes = [
        {
            name: "/",
            path: "/",
            screen: InitialRedirect,
        },
        {
            name: "Home",
            path: "/home",
            screen: HomeScreen,
        },
        {
            name: "About",
            path: "/about",
            screen: AboutUS,
        },
        {
            name: "Competition",
            path: "/events",
            //screen: EventsScreen,
            screen: ComingSoonScreen,
        },
        {
            name: "Competition",
            path: "/events/:id",
            // screen: DetailEventsScreen,
            screen: ComingSoonScreen,
        },
        {
            name: "Bluelock",
            path: "/bluelock",
            screen: ComingSoonScreen,
        },
        {
            name: "Challenge",
            path: "/challenges",
            screen: ChallengesScreen,
        },
        {
            name: "Challenge",
            path: "/challenges/:id",
            screen: DetailChallengesScreen,
        },
        {
            name: "Challenge require",
            path: "/challenges/require/:id",
            screen: DetailChallengesScreen,
        },
        {
            name: "Notification",
            path: "/notification",
            screen: NotifyScreen,
        },
        {
            name: "Notification",
            path: "/notification/:id",
            screen: NotifyScreen,
        },
        {
            name: "user",
            path: "/profil",
            screen: ProfilScreen,
        },
        {
            name: "logout",
            path: "/logout",
            screen: Logout,
        },
    ];

    return routes;
};

export default UserRouterConstant;
