import { faStar, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ChallengeModal from "../Modal/ChallengeModal";
import { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../../../context/userContext";
import { sendNotify } from "../../../hooks/notify/notify";
import { useNavigate } from "react-router-dom";

function CardChallenges({ challenge }) {
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const numSolvers = challenge.solvers.teams.length + challenge.solvers.users.length;

  //  Modal
  const [showModal, setShowModal] = useState(false)
  const [solved, setSolved] = useState(false)
  const [require, setRequire] = useState(false)

  const toggleVisible = () => {
    if (!solved) {
      // setShowModal(!showModal)
      navigate(`/challenges/${challenge._id}`)
    }
    else {
      sendNotify("warning", challenge.name, "You have already solved this challenge!")
    }
  }

  useEffect(() => {
    const getRequire = challenge.requirements.length
    setRequire(getRequire)
    const getSolved = challenge.solvers.users.some(solver => solver._id === user._id)
    setSolved(getSolved)
  }, [challenge])

  return (
    <>
      <div className="w-full cursor-pointer hover:shadow-lg" onClick={() => toggleVisible()}>
        <div className="w-full flex flex-col h-36 bg-white shadow-gray-950/30 shadow justify-between items-center rounded-sm p-4">
          <div className="w-full flex justify-between items-center">
            <div className="">{challenge.category.name}</div>
            <div className="text-md font-semibold">
              <FontAwesomeIcon
                icon={faUser}
                className="text-black"
              /> | {`${challenge.value} points`}
            </div>
          </div>
          <div className="text-xl font-bold">{challenge.name}</div>
          <div className="w-full flex justify-between items-center text-md">
            <div className="">{`${numSolvers} solves`}</div>

            {require ? <div className={`bg-gray-300 rounded-lg px-2`}>
              <span className="text-black">Require</span>
            </div> : null}
            <div className={`${solved ? "bg-green-300" : "bg-orange-300"} rounded-lg px-2`}>
              {solved ?
                <span className="text-green-800">Resolved</span>
                :
                <span className="text-orange-800">Not resolved</span>
              }
            </div>


          </div>
        </div>
      </div>
      <ChallengeModal visible={showModal} onClose={toggleVisible} data={challenge} />
    </>
  );
}

export default CardChallenges;
