import React from "react";

function CardNotify() {
  return (
    <div className="w-full lg:w-2/3 bg-gray-700/20 p-4">
      <h1 className="text-xl font-semibold lg:font-normal lg:text-2xl mb-2">Hint chall web Escape 2</h1>
      <div className="pl-4 ml-1 border-l-2 border-blue-800">
        <h2 className="font-semibold">Flags</h2>
        <p className="text-justify text-sm">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ratione qui
          unde expedita perferendis necessitatibus a hic nisi fugiat itaque,
          corrupti quibusdam illum possimus voluptas odit facilis nihil minima
          eveniet similique.
        </p>
        <span className="text-black/80 text-sm">{Date()}</span>
      </div>
    </div>
  );
}

export default CardNotify;
