import React from "react";
import AdminWrapper from "../../components/admin/AdminWrapper";

function HomeScreen() {
    return (
        <>
            <AdminWrapper>
                <div className=" h-40 flex items-center justify-center bg-[#2693d0]">
                    <div className="flex items-center space-x-2">
                        <h1 className="cursor-pointer select-none text-white text-5xl font-medium">Dashboard</h1>
                    </div>
                </div>

            </AdminWrapper>
        </>
    )
};

export default HomeScreen;