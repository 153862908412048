import React, { useEffect, useState } from "react";
import CountrySelect2 from "../../ui/CountriesSelect2";
import CheckBox from "../../ui/CheckBox";
import InputField2 from "../../ui/InputField2";
import { validateEmail, validateName, validateSchool } from "../../../utils/validationUtils";
import SchoolSelect2 from "../../ui/SchoolSelect2";
import ButtonUi from "../../ui/ButtonUi";
import { sendNotify } from "../../../hooks/notify/notify";
import { useDispatch } from "react-redux";
import { deleteUser, updateUser } from "../../../store/actions/adminUsersActions";
import store from "../../../store";
import { useNavigate } from "react-router-dom";

const UserModal = ({ token, showModal, modalType, onDismiss, hideConfirmation, user, onUpdate }) => {
    const [userData, setUserData] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function updateOneUser() {
        setLoading(true);
        dispatch(updateUser(token, userData))
            .then(() => {
                sendNotify('success', 'Update user !', "User updated successfully")
                onUpdate({ user: store.getState().adminUser.otherUser })
                setLoading(false);
            }).catch((err) => {
                sendNotify('error', 'Update Users error !', store.getState().adminUser.message);
                setLoading(false);
            })
    }

    async function deleteOneUser() {
        dispatch(deleteUser(token, userData._id))
            .then(() => {
                sendNotify('success', 'Delete user !', "User deleted successfully");
                navigate("/admin/users");
            })
            .catch(() => {
                sendNotify('error', 'Delete error !', store.getState().adminUser.message)
            })
    }

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevFields) => ({ ...prevFields, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    // Select Country
    const handleCountrySelection = (selectedCountry) => {
        setUserData((prevFields) => ({
            ...prevFields,
            country: selectedCountry.label,
        }));
    };
    // Checked isStudent
    const handleStudentChange = (isChecked) => {
        setUserData((prevFields) => ({
            ...prevFields,
            isStudent: isChecked ? "yes" : "no",
        }));
    };

    const handleSchoolChange = (school) => {
        setUserData((prevFields) => ({
            ...prevFields,
            school: school,
        }));
    }

    const handleSubmit = async () => {
        const newErrors = {};

        // Validation des champs avec les fonctions de validation
        newErrors.country = !userData.country ? "Country is required" : null;
        newErrors.name = validateName(userData.name);
        newErrors.email = validateEmail(userData.email);

        if (userData.isStudent === "yes") {
            newErrors.school = validateSchool(userData.school);
        }

        setErrors(newErrors);

        // Vérifie si toutes les valeurs dans newErrors sont null
        const areAllErrorsNull = Object.values(newErrors).every(
            (error) => error === null
        );

        if (areAllErrorsNull) {
            updateOneUser();
        }
    };

    useEffect(() => {
        setUserData({
            ...user,
            school: user?.school?.name,
            // password: "",
            // confirm_password: "",
        });
    }, [user])

    return (
        showModal &&
        <div className="h-screen bg-black absolute w-full z-10 top-0 bg-opacity-50" onClick={(e) => onDismiss(e)}>

            {
                modalType === "edit" ?
                    <div className="w-5/6 md:w-2/5 mx-auto my-10 bg-white p-10 rounded-lg">
                        <div className="flex flex-col space-y-5">
                            <InputField2
                                label={"Nom"}
                                type={"text"}
                                placeholder={"Entrez le Nom "}
                                name={"name"}
                                value={userData.name}
                                error={errors.name}
                                onChange={handleFieldChange}
                                onBlur={() =>
                                    setErrors((prevErrors) => ({ ...prevErrors, name: "" }))
                                }
                            />

                            <InputField2
                                label="Email"
                                type="email"
                                name="email"
                                placeholder="Entrez l'email"
                                value={userData.email}
                                error={errors.email}
                                onChange={handleFieldChange}
                                onBlur={() =>
                                    setErrors((prevErrors) => ({ ...prevErrors, email: "" }))
                                }
                            />

                            {/* Country Select */}
                            <CountrySelect2
                                country={userData.country}
                                onCountryChange={handleCountrySelection}
                                error={errors.country}
                                label="Pays"
                            />

                            {/* Checkbox for isStudent */}
                            <CheckBox
                                checked={userData.isStudent ? true : false}
                                onChange={handleStudentChange}
                                label={"Etudiant ?"}
                            />

                            {/* Conditional rendering of school input */}
                            {userData.isStudent === "yes" && (
                                <SchoolSelect2
                                    type="text"
                                    name="school"
                                    placeholder="Enter your school"
                                    value={userData.school}
                                    error={errors.school}
                                    onChange={handleSchoolChange}
                                    onBlur={() =>
                                        setErrors((prevErrors) => ({
                                            ...prevErrors,
                                            school: "",
                                        }))
                                    }
                                />
                            )}

                            <div className="flex justify-end w-full">
                                <ButtonUi
                                    handleSubmit={handleSubmit}
                                    isLoading={loading}
                                    label={"Submit"}
                                />
                            </div>
                        </div>
                    </div> :
                    <div className="w-1/5 mx-auto bg-white px-5 py-5 rounded-lg mt-10">
                        <p className="text-center text-lg">Souhaitez-vous vraiment supprimez l'utilisateur ?</p>

                        <div className="flex justify-center mt-8 space-x-4">
                            <div className="bg-red-500 px-3 py-1 rounded-md cursor-pointer" onClick={() => deleteOneUser()}>
                                <p className="text-white text-base ">confirmer</p>
                            </div>

                            <div className="bg-gray-500 px-3 py-1 rounded-md cursor-pointer" onClick={(e) => hideConfirmation()}>
                                <p className="text-white text-base ">Annuler</p>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default UserModal;