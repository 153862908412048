import { useState } from "react";
import PROFIL from "../../assets/profil.jpg"
import { Link } from "react-router-dom";
import NavbarConnect from "../../components/user/NavbarWrapper";
import { useContext } from "react";
import { UserContext } from "../../context/userContext";
import InputField2 from "../../components/ui/InputField2";
import CheckBox from "../../components/ui/CheckBox";
import CountrySelect from "../../components/ui/CountriesSelect";
import UserWrapper from "../../components/user/common/UserWrapper";
import { validateEmail, validateName, validatePassword, validateSchool } from "../../utils/validationUtils";
import ButtonUi from "../../components/ui/ButtonUi";
import userServices from "../../services/user/userServices";
import { sendNotify } from "../../hooks/notify/notify";
import { AuthMessage } from "../../constants/common/AuthMessage";
import Loading from "../../components/ui/Loading";
import SchoolSelect2 from "../../components/ui/SchoolSelect2";

const ProfilScreen = () => {
    const { user, token, initUser } = useContext(UserContext)
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState({
        name: user.name,
        email: user.email,
        isStudent: user.isStudent,
        country: user.country,
        school: user.school ? user.school.name : "",
        password: ""
    });


    // Save Fields
    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setFields((prevFields) => ({ ...prevFields, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    // Checked isStudent
    const handleStudentChange = (isChecked) => {
        setFields((prevFields) => ({
            ...prevFields,
            isStudent: isChecked ? "yes" : "no",
        }));
    };

    const handleSchoolChange = (school) => {
        setFields((prevFields) => ({
            ...prevFields,
            school: school,
        }));
    }

    // Select Country
    const handleCountrySelection = (selectedCountry) => {
        setFields((prevFields) => ({
            ...prevFields,
            country: selectedCountry.label,
        }));
    };


    const handleSaveClick = async () => {
        const newErrors = {};

        // Validation des champs avec les fonctions de validation
        newErrors.country = !fields.country ? "Country is required" : null;
        newErrors.name = validateName(fields.name);
        newErrors.email = validateEmail(fields.email);
        newErrors.password = !fields.password ? "Champ requis..." : null;
        // newErrors.password = validatePassword(fields.password);
        // newErrors.confirm_password = validatePasswordConfirm(
        //     fields.password,
        //     fields.confirm_password
        // );

        if (fields.isStudent === "yes") {
            newErrors.school = validateSchool(fields.school);
        }

        setErrors(newErrors);

        // Vérifie si toutes les valeurs dans newErrors sont null
        const areAllErrorsNull = Object.values(newErrors).every(
            (error) => error === null
        );

        if (areAllErrorsNull) {
            setIsLoading(true); // Déclenche le chargement
            const { status, message } = await userServices.updateInfo(token, fields)
            setIsLoading(false)
            switch (status) {
                case 200:
                    sendNotify("success", "Update Profil", message);
                    await initUser()
                    break;
                case 401:
                    sendNotify("error", "Update Profil", message);
                    break;
                default:
                    sendNotify("error", "Update Profil", AuthMessage.NOT_CONNEXION);
                    break
            }

        }


    };

    // const handleCancelClick = () => {
    // };

    return (
        <UserWrapper>
            <div className="w-full p-5 lg:p-12 space-y-4">
                <section id="head" className="w-full lg:w-[50%] mx-auto flex flex-col lg:flex-row  lg:justify-between font-semibold">
                    <h1 className="text-xl">Settings</h1>
                    {/* <p className="text-black/60">
                        Dashboard / <span className="text-blue-950">Settings</span>
                    </p> */}
                </section>

                <section id="info" className="w-full lg:w-[50%] mx-auto border-[1px] border-gray-100 shadow">
                    <div className="w-full border-b-[1px] border-gray-200 px-8 py-4">
                        <h1 className="text-black/60 font-semibold">Personal Information</h1>
                    </div>
                    <div className="w-full p-5 space-y-4">
                        {/* Username */}
                        <InputField2
                            type="text"
                            label="Username"
                            name="name"
                            placeholder="Enter your username"
                            value={fields.name}
                            error={errors.name}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({ ...prevErrors, name: "" }))
                            }
                        />

                        {/* Email */}
                        <InputField2
                            type="email"
                            label="Email"
                            name="email"
                            placeholder="Enter your email"
                            value={fields.email}
                            error={errors.email}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({ ...prevErrors, email: "" }))
                            }
                        />

                        {/* Old Password */}
                        <InputField2
                            type="password"
                            label="Password"
                            name="password"
                            placeholder="Enter your password"
                            value={fields.password}
                            error={errors.password}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({ ...prevErrors, password: "" }))
                            }
                            password
                        />


                        {/* Checkbox for isStudent */}
                        <CheckBox
                            checked={fields.isStudent === "yes" ? true : false}
                            onChange={handleStudentChange}
                            label={"Are you student ?"}
                        />

                        {/* Conditional rendering of school input */}
                        {fields.isStudent === "yes" && (
                            <SchoolSelect2
                                type="text"
                                label="School"
                                name="school"
                                placeholder="Enter your school"
                                value={fields.school}
                                error={errors.school}
                                onChange={handleSchoolChange}
                                onBlur={() =>
                                    setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        school: "",
                                    }))
                                }
                            />
                        )}


                        {/* Country Select */}
                        <CountrySelect
                            label={"Country"}
                            country={fields.country}
                            onCountryChange={handleCountrySelection}
                            error={errors.country}
                        />

                        {isLoading ?
                            <div className="w-full flex justify-center items-center mt-4">
                                <Loading />
                            </div> :
                            <div className="w-full flex justify-center items-center mt-4">
                                <button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-md mr-4" onClick={handleSaveClick}>Update</button>
                            </div>
                        }



                    </div>
                </section>
            </div>
        </UserWrapper>

    );
};

export default ProfilScreen;
