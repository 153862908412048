import CardChallenges from "../../components/user/challenges/CardChallenges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import SearchBar from "../../components/user/challenges/SearchBar";
import UserWrapper from "../../components/user/common/UserWrapper";
import ProgessBar from "../../components/user/challenges/ProgressBar";
import FilterCheckbox from "../../components/user/challenges/FilterCheckbox";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import Notfound from "../../assets/Empty-amico.png";
import Footer from "../../components/layouts/home/Footer";
import Loading from "../../components/ui/Loading";
import { current } from "@reduxjs/toolkit";

function ChallengesScreen() {
  const [gridVisible, setGridVisible] = useState(false);
  const [filterVisble, setFilterVisble] = useState(false);
  const [ChallengList, setChallengList] = useState([]);
  const [CategoriesList, setCategoriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [challengeLoading, setChallengeLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useContext(UserContext);
  const [allChallengLength, setAllChallengLength] = useState(0);
  const [currentCategory, setCurrentCategory] = useState("")

  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = ChallengList.challenges?.slice(indexOfFirstItem, indexOfLastItem);

  //API URL
  const API_URL = process.env.REACT_APP_API_URL;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const getAllcategories = async () => {
    const url = `${API_URL}/categories/solved`;
    try {
      const response = await fetch(url, {
        headers: headers,
      });
      if (response.ok) {
        const data = await response.json();
        setCategoriesList(data);
        setIsLoading(false);
      } else {
      }
    } catch (error) {
      console.error("Une erreur s'est produite : ", error);
    }
  };

  const getAllChallenge = async () => {
    setError(null);
    setChallengeLoading(true);
    const url =
      `${API_URL}/challenges/practices?page=1&perPage=9&order=asc`;
    try {
      const response = await fetch(url, {
        headers: headers,
      });
      if (response.ok) {
        const data = await response.json();
        setChallengList(data);
        if (data.challenges.length === 0) {
          setError("No challenge found !");
        }
        setAllChallengLength(data.challenges.length);
        setChallengeLoading(false);
      } else {
      }
    } catch (error) {
      console.error("Une erreur s'est produite : ", error);
    }
  };

  const getCategoryChallenge = async (challengeId) => {
    setError(null);
    setChallengeLoading(true);
    const url = `${API_URL}/categories/${challengeId}/challenges`;
    try {
      const response = await fetch(url, {
        headers: headers,
      });
      if (response.ok) {
        const data = await response.json();
        setChallengList(data);
        if (data.challenges.length === 0) {
          setError("No challenge found !");
        }
        setChallengeLoading(false);
      } else {
      }
    } catch (error) {
      console.error("Une erreur s'est produite : ", error);
    }
  };

  useEffect(() => {
    getAllcategories();
    getAllChallenge();
  }, []);

  const toggleGridVisibility = () => {
    setGridVisible(!gridVisible);
  };

  const togglefilterVisble = () => {
    setFilterVisble(!filterVisble);
  };
  return (
    <>
      {
        <UserWrapper>
          {
            isLoading ? <div className="h-screen w-full flex justify-center items-center">
              <Loading />
            </div> :
              <>
                {/* Progress section */}
                <div className="w-full mx-auto my-2 lg:py-8">
                  <div className=" bg-white shadow-gray-950/30 shadow rounded-sm flex flex-col px-5 mx-2 lg:mx-16">
                    <div className="w-full flex items-center justify-between">
                      <h1 className="my-3">Progress tracker</h1>
                      <button className=" lg:hidden " onClick={toggleGridVisibility}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      className={`grid grid-cols-1 lg:grid-cols-2 gap-4 mb-2 ${gridVisible ? "" : "hidden lg:grid"
                        }`}
                    >
                      {CategoriesList.map((categorie, index) => (
                        <div key={index} className="p-2">
                          <ProgessBar category={categorie} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* Filters section */}
                <div className="w-full flex flex-col lg:flex-row lg:justify-between lg:px-16 px-2 lg:space-x-7 space-y-2 mb-10">
                  <div className="w-full lg:w-1/4 bg-white shadow-gray-950/30 shadow rounded-sm px-5">
                    <div className="w-full flex items-center justify-between">
                      <h1 className="my-3">Filters</h1>
                      <button className=" lg:hidden " onClick={togglefilterVisble}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className={`w-full ${filterVisble ? "" : "hidden lg:block"}`}>
                      {/* <div className="w-full flex flex-col">
                       
                        <div>
                          <FilterCheckbox label="Hide Solved" />
                          <FilterCheckbox label="Show Bookmarked Solved" />
                          <FilterCheckbox label="Show Assigned" />
                        </div>

                        
                        <SearchBar />
                      </div> */}

                      {/* Category Filter */}
                      <h1 className="mb-3">Category Filter</h1>
                      <div className="w-full flex flex-col mb-5">
                        <span className="w-full flex border-[1px] items-center justify-center h-10 text-sm bg-[#172554] text-white font-semibold">
                          All Categories ({CategoriesList.length})
                        </span>
                        <button
                          className={`w-full flex border-[1px] items-center justify-start px-2 h-10 text-sm ${currentCategory === "" ? "text-blue-600" : "text-gray-600"}`}
                          onClick={() => {
                            setCurrentCategory("");
                            getAllChallenge()
                          }}
                        >
                          All ({allChallengLength})
                        </button>
                        {CategoriesList.map((categorie, index) => (
                          <button
                            className={`w-full flex border-[1px] items-center justify-start px-2 h-10 text-sm ${currentCategory === categorie.category.name ? "text-blue-600" : "text-gray-600"}`}
                            onClick={() => {
                              setCurrentCategory(categorie.category.name);
                              getCategoryChallenge(categorie.category._id)
                            }}
                            key={index}
                          >
                            {categorie.category.name} ({categorie.nbChallenges})
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-col justify-between">
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                      {
                        challengeLoading ? <div className="flex-1 col-span-3 justify-center items-center ">
                          <Loading />
                        </div> : error ? <div class="col-span-3 flex-1 flex justify-center items-center">
                          <img src={Notfound} alt="img" className="h-[350px] lg:h-[489px] lg:w-[400px] w-[300px]" />
                        </div> : currentItems.map((challenge) => (
                          <div key={challenge._id} className="col-span-1 md:col-span-1">
                            <CardChallenges challenge={challenge} />
                          </div>
                        ))
                      }
                    </div>

                    {ChallengList.challenges?.length > itemsPerPage && <div className="w-full flex justify-center space-x-10 items-center mt-2">
                      <button
                        className={`px-4 ${currentPage === 1 ? "cursor-not-allowed" : "cursor-pointer"} py-2 rounded-full text-blue-700 hover:text-blue-500 transition duration-300 ease-in-out`}
                        disabled={currentPage === 1}
                        onClick={() => paginate(currentPage - 1)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </button>
                      <ul className="flex space-x-2">
                        <li className="px-4 cursor-pointer py-2 rounded-full bg-white text-blue-700 hover:bg-blue-700 hover:text-white hover:border-blue-700 border hover:border-white transition duration-300 ease-in-out">
                          <span>{currentPage}</span>
                        </li>
                      </ul>
                      <button
                        className={`px-4 ${indexOfLastItem >= ChallengList.challenges?.length ? "cursor-not-allowed" : "cursor-pointer"} py-2 rounded-full text-blue-700 hover:text-blue-500 transition duration-300 ease-in-out`}
                        disabled={indexOfLastItem >= ChallengList.challenges?.length}
                        onClick={() => paginate(currentPage + 1)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </button>

                    </div>}
                  </div>
                </div>
                <Footer />
              </>
          }
        </UserWrapper>
      }
    </>
  );
}

export default ChallengesScreen;
