import React, { useContext, useState } from "react";
import Logo from "../../assets/Logo/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AdminRouterConstant from "../../constants/admin/routerConstant";
import { UserContext } from "../../context/userContext";
import moment from "moment";

function AdminWrapper({ children }) {
    const router = useLocation();
    const { user, logout } = useContext(UserContext);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    return (
        <div>
            <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <aside id="logo-sidebar" className="fixed top-0 left-0 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
                <div className="h-full px-3 py-4 overflow-y-auto bg-gray-100">
                    <Link to="/" className="flex items-center pl-2.5 mt-5">
                        <img src={Logo} className="h-14 mr-3" alt="Flowbite Logo" />
                    </Link>
                    <ul className="mt-10 space-y-2 font-medium">
                        {
                            AdminRouterConstant.map((route, index) => {
                                if (route.primary) {
                                    if (router.pathname.includes(route.name.toLowerCase())) {
                                        return <li key={`${route.name}` + index}>
                                            <Link to={route.path} className="flex items-center p-2 text-gray-900 rounded-lg bg-blue-100 group">
                                                {<route.icon color={"#2693d0"} />}
                                                <span className="flex-1 ml-3 text-[#2693d0] whitespace-nowrap">{route.name}</span>
                                            </Link>
                                        </li>
                                    } else {
                                        return <li key={`${route.name}` + index}>
                                            <Link to={route.path} className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-blue-100 group">
                                                {<route.icon />}
                                                <span className="flex-1 ml-3 whitespace-nowrap">{route.name}</span>
                                            </Link>
                                        </li>
                                    }
                                }
                                return null;
                            })
                        }
                        {/* <li>
                            <Link to={"/admin"} className="flex items-center p-2 text-gray-900 rounded-lg bg-blue-100 hover:bg-blue-100 group">
                                <svg className="w-5 h-5 text-blue-500 transition duration-75 group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                                    <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                                    <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                                </svg>
                                <span className="ml-3 text-blue-500">Dashboard</span>
                            </Link>
                        </li> */}

                        {/* <li>
                            <Link to={"/logout"} className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-red-100 group">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="flex-shrink-0 w-6 h-6 text-red-500">
                                    <path fillRule="evenodd" d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm10.72 4.72a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H9a.75.75 0 010-1.5h10.94l-1.72-1.72a.75.75 0 010-1.06z" clipRule="evenodd" />
                                </svg>
                                <span className="flex-1 ml-2 text-red-500 whitespace-nowrap">Log out</span>
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </aside>

            <div className="sm:ml-64">
                <div className=" flex items-center h-24 w-full bg-[#2693d0] justify-between">
                    <div className="ml-10 relative w-1/4">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input type="text" id="input-group-1" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none block w-full pl-10 p-2 " placeholder="Search..." />
                    </div>
                    <div>
                    </div>
                    <div className="relative flex space-x-3 items-center mx-10 cursor-pointer" onClick={() => {
                        setShowModal(!showModal);
                    }}>
                        <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
                            {/* <span className="font-medium text-gray-600">{user.name.charAt(0)}</span> */}
                            <img src={`https://ui-avatars.com/api/?name=${user.name}&background=2693d0&color=fff&font-size=0.5&rounded=true&bold=true`} alt="profil" className="w-full" />
                        </div>
                        <div className="font-medium">
                            <div className="text-white">{user.name}</div>
                            <div className="text-sm text-white opacity-30">Joined in {moment(user.createdAt).format("MMMM YYYY")}</div>
                        </div>

                        {showModal && <div className="absolute flex flex-col translate-y-[90%] bg-white shadow-md rounded-md">
                            <div className="flex flex-col px-4 py-2">
                                <span className="text-gray-800">{user.name}</span>
                                <span className="text-gray-400">{user.email}</span>
                            </div>
                            <div className="h-0.5 w-full bg-gray-100" />
                            <div className="py-2 px-4 hover:bg-red-100 rounded-b-md cursor-pointer" onClick={() => {
                                logout();
                                navigate("/");
                            }}>
                                <span className="text-red-500">Deconnexion</span>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

            <div className="sm:ml-64">
                {children}
            </div>

        </div>
    )
}

export default AdminWrapper;