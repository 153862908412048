import React, { useContext, useState, useEffect } from "react";
import CardNotify from "../../components/user/notify/CardNotify";
import UserWrapper from "../../components/user/common/UserWrapper";
import { UserContext } from "../../context/userContext";
import userServices from "../../services/user/userServices";
import Loading from "../../components/ui/Loading";

function NotifyScreen() {
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [notify, setNotify] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const res = await userServices.getNotify(token);
      if (res.length === 0) {
        setNotify(res.notifications);
      } else {
        setError("No Notification...");
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setError("Error loading notifications. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <div className="h-[80vh] w-full flex justify-center items-center">
      <Loading />
    </div>;
  }

  if (error) {
    return (
      <UserWrapper>
        <div className="h-[80vh] w-full flex flex-col gap-10 justify-center items-center">
          <p className='text-3xl text-black/60 font-bold'>{error}</p>
        </div>
      </UserWrapper>

    );
  }

  return (
    <UserWrapper>
      <div className="w-full flex flex-col gap-5 justify-center items-center py-4 px-4">
        {notify.map((item, index) => (
          <CardNotify key={index} data={item} />
        ))}
      </div>
    </UserWrapper>
  );
}

export default NotifyScreen;
