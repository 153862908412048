import {
    GET_ALL_CATEGORY,
    GET_ALL_CATEGORY_FAIL,
    GET_CATEGORY,
    GET_CATEGORY_FAIL,
    UPDATE_CATEGORY,
    UPDATE_CATEGORY_FAIL,
    DELETE_CATEGORY,
    DELETE_CATEGORY_FAIL,
    CREATE_CATEGORY,
    CREATE_CATEGORY_FAIL
} from '../constants/adminConstants';

import AdminService from '../../services/admin/admin-category-services';

import store from "..";

export const getAllCategories = (token, q, page, perPage, order) => (dispatch) => {
    return AdminService.getAllCategories(token, q, page, perPage, order).then(
        (data) => {
            store.dispatch({
                type: GET_ALL_CATEGORY,
                payload: { 
                    categories : data.categories,
                    currentRows : data.currentRows,
                    totalRows : data.totalRows,
                },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: GET_ALL_CATEGORY_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};


export const getCategory = (token, id) => (dispatch) => {
    return AdminService.getCategory(token, id).then(
        (data) => {
            store.dispatch({
                type: GET_CATEGORY,
                payload: { 
                    category : data,
                },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: GET_CATEGORY_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};

export const createCategory = (token, category) => (dispatch) =>{

    return AdminService.createCategory(token, category).then(
        (data) => {
            store.dispatch({
                type: CREATE_CATEGORY,
                payload: {
                    category: data
                }
            })
            return Promise.resolve();
        }
    ).catch((error) => {
        store.dispatch({
            type: CREATE_CATEGORY_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
}

export const updateCategory = (token, category) => (dispatch) => {
    return AdminService.updateCategory(token, category).then(
        (data) => {
            store.dispatch({
                type: UPDATE_CATEGORY,
                payload: { category : data },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: UPDATE_CATEGORY_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};


export const deleteCategory = (token, id) => (dispatch) => {
    return AdminService.deleteCategory(token, id).then(
        (data) => {
            store.dispatch({
                type: DELETE_CATEGORY,
                payload: { category : data },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        store.dispatch({
            type: DELETE_CATEGORY_FAIL,
            payload: { message : error.data.message, },
        });
        return Promise.reject();
    });
};
