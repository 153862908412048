// validationUtils.js

import moment from "moment";

export const validateEmail = (email) => {
    if (!email) {
        return "Email is required";
    }

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailRegex.test(email)) {
        return "Invalid email format";
    }

    return null;
};

export const validatePassword = (password) => {
    if (!password) {
        return "Password is required";
    }

    if (password.length < 8) {
        return "Password must be at least 8 characters long";
    }

    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

    if (
        !(
            uppercaseRegex.test(password) &&
            lowercaseRegex.test(password) &&
            digitRegex.test(password) &&
            specialCharRegex.test(password)
        )
    ) {
        return "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character";
    }

    return null;
};

export const validatePasswordConfirm = (password, confirmPassword) => {
    if (!confirmPassword) {
        return "Confirm password is required";
    }

    if (password !== confirmPassword) {
        return "Passwords do not match";
    }

    return null;
};

export const validateName = (name) => {
    if (!name) {
        return "Name is required";
    }

    return null;
};

export const validateSchool = (school) => {
    if (!school) {
        return "School is required";
    }

    return null;
};

export const validatePeriode = (startDate, endDate) => {
    if (!startDate || !endDate) {
        return "Please select a start date and a end date !"
    }

    if( moment(startDate).isBefore(moment()) || moment(endDate).isBefore(moment()) || moment(endDate).isBefore(moment(startDate))){
        return "Please enter a valide period !";
    }

    return null;   
}

export const valideTeamSize = (minTeamSize, maxTeamSize) => {
    if (!minTeamSize || !maxTeamSize) {
        return "Please enter team size !";
    }

    if(parseInt(minTeamSize) === 0 || parseInt(maxTeamSize) === 0 || parseInt(minTeamSize)  > parseInt(maxTeamSize)) {
        return "Please enter a valide team size";
    }

    return null;
}
