import React from "react";

function FilterCheckbox({label}) {
  return (
    <div className="flex space-x-2 items-center p-2">
      <input
        type="checkbox"
        id="hide-solved"
        className="rounded focus:outline-none text-[#172554] focus:ring-0"
      />
      <span className="text-sm text-gray-600">{label}</span>
    </div>
  );
}

export default FilterCheckbox;
