import React, { useState } from "react";

function CheckBox({ checked, onChange, label }) {
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckboxChange = (event) => {
    const newChecked = event.target.checked;
    setIsChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <label className="flex items-center">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <span className="ml-2">{label}</span>
    </label>
  );
}

export default CheckBox;
