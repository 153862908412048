/* eslint-disable import/no-anonymous-default-export */
import {
    GET_ALL_SUBMISSIONS,
    GET_ALL_SUBMISSIONS_FAIL,
    GET_CHALLENGE_SUBMISSIONS,
    GET_CHALLENGE_SUBMISSIONS_FAIL,
    GET_SUBMISSION,
    GET_SUBMISSION_FAIL,
} from '../constants/adminConstants';

const initialState = {
    submissions: [],
    totalRows: 0,
    submission: {},
    message: '',
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_SUBMISSIONS:
            return {
                ...state,
                submissions: payload.submissions,
                totalRows: payload.totalRows,
            };
        case GET_ALL_SUBMISSIONS_FAIL:
            return {
                ...state,
                // submissions: [],
                message: payload.message ? payload.message : 'An error occured when trying to get all submissions',
            };
        case GET_CHALLENGE_SUBMISSIONS:
            return {
                ...state,
                submissions: payload.submissions,
                totalRows: payload.totalRows,
            };
        case GET_CHALLENGE_SUBMISSIONS_FAIL:
            return {
                ...state,
                // submissions: [],
                message: payload.message ? payload.message : 'An error occured when trying to get all submissions',
            };
        case GET_SUBMISSION:
            return {
                ...state,
                otherSubmission: payload.submission,
            };
        case GET_SUBMISSION_FAIL:
            return {
                ...state,
                message: payload.message ? payload.message : 'An error occured when trying to get this submission',
            };
        default:
            return state;
    }
}