import React, { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";

function Logout() {
  const navigate = useNavigate();
  const { logout, initUser } = useContext(UserContext);

  useEffect(() => {
    const handleLogout = async () => {
      // Supprimez le token utilisateur du stockage local
      await logout();

      // Redirigez l'utilisateur vers la page d'accueil
      initUser()
      navigate("/");

    };

    handleLogout(); // Call the async function inside useEffect

  }, [navigate, logout]);

  return <div>Logging out...</div>;
}

export default Logout;
