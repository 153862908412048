import { useContext, useEffect, useState } from "react";
import { getLocalStorage } from "../../../utils/storage";
import { useDispatch } from "react-redux";
import { updateChallenge } from "../../../store/actions/adminChallengesActions";
import { sendNotify } from "../../../hooks/notify/notify";
import store from "../../../store";
import InputField2 from "../../ui/InputField2";
import EventSelect from "../../ui/EventSelect";
import CategorySelect from "../../ui/CategorySelect";
import ReactQuill from "react-quill";
import ButtonUi from "../../ui/ButtonUi";
import Separator from "../Separator";
import { UserContext } from "../../../context/userContext";


function DetailSectionInfo({ challenge }) {
    const [state, setState] = useState({});
    const { token } = useContext(UserContext);
    const [type, setType] = useState("");
    const dispatch = useDispatch();

    const [challengeData, setChallengeData] = useState({
        name: "", //required
        description: "", //required
        max_attempts: "0",
        eventName: "",
        event: "", //required
        value: "", //required
        categoryName: "",
        category: "", //required
        type: "standard", //required
        caseSensitivity: "case-sensitive",
        minimum: "", //required
        decay: "", //required
        func: "linear",
        flag: "", // required
        files: [],
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (challenge) {
            if (challenge.dynamic) {
                setChallengeData({
                    _id: challenge.challenge._id,
                    name: challenge.challenge.name,
                    description: challenge.challenge.description,
                    max_attempts: challenge.challenge.max_attempts,
                    eventName: challenge.challenge.event?.name,
                    event: challenge.challenge.event?._id,
                    value: challenge.challenge.value,
                    categoryName: challenge.challenge.category.name,
                    category: challenge.challenge.category._id,
                    type: challenge.challenge.type,
                    minimum: challenge.dynamic.minimum,
                    decay: challenge.dynamic.decay,
                    func: challenge.dynamic.func,
                });
                setType(challenge.challenge.type)
            } else {
                setChallengeData({
                    _id: challenge.challenge._id,
                    name: challenge.challenge.name,
                    description: challenge.challenge.description,
                    max_attempts: challenge.challenge.max_attempts,
                    eventName: challenge.challenge.event?.name,
                    event: challenge.challenge.event?._id,
                    value: challenge.challenge.value,
                    categoryName: challenge.challenge.category.name,
                    category: challenge.challenge.category._id,
                    type: challenge.challenge.type,
                    minimum: "",
                    decay: "",
                    func: "linear",
                });
                setType(challenge.challenge.type)
            }
        }

    }, [challenge])

    async function updateOneChallenge(data) {
        setLoading(true);
        dispatch(updateChallenge(token, challenge.challenge._id, data))
            .then(() => {
                setState({ ...state, challenge: store.getState().adminChallenge.otherChallenge })
                sendNotify('success', 'Update challenge !', "Challenge updated successfully")
                setLoading(false);
            }).catch((err) => {
                sendNotify('error', 'Update challenge error !', store.getState().adminChallenge.message);
                setLoading(false);
            });
    }

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        if (name === "value" || name === "minimum" || name === "decay") {
            setChallengeData((prevFields) => ({ ...prevFields, [name]: value.replace(/[^0-9]/g, '') }));
            e.target.value = value.replace(/[^0-9]/g, '');
        } else {
            setChallengeData((prevFields) => ({ ...prevFields, [name]: value }));
        }
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleEventChange = (event) => {
        if (typeof event === 'object') {
            setChallengeData({ ...challengeData, eventName: event.name, event: event._id })
        } else {
            setChallengeData({ ...challengeData, eventName: event, event: "" })
        }
    }

    const handleCategoryChange = (category) => {
        setChallengeData({ ...challengeData, categoryName: category.name, category: category._id })
    }

    const handleSubmit = async () => {
        const newErrors = {};
        console.log(challengeData);
        //Validation des champs
        ['name', 'description', 'category', 'value', 'flag'].map((field) => {
            if (challengeData[field] === "") {
                newErrors[field] = field + ' is required';
            }
        })

        if (challengeData.eventName !== "" && challengeData.event === "") {
            newErrors.event = "Invalid event";
        }

        if (challengeData.type === "dynamic") {
            ['minimum', 'decay'].map((field) => {
                if (challengeData[field] === "") {
                    newErrors[field] = field + ' is required';
                }
            })
        }
        setErrors(newErrors);

        // Vérifie si toutes les valeurs dans newErrors sont null
        const areAllErrorsNull = Object.values(newErrors).every(
            (error) => error === null
        );

        if (areAllErrorsNull) {
            const formData = new FormData();


            for (const key in challengeData) {
                if (challengeData.hasOwnProperty(key)) {
                    if (challengeData[key])
                        formData.append(key, challengeData[key]);
                    else
                        formData.append(key, "");
                }
            }

            updateOneChallenge(formData);
        }
    };

    return (

        <div className="w-full flex mx-auto my-10">
            <div className="w-2/5">
                <p className="text-center text-lg font-bold">Challenge type</p>
                <div className="flex flex-col space-y-3 mt-5">

                    <div className={`flex items-center w-4/5 mx-auto py-8 px-5 ${type === "standard" ? "ring-2 ring-[#2693d0]" : "ring-2 ring-gray-300"} rounded-md cursor-pointer`} onClick={() => {
                        setType("x");
                        setChallengeData({ ...challengeData, type: "standard" });
                    }}>
                        <input id="standard" checked={type === "standard" ? true : false} type="checkbox" value="standard" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" onChange={() => { }} />
                        <label htmlFor="standard" className="ml-2 text-sm font-medium text-gray-900 cursor-pointer">Standard</label>
                    </div>

                    <div className={`flex items-center w-4/5 mx-auto py-8 px-5  ${type === "dynamic" ? "ring-2 ring-[#2693d0]" : "ring-2 ring-gray-300"} rounded-md cursor-pointer`} onClick={() => {
                        setType("dynamic");
                        setChallengeData({ ...challengeData, type: "dynamic" });
                    }}>
                        <input id="dynamic" checked={type === "dynamic" ? true : false} type="checkbox" value="dynamic" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" onChange={() => { }} />
                        <label htmlFor="dynamic" className="ml-2 text-sm font-medium text-gray-900 cursor-pointer ">Dynamique</label>
                    </div>
                </div>

                <div className="px-5 mt-10">
                    <div className="flex flex-col">
                        <span className="font-bold">Fonction de décroissance</span>
                        <span className="text-sm text-gray-400 mt-2">Mode de calcul de la valeur dynamique en fonction de la valeur de décroissance</span>
                        <ul className="ml-10">
                            <li className="list-disc"><span className=" text-sm text-gray-400">Linéaire : Calculée comme <span className="text-sm text-red-400">Initiale - (Decay * nombreResolu)</span> </span></li>
                            <li className="list-disc"><span className=" text-sm text-gray-400">Logarithmique : Calculée comme <span className="text-sm text-red-400">(((Minimum - Initiale) / (Decay^2)) * (nombreResolu^2)) + Initiale</span> </span></li>
                        </ul>
                    </div>
                </div>

                <div className="px-5 mt-10">
                    <div className="flex flex-col">
                        <span className="font-bold">Decay (Decroissance)</span>
                        <span className="text-sm text-gray-400 mt-2">La valeur de décroissance est utilisée différemment selon la fonction de décroissance ci-dessus.</span>
                        <ul className="ml-10">
                            <li className="list-disc"><span className=" text-sm text-gray-400">Linéaire: Le nombre de points déduits par solution. Déduction égale par solution.</span></li>
                            <li className="list-disc"><span className=" text-sm text-gray-400">Logarithmique: Le nombre de résolutions avant que le défi n'atteigne sa valeur minimale. Les premières solutions font perdre moins de points. Les résolutions plus tardives perdront plus de points.</span></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="w-3/5 flex flex-col space-y-5">
                <div>
                    <InputField2
                        label={"Nom"}
                        type={"text"}
                        placeholder={"Entrez le Nom "}
                        name={"name"}
                        value={challengeData.name}
                        error={errors.name}
                        onChange={handleFieldChange}
                        onBlur={() =>
                            setErrors((prevErrors) => ({ ...prevErrors, name: "" }))
                        }
                    />
                    <span className="text-xs text-gray-400 italic">Nom du challenge</span>
                </div>

                <div>
                    <EventSelect
                        label={"Event"}
                        type={"text"}
                        name="event"
                        placeholder={"Event name"}
                        value={challengeData.eventName}
                        error={errors.event}
                        onChange={handleEventChange}
                        onBlur={() =>
                            setErrors((prevErrors) => ({ ...prevErrors, event: "" }))
                        }
                    />
                    <span className="text-xs text-gray-400 italic">L'événement auquel le challenge est lié. Ne rien indiquer pour un challenge d'entraînement.</span>
                </div>

                <div>
                    <CategorySelect
                        label={"Categorie"}
                        type={"text"}
                        name="category"
                        placeholder={"Categorie name"}
                        value={challengeData.categoryName}
                        error={errors.category}
                        onChange={handleCategoryChange}
                        onBlur={() =>
                            setErrors((prevErrors) => ({ ...prevErrors, category: "" }))
                        }
                    />
                    <span className="text-xs text-gray-400 italic">La catégorie auquel le challenge appartient.</span>
                </div>

                <div>
                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Description</label>
                    <div className="w-full h-[200px] pb-10">
                        <ReactQuill theme="snow" className="w-full h-full mb-10" value={challengeData.description} onChange={(value) => {
                            setChallengeData({ ...challengeData, description: value })
                        }} />
                    </div>
                    {errors.description && <span className="mt-2 text-red-500">{errors.description}</span>}
                </div>

                <Separator text={"Value options"} />

                {/* for dynamic challenge */}
                {type === "dynamic" && <div className="flex flex-col space-y-5">
                    <div>
                        <InputField2
                            label={"Valeur Initiale"}
                            type={"text"}
                            name={"value"}
                            value={challengeData.value}
                            error={errors.value}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({ ...prevErrors, value: "" }))
                            }
                        />
                        <span className="text-xs text-gray-400 italic">Le nombre de points que le défi vaut au départ.</span>
                    </div>

                    <div>
                        <label htmlFor="decay-fn" className="block mb-2 text-sm font-medium text-gray-900">Decay function</label>
                        <select id="decay-fn" name="func" value={challengeData.func} className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" onChange={handleFieldChange}>
                            <option value={"linear"}>Linéaire</option>
                            <option value={"logarithmic"}>Logarithmique</option>
                        </select>
                        <span className="text-xs text-gray-400 italic">La fonction de diminution des points de ce challenge si dynamic</span>
                    </div>

                    <div>
                        <InputField2
                            label={"Decay"}
                            type={"text"}
                            name={"decay"}
                            value={challengeData.decay}
                            error={errors.decay}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({ ...prevErrors, decay: "" }))
                            }
                        />
                        <span className="text-xs text-gray-400 italic">La valeur à diminuer des points de ce challenge si dynamic.</span>
                    </div>

                    <div>
                        <InputField2
                            label={"Minimum"}
                            type={"text"}
                            name={"minimum"}
                            value={challengeData.minimum}
                            error={errors.minimum}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({ ...prevErrors, minimum: "" }))
                            }
                        />
                        <span className="text-xs text-gray-400 italic">La valeur maximale à laquelle ce challenge si dynamic peut descendre.</span>
                    </div>

                </div>
                }

                {/* for standard challenge */}
                {
                    type === "standard" && <div className="flex flex-col space-y-5">
                        <div>
                            <InputField2
                                label={"Valeur"}
                                type={"text"}
                                name={"value"}
                                value={challengeData.value}
                                error={errors.value}
                                onChange={handleFieldChange}
                                onBlur={() =>
                                    setErrors((prevErrors) => ({ ...prevErrors, value: "" }))
                                }
                            />
                        </div>
                    </div>
                }

                <Separator text={"More options"} />

                {/* more option */}
                <div>
                    <InputField2
                        label={"Max attempts"}
                        type={"text"}
                        name={"max_attempts"}
                        value={challengeData.max_attempts}
                        error={errors.max_attempts}
                        onChange={handleFieldChange}
                        onBlur={() =>
                            setErrors((prevErrors) => ({ ...prevErrors, max_attempts: "" }))
                        }
                    />
                </div>


                <div className="flex justify-end w-full">
                    <div className="w-2/6">
                        <ButtonUi
                            handleSubmit={handleSubmit}
                            isLoading={loading}
                            label={"Update"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DetailSectionInfo;