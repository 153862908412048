import React, { useState } from "react";

const Tabs = ({items, current, onChange}) => {
    const [currentState, setCurrentState] = useState(current)

    const handleClick = (e) =>{
        setCurrentState(e.target.innerText);
        onChange(e.target.innerText);
    }

    return (
        <div className="flex items-center">
            {
                items.map((item, index) => {
                    return (
                        <div onClick={(e) => handleClick(e) } key={index} className={`cursor-pointer w-full py-2 text-center border-gray-300 ${item === currentState ? "border text-[#2693d0] border-b-0": "border-b text-gray-500"}` }>
                            {item}
                        </div>
                    )
                })
            }

        </div>
    )
}

export default Tabs;