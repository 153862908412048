/* eslint-disable import/no-anonymous-default-export */
import {
    GET_ALL_USERS,
    GET_ALL_USERS_FAIL,
    CREATE_USER,
    CREATE_USER_FAIL,
    GET_USER,
    GET_USER_FAIL,
    UPDATE_USER,
    UPDATE_USER_FAIL,
    DELETE_USER,
    DELETE_USER_FAIL,
    MAKE_ADMIN,
    MAKE_ADMIN_FAIL,
    ENABLE_USER,
    ENABLE_USER_FAIL,
    EXPORT_USERS_TABLE,
    EXPORT_USERS_TABLE_FAIL,
} from '../constants/adminConstants';


const initialState = {
    users : [],
    totalRows : 0,
    user : {},
    message : '',
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_USERS:
            return {
                ...state,
                users: payload.users,
                totalRows : payload.totalRows,
            };
        case GET_ALL_USERS_FAIL:
            return {
                ...state,
                // users: [],
                message : payload.message ? payload.message : 'An error occured when trying to get all users',
            };
        case CREATE_USER:
            return {
                ...state,
                otherUser: payload.user,
            };
        case CREATE_USER_FAIL:
            return {
                ...state,
                // users: [],
                message : payload.message ? payload.message : 'An error occured when trying to create user',
            };
        case GET_USER:
            return {
                ...state,
                otherUser: payload.user,
            };
        case GET_USER_FAIL:
            return {
                ...state,
                message : payload.message ? payload.message : 'An error occured when trying to get this user',
            };
        case UPDATE_USER:
            return {
                ...state,
                otherUser: payload.user,
            };
        case UPDATE_USER_FAIL:
            return {
                ...state,
                message : payload.message ? payload.message : 'An error occured when trying to get all users',
            };
        case DELETE_USER:
        return {
            ...state,
            user: payload.user,
            message : 'User ' + payload.user.name + ' deleted'
        };
        case DELETE_USER_FAIL:
            return {
                ...state,
                // users: [],
                message : payload.message ? payload.message : 'An error occured when trying to delete the user',
            };
        case MAKE_ADMIN:
            return {
                ...state,
                user: payload.user,
                message : payload.user.name + ' right have been edited'
            };
        case MAKE_ADMIN_FAIL:
            return {
                ...state,
                // users: [],
                message : payload.message ? payload.message : 'An error occured when trying to edit admin right of a user',
            };
        case ENABLE_USER:
            return {
                ...state,
                user: payload.user,
                message : payload.user.name + ' status have been edited'
            };
        case ENABLE_USER_FAIL:
            return {
                ...state,
                // users: [],
                message : payload.message ? payload.message : 'An error occured when trying to edit a user status',
            };
        case EXPORT_USERS_TABLE:
            return {
                ...state,
                message : 'Users list downloading have started'
            };
        case EXPORT_USERS_TABLE_FAIL:
            return {
                ...state,
                // users: [],
                message : payload.message ? payload.message : 'An error occured when trying download users list',
            };
        default:
            return state;
    }
}