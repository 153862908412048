/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import authServices from "../../services/auth/authServices";
import { sendNotify } from "../../hooks/notify/notify";
import { AuthMessage } from "../../constants/common/AuthMessage";

function CheckCodeAccount() {
  const navigate = useNavigate();
  const { key } = useParams();
  let hasNotified = false;

  useEffect(() => {
    const checCode = async () => {
      try {
        const res = await authServices.checkAccount(key);
        if (!hasNotified) {
          if (res === 201) {
            sendNotify("success", "Check Code", AuthMessage.ACCOUNT_ACTIVE);
            navigate(`/auth/login`);
          } else if (res === 404) {
            sendNotify("error", "Check Code", AuthMessage.INVALID_CODE);
            navigate(`/auth/login`);
          } else {
            throw new Error(AuthMessage.NOT_CONNEXION);
          }
          hasNotified = true;
        }

      } catch (error) {
        sendNotify("error", "Check Code", error.message || AuthMessage.NOT_CONNEXION);
        navigate(`/auth/login`);
      }
    };

    checCode();
  }, []);

  return <div>{/* Vous pouvez ajouter du contenu ici si nécessaire */}</div>;
}

export default CheckCodeAccount;
