const TextArea = ({
    type,
    name,
    placeholder,
    value,
    error,
    onChange,
    onBlur,
    label
}) => {
    return (
        <div className="w-full flex flex-col ">
            {label && <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900">{label}</label>}
            <div className="flex justify-center relative">
                <textarea
                    
                    type={type}
                    name={name}
                    id={name}
                    className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 h-44 ${error ? " border-red-300 focus:ring-red-500 focus:border-red-500" : " border-gray-300 focus:ring-blue-500 focus:border-blue-500"}`}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </div>

            {error && <div className="text-[#ff0000]">{error}</div>}
        </div>
    )
}

export default TextArea;