/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from 'react';
import authServices from '../services/auth/authServices';
import { getLocalStorage, saveLocalStorage } from '../utils/storage';
import { decrypt } from '../utils/crypt';

export const UserContext = createContext();

// eslint-disable-next-line react/prop-types
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(true);
  let once = false;

  useEffect(() => {
    if (once === false) {
      initUser();
    }
  }, [])

  const initUser = async () => {
    once = true;
    try {
      const token = await getLocalStorage("token");
      if (token) {
        const user = await authServices.userInfo(decrypt(token))
        setToken(decrypt(token));
        setUser(user)
      } else {
        await logout()
      }
    } catch (error) {
      await logout();
    } finally {
      setIsLoading(false);
    }
  }

  const login = async (credentials) => {
    const res = await authServices.login(credentials);
    const { token } = res;
    const user = await authServices.UserInfo(token);
    setUser(user);
    setToken(token);
    await saveLocalStorage("token", token);
  }

  const logout = async () => {
    try {
      localStorage.removeItem("token");
      setUser(null);
      setToken(null);
      once = false;
    } catch (error) {
    }
  }

  return (
    <UserContext.Provider value={{
      user,
      token,
      isLoading,
      isMounted,
      setUser,
      initUser,
      setIsLoading,
      login,
      logout,
      setIsMounted
    }}>
      {children}
    </UserContext.Provider>
  );
};