const LOGIN_SUCCESS = 'Connected'
const REGISTER_SUCCESS = 'Registered'
const REGISTER_FAIL = 'Error registeration'
const LOGIN_FAIL = 'Error login'
const LOGOUT = 'Disconnected'
const SET_MESSAGE = 'message setting'
const GET_PROFILE = 'My profile'
const GET_PROFILE_FAIL ='Error getting profile'
const UPDATE_ME = 'Update my profile'
const UPDATE_ME_FAIL = 'Updating my profile fail'
const UPDATE_MY_PASSWORD = 'Update my password'
const UPDATE_MY_PASSWORD_FAIL = 'Updating my password fail'

export {LOGIN_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    GET_PROFILE,
    GET_PROFILE_FAIL,
    UPDATE_ME,
    UPDATE_ME_FAIL,
    UPDATE_MY_PASSWORD,
    UPDATE_MY_PASSWORD_FAIL,
}