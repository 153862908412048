import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserWrapper from "../../components/user/common/UserWrapper";

function ComingSoonScreen() {
  const [countdown, setCountdown] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        clearInterval(timer);
        // Redirect to the specified route after the countdown.
        navigate("/");
      }
    }, 1000);

    return () => {
      clearInterval(timer); // Clean up the timer in case the component is unmounted.
    };
  }, [countdown, navigate]);

  return (
    <UserWrapper>
      <div className="bg-white min-h-screen flex flex-col items-center justify-center text-white">
        <h1 className="text-blue-900 text-4xl font-semibold mb-4">Not Available</h1>
        <p className="text-blue-900 text-xl mb-2">
          The page you are looking for does not available for the moment.
        </p>
        {/* <img
          src="https://freefrontend.com/assets/img/html-funny-404-pages/HTML-404-Error-Page.gif"
          alt="Animated GIF"
          className="w-[60%] h-[250px]"
        /> */}
        <p className="text-xl text-blue-900">
          You will be redirected to the home page in {countdown} seconds.
        </p>
        <Link to="/" className="mt-2 underline text-blue-900">
          Back to Home Page
        </Link>
      </div>
    </UserWrapper>
  );
}

export default ComingSoonScreen;
