/* eslint-disable import/no-anonymous-default-export */
import {
    GET_ALL_CHALLENGES,
    GET_ALL_CHALLENGES_FAIL,
    CREATE_CHALLENGE,
    CREATE_CHALLENGE_FAIL,
    GET_CHALLENGE,
    GET_CHALLENGE_FAIL,
    GET_CHALLENGE_SOLVERS,
    GET_CHALLENGE_SOLVERS_FAIL,
    UPDATE_CHALLENGE,
    UPDATE_CHALLENGE_FAIL,
    DELETE_CHALLENGE,
    DELETE_CHALLENGE_FAIL,
    DELETE_CHALLENGE_FILES,
    DELETE_CHALLENGE_FILES_FAIL,
} from '../constants/adminConstants';

const initialState = {
    challenges: [],
    totalRows: 0,
    challenge: {},
    message: '',
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_CHALLENGES:
            return {
                ...state,
                challenges: payload.challenges,
                totalRows: payload.totalRows,
            };
        case GET_ALL_CHALLENGES_FAIL:
            return {
                ...state,
                // challenges: [],
                message: payload.message ? payload.message : 'An error occured when trying to get all challenges',
            };
        case CREATE_CHALLENGE:
            return {
                ...state,
                otherChallenge: payload.challenge,
            };
        case CREATE_CHALLENGE_FAIL:
            return {
                ...state,
                // challenges: [],
                message: payload.message ? payload.message : 'An error occured when trying to create challenge',
            };
        case GET_CHALLENGE:
            return {
                ...state,
                otherChallenge: payload.challenge,
            };
        case GET_CHALLENGE_FAIL:
            return {
                ...state,
                message: payload.message ? payload.message : 'An error occured when trying to get this challenge',
            };
        case GET_CHALLENGE_SOLVERS:
            return {
                ...state,
                solvers: payload.solvers,
                totalRows: payload.totalRows,
            };
        case GET_CHALLENGE_SOLVERS_FAIL:
            return {
                ...state,
                message: payload.message ? payload.message : 'An error occured when trying to get challenge solver',
            };
        case UPDATE_CHALLENGE:
            return {
                ...state,
                otherChallenge: payload.challenge,
            };
        case UPDATE_CHALLENGE_FAIL:
            return {
                ...state,
                message: payload.message ? payload.message : 'An error occured when trying to get all challenges',
            };
        case DELETE_CHALLENGE:
            return {
                ...state,
                challenge: payload.challenge,
                message: 'Challenge ' + payload.challenge.name + ' deleted'
            };
        case DELETE_CHALLENGE_FAIL:
            return {
                ...state,
                // challenges: [],
                message: payload.message ? payload.message : 'An error occured when trying to delete the challenge',
            };

        case DELETE_CHALLENGE_FILES:
            return {
                ...state,
                message: 'Challenge files deleted'
            };
        case DELETE_CHALLENGE_FILES_FAIL:
            return {
                ...state,
                // challenges: [],
                message: payload.message ? payload.message : 'An error occured when trying to delete the challenge',
            };
        default:
            return state;
    }
}