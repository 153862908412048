import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill CSS
import { UserContext } from '../../../context/userContext';
import ButtonUi from '../../ui/ButtonUi';
import userServices from '../../../services/user/userServices';
import { sendNotify } from '../../../hooks/notify/notify';
import Loading from '../../ui/Loading';
import { useNavigate } from 'react-router-dom';

const SubmitModal = ({ visible, response, challenge, onClose }) => {
  const navigate = useNavigate()
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    flag: '',
    files: null,
  });
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    const files = e.target.files;
    setData({ ...data, files: files })
  }

  const handleSubmit = async () => {
    setError("")
    setLoading(true);
    try {
      if (challenge.event && !data.files) {
        setError('Required field');
        return;
      }

      const formData = new FormData();
      formData.append("flag", data.flag);

      if (data.files.length === 0) {
        formData.append('files', []);
      } else {
        for (const file of data["files"]) {
          formData.append('files', file);
        }
      }

      console.log(formData);
      const { status, message } = await userServices.sendSubmit(token, challenge._id, formData);

      if (status === 400) {
        sendNotify('error', 'Submit Flag', message);
        onClose()
      } else if (status === 500) {
        sendNotify('error', 'Submit Flag', 'Network Failed');
        onClose()
      }
      else if (status === 403) {
        sendNotify('error', 'Submit Flag', message);
        onClose()
      }
      else {
        sendNotify('success', 'Submit Flag', "WriteUp submitted successfully");
        onClose()
        navigate("/challenges")
      }

    } catch (error) {
      console.error('Error submitting data:', error);
      // Handle error, for example, show a user-friendly error message.
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setData({ ...data, flag: response });
  }, [response]);

  return (
    <div className={`fixed inset-0 flex items-center justify-center z-50 ${visible ? '' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="mx-4 lg:mx-0 lg:w-2/5 bg-white flex flex-col p-8 rounded-lg shadow-lg z-10">
        <div className='block h-full'>
          <label htmlFor="description" className="block mb-4 text-lg font-medium text-gray-900">How did you go about finding the flag? {!challenge.event ? "(Optional)" : ""}</label>
          <div className="w-full h-full block">
            {/* <ReactQuill theme="snow" className="w-full h-[80%] mb-20" value={data.writeUp} onChange={(value) => {
              setData({ ...data, writeUp: value })
            }} /> */}
            <input name="file" className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none " aria-describedby="user_avatar_help" id="files" type="file" accept='.docx, .pdf' onChange={handleFileChange} />
            <span className="text-gray-400 text-sm">Upload a file explaining it (.docx, .pdf)</span>
          </div>
          {error && <span className="text-red-500">{error}</span>}
        </div>

        {loading ?
          <div className="w-full flex justify-center items-center mt-4">
            <Loading />
          </div> :
          <div className="w-full flex justify-end items-start mt-4">
            {onClose && <button className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md mr-4" onClick={onClose}>Cancel</button>}
            <button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-md " onClick={handleSubmit}>Submit</button>
          </div>
        }
      </div>
    </div>
  );
};

SubmitModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  response: PropTypes.string.isRequired,
  challenge: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SubmitModal;