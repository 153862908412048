import axios from 'axios';
//import "dotenv";

const API_URL = process.env.REACT_APP_API_URL;

const getAllUsers = (token, q, page, perPage, order) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/users?q=' + q + '&page=' + page + '&perPage=' + perPage + '&order=' + order, { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

const getUser = (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/users/' + id, { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

const createUser = (token, user) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
    };

    return axios.post(API_URL + '/users/', user, { headers })
        .then((response) => {
            return response.data
        }).catch((err) => {
            throw err.response
        })
}

const updateUser = (token, user) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios.put(API_URL + '/users/' + user._id, user, { headers }).then((response) => {
        return response.data;
    }).catch((err) => {
        throw err.response;
    });
};

const deleteUser = (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios.delete(API_URL + '/users/' + id, { headers })
        .then((response) => {
            return response.data
        }).catch((err) => {
            throw err.response
        });
};

const makeAdmin = (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/users/' + id + '/admin', { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

const enableUser = (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/users/' + id + '/enable', { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

const exportUsersExcel = (token) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/users/excel', { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAllUsers,
    getUser,
    updateUser,
    deleteUser,
    exportUsersExcel,
    enableUser,
    makeAdmin,
    createUser
};