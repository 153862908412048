/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import AuthWrapper from "../../components/auth/AuthWrapper";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail } from "../../utils/validationUtils";
import InputField from "../../components/ui/InputField";
import CheckBox from "../../components/ui/CheckBox";
import ButtonUi from "../../components/ui/ButtonUi";
import { saveLocalStorage } from "../../utils/storage";
import Logo from "../../assets/Logo/logo.png";
import authServices from "../../services/auth/authServices";
import { sendNotify } from "../../hooks/notify/notify";
import { AuthMessage } from "../../constants/common/AuthMessage";
import { crypt } from "../../utils/crypt";
import { useContext } from "react";
import { UserContext } from "../../context/userContext";

function LoginScreen() {
  const navigate = useNavigate();

  const { initUser } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [checked, setChecked] = useState(false)
  const [fields, setFields] = useState({
    email: "",
    password: "",
  });

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleCheck = (isChecked) => {
    setChecked(isChecked)
  };

  const handleSubmit = async () => {
    const newErrors = {};

    // Validation des champs avec les fonctions de validation
    newErrors.email = validateEmail(fields.email);
    if (!fields.password) newErrors.password = "Password is required";

    setErrors(newErrors);

    // Vérifie si toutes les valeurs dans newErrors sont null
    const areAllErrorsNull = Object.values(newErrors).every(
      (error) => error === null
    );

    if (areAllErrorsNull) {
      setIsLoading(true); // Déclenche le chargement
      const { data, status, message } = await authServices.login(fields)
      switch (status) {
        case 200:
          setIsLoading(false)
          sendNotify("success", "Login", AuthMessage.LOGIN_SUCCESS);
          await saveLocalStorage("token", crypt(data.token))
          await initUser()
          navigate("/")
          break;
        case 401:
          setIsLoading(false)
          if (message === AuthMessage.LOGIN_NOT_VALID) {
            sendNotify("error", "Login", AuthMessage.LOGIN_NOT_VALID);
          } else {
            sendNotify("error", "Login", AuthMessage.MAIL_NOT_CONFIRM)
            sendNotify("success", "Login", AuthMessage.SEND_MAIL);
          }
          break;
        default:
          setIsLoading(false)
          sendNotify("error", "Login", AuthMessage.NOT_CONNEXION);
          break
      }
    }
  };

  return (
    <AuthWrapper>
      <div className="w-full h-[100vh] flex flex-col justify-center lg:w-[80%] lg:h-auto lg:rounded-xl shadow p-6 my-auto">
        {/* Logo Start */}
        <Link
          to="/"
          className="cursor-pointer flex flex-col justify-center items-center mb-10 lg:hidden"
        >
          <img src={Logo} className="w-48" />
        </Link>
        {/* Logo End */}

        {/* Container Start */}
        <div className="w-full flex flex-col mb-2">
          <h3 className="text-2xl lg:text-3xl font-semibold mb-2">Login to your Account</h3>
          <p className="text-md lg:text-base mb-2">
            Welcome Back! Please enter your details.
          </p>

          {/* Fields Start */}
          <div className="w-full flex flex-col">
            <InputField
              type="email"
              name="email"
              placeholder="Enter your email"
              value={fields.email}
              error={errors.email}
              onChange={handleFieldChange}
              onBlur={() =>
                setErrors((prevErrors) => ({ ...prevErrors, email: "" }))
              }
            />
            <InputField
              type="password"
              name="password"
              placeholder="Enter your password"
              value={fields.password}
              error={errors.password}
              onChange={handleFieldChange}
              onBlur={() =>
                setErrors((prevErrors) => ({ ...prevErrors, password: "" }))
              }
              password
            />
          </div>
          <div className="w-full flex items-center justify-between mt-4">
            <div className="w-full flex items-center">
              <CheckBox onChange={handleCheck} />
              <p className="text-sm">Remember me for 30 days</p>
            </div>

            <p className="text-sm font-medium whitespace-nowrap cursor-pointer underline underline-offset-2 hover:text-[#2693d0]">
              <Link to={"/auth/forgot-password"}>Forgot Password ?</Link>
            </p>
          </div>
          <div className="w-full flex flex-col my-4">
            <ButtonUi
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              label={"Login"}
            />
          </div>
        </div>
        {/* Fields Ends */}

        {/* Container End */}

        {/* Footer Start */}
        <div className="w*full flex items-center justify-center cursor-pointer">
          <p className="text-sm font-normal text-[#060606]">
            No account yet?{" "}
            <span className="font-semibold underline underline-offset-2 cursor-pointer hover:text-[#2693d0]">
              <Link to={"/auth/register"}>Create your account.</Link>
            </span>
          </p>
        </div>
        {/* Footer End */}
      </div>
    </AuthWrapper>
  );
}

export default LoginScreen;
