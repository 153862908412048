import React from 'react'
import NavbarWrapper from '../NavbarWrapper'

function UserWrapper({ children }) {
    return (
        <>
            <NavbarWrapper />
            <div className="w-full pt-20">
                {children}
            </div>

        </>
    )
}

export default UserWrapper