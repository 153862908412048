import React from "react";

function ButtonUi({ handleSubmit, isLoading, label }) {
  return (
    <button
      className={`w-full text-white font-semibold my-2 bg-[#2693d0] rounded-md p-4 text-center flex items-center justify-center cursor-pointer ${
        isLoading ? "opacity-50 pointer-events-none" : ""
      }`}
      onClick={handleSubmit}
      disabled={isLoading}
    >
      {isLoading ? "Loading..." : label}
    </button>
  );
}

export default ButtonUi;
