/* eslint-disable import/no-anonymous-default-export */
import {
    GET_ALL_EVENTS,
    GET_ALL_EVENTS_FAIL,
    CREATE_EVENT,
    CREATE_EVENT_FAIL,
    GET_EVENT,
    GET_EVENT_FAIL,
    UPDATE_EVENT,
    UPDATE_EVENT_FAIL,
    DELETE_EVENT,
    DELETE_EVENT_FAIL,
} from '../constants/adminConstants';


const initialState = {
    events : [],
    totalRows : 0,
    event : {},
    message : '',
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_EVENTS:
            return {
                ...state,
                events: payload.events,
                totalRows : payload.totalRows,
            };
        case GET_ALL_EVENTS_FAIL:
            return {
                ...state,
                // events: [],
                message : payload.message ? payload.message : 'An error occured when trying to get all events',
            };
        case CREATE_EVENT:
            return {
                ...state,
                otherEvent: payload.event,
            };
        case CREATE_EVENT_FAIL:
            return {
                ...state,
                // events: [],
                message : payload.message ? payload.message : 'An error occured when trying to create event',
            };
        case GET_EVENT:
            return {
                ...state,
                otherEvent: payload.event,
            };
        case GET_EVENT_FAIL:
            return {
                ...state,
                message : payload.message ? payload.message : 'An error occured when trying to get this event',
            };
        case UPDATE_EVENT:
            return {
                ...state,
                otherEvent: payload.event,
            };
        case UPDATE_EVENT_FAIL:
            return {
                ...state,
                message : payload.message ? payload.message : 'An error occured when trying to get all events',
            };
        case DELETE_EVENT:
        return {
            ...state,
            event: payload.event,
            message : 'Event ' + payload.event.name + ' deleted'
        };
        case DELETE_EVENT_FAIL:
            return {
                ...state,
                // events: [],
                message : payload.message ? payload.message : 'An error occured when trying to delete the event',
            };
        default:
            return state;
    }
}