import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-blue-950 text-white py-8">
      <div className="container mx-auto text-center">
        <p className="text-lg">© {currentYear} EPITECH - All rights reserved</p>
        <p className="text-sm mt-2">
          Hacking your security, one challenge at a time...
        </p>

        {/* Social Media Icons */}
        <div className="mt-4">
          <a href="#" className="text-white hover:text-blue-300 mx-2">
            <FontAwesomeIcon icon={faTwitter} className="text-xl" />
          </a>
          <a href="#" className="text-white hover:text-blue-300 mx-2">
            <FontAwesomeIcon icon={faFacebook} className="text-xl" />
          </a>
          <a href="#" className="text-white hover:text-blue-300 mx-2">
            <FontAwesomeIcon icon={faLinkedin} className="text-xl" />
          </a>
          <a href="#" className="text-white hover:text-blue-300 mx-2">
            <FontAwesomeIcon icon={faInstagram} className="text-xl" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
