import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteCategory, updateCategory } from "../../../store/actions/adminCategoryActions";
import { sendNotify } from "../../../hooks/notify/notify";
import store from "../../../store";
import InputField2 from "../../ui/InputField2";
import { validateName } from "../../../utils/validationUtils";
import TextArea from "../../ui/TextArea";
import ButtonUi from "../../ui/ButtonUi";

const CategoryModal = ({ token, showModal, modalType, onDismiss, hideConfirmation, category, onUpdate }) => {
    const [categoryData, setCategoryData] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    async function updateOneCategory() {
        setLoading(true);
        dispatch(updateCategory(token, categoryData))
            .then(() => {
                sendNotify('success', 'Update event !', "Category updated successfully")
                onUpdate()
                setLoading(false);
            }).catch((err) => {
                sendNotify('error', 'Update event error !', store.getState().adminCategory.message);
                setLoading(false);
            })
    }

    async function deleteOneCategory() {
        dispatch(deleteCategory(token, categoryData._id))
            .then(() => {
                sendNotify('success', 'Delete event !', "Category deleted successfully");
                onUpdate()
            })
            .catch(() => {
                sendNotify('error', 'Delete error !', store.getState().adminUser.message)
            })
    }

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setCategoryData((prevFields) => ({ ...prevFields, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleSubmit = async () => {
        const newErrors = {};

        // Validation des champs avec les fonctions de validation
        newErrors.name = validateName(categoryData.name);
        newErrors.description = !categoryData.description ? "Description is required" : null;

        // Vérifie si toutes les valeurs dans newErrors sont null
        const areAllErrorsNull = Object.values(newErrors).every(
            (error) => error === null
        );

        if (areAllErrorsNull) {
            updateOneCategory();
        }
    };

    useEffect(() => {
        setCategoryData(category);
    }, [category])


    return (
        showModal &&
        <div className="h-screen bg-black absolute w-full z-10 top-0 bg-opacity-50" onClick={(e) => onDismiss(e)}>

            {
                modalType === "edit" ?
                    <div className="w-5/6 md:w-2/5 mx-auto my-10 bg-white p-10 rounded-lg">
                        <div className="flex flex-col space-y-5">
                            <InputField2
                                label={"Nom"}
                                type={"text"}
                                placeholder={"Entrez le Nom "}
                                name={"name"}
                                value={categoryData.name}
                                error={errors.name}
                                onChange={handleFieldChange}
                                onBlur={() =>
                                    setErrors((prevErrors) => ({ ...prevErrors, name: "" }))
                                }
                            />

                            <TextArea
                                label={"Descriptions"}
                                type={"text"}
                                placeholder={"Descriptions.. "}
                                name={"description"}
                                value={categoryData.description}
                                error={errors.description}
                                onChange={handleFieldChange}
                                onBlur={() =>
                                    setErrors((prevErrors) => ({ ...prevErrors, name: "" }))
                                }
                            />

                            <div className="flex justify-end w-full">

                                <div className="w-2/6">
                                    <ButtonUi
                                        handleSubmit={handleSubmit}
                                        isLoading={loading}
                                        label={"Submit"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="w-1/5 mx-auto bg-white px-5 py-5 rounded-lg mt-10">
                        <p className="text-center text-lg">Souhaitez-vous vraiment supprimez la catégorie ?</p>

                        <div className="flex justify-center mt-8 space-x-4">
                            <div className="bg-red-500 px-3 py-1 rounded-md cursor-pointer" onClick={() => deleteOneCategory()}>
                                <p className="text-white text-base ">confirmer</p>
                            </div>

                            <div className="bg-gray-500 px-3 py-1 rounded-md cursor-pointer" onClick={(e) => hideConfirmation()}>
                                <p className="text-white text-base ">Annuler</p>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default CategoryModal;