/* eslint-disable import/no-anonymous-default-export */
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    GET_PROFILE,
    GET_PROFILE_FAIL,
    UPDATE_ME,
    UPDATE_ME_FAIL,
    UPDATE_MY_PASSWORD,
    UPDATE_MY_PASSWORD_FAIL,
} from '../constants/authConstants';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
   ? { isLoggedIn: true, user, message : null }
   : { isLoggedIn: false, user: null, message : null};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                user: payload.user,
                message : null,
            };
        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                message : null,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                message : null,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                message : null,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                message : null,
            };
        case SET_MESSAGE:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                message : payload.message,
            };    
        case GET_PROFILE:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                message : null,
            };
        case GET_PROFILE_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                message : null,
            };
        case UPDATE_ME:
            return {
                ...state,
                user: payload.user,
                message : null,
            };
        case UPDATE_ME_FAIL:
            return {
                ...state,
                message : payload.message ? payload.message : 'An error occured when trying to update your informations !',
            };
        case UPDATE_MY_PASSWORD:
            return {
                ...state,
                user: payload.user,
                message : null,
            };
        case UPDATE_MY_PASSWORD_FAIL:
            return {
                ...state,
                message : payload.message ? payload.message : 'An error occured when trying to change your password !',
            };
                            
        default:
            return state;
    }
}