import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function CardTestimony() {
  return (
    <section id="testimonials" className="bg-white text-blue-900 py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Testimonials</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="p-4">
            <FontAwesomeIcon icon={faQuoteLeft} className="text-4xl mb-4" />
            <p className="text-lg">
              "I found EPITECH's challenges very stimulating. They really
              helped me improve my cybersecurity skills."
            </p>
            <p className="font-semibold mt-2">Alice Doe</p>
          </div>
          <div className="p-4">
            <FontAwesomeIcon icon={faQuoteLeft} className="text-4xl mb-4" />
            <p className="text-lg">
              "EPITECH's community is incredibly helpful. I've been able to
              learn a lot of things thanks to it."
            </p>
            <p className="font-semibold mt-2">Bob Smith</p>
          </div>
          <div className="p-4">
            <FontAwesomeIcon icon={faQuoteLeft} className="text-4xl mb-4" />
            <p className="text-lg">
              "EPITECH's training programs are top-notch. They've allowed me
              to progress in my career."
            </p>
            <p className="font-semibold mt-2">Charlie Brown</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CardTestimony;
