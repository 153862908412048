import axios from 'axios';
//import "dotenv";

const API_URL = process.env.REACT_APP_API_URL;

const getAllCategories = (token, q, page, perPage, order) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/categories?q=' + q + '&page=' + page + '&perPage=' + perPage + '&order=' + order, { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

const getCategory = (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/categories/' + id, { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

const createCategory = (token, categorie) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
    };

    return axios.post(API_URL + '/categories/', categorie, { headers })
        .then((response) => {
            return response.data
        }).catch((err) => {
            throw err.response
        })
}

const updateCategory = (token, categorie) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios.put(API_URL + '/categories/' + categorie._id, categorie, { headers }).then((response) => {
        return response.data;
    }).catch((err) => {
        throw err.response;
    });
};

const deleteCategory = (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios.delete(API_URL + '/categories/' + id, { headers })
        .then((response) => {
            return response.data
        }).catch((err) => {
            throw err.response
        });
};

const makeAdmin = (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/categories/' + id + '/admin', { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

const enableCategory = (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/categories/' + id + '/enable', { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

const exportCategoriesExcel = (token) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/categories/excel', { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAllCategories,
    getCategory,
    updateCategory,
    deleteCategory,
    exportCategoriesExcel,
    enableCategory,
    makeAdmin,
    createCategory
};