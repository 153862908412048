import React from "react";
import AdminWrapper from "../../components/admin/AdminWrapper";

function NotificationsScreen() {
    return (
        <>
            <AdminWrapper>
                <div className=" h-40 flex items-center justify-center bg-[#2693d0]">
                    <div className="flex items-center space-x-2">
                        <h1 className="cursor-pointer select-none text-white text-5xl font-medium">Notifications</h1>
                    </div>
                </div>

                <div className="w-5/6 md:w-2/5 mx-auto my-10">
                    <div className="flex flex-col space-y-5 w-full">
                        <div>
                            <label htmlFor="Event" className="block mb-2 text-sm font-medium text-gray-900">Event</label>
                            <select id="Event" defaultValue={"event1"} className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 ">
                                <option value={"event1"}>Event 1</option>
                                <option value={"event2"}>Event 2</option>
                                <option value={"event3"}>Event 3</option>
                                <option value={"event4"}>Event 4</option>
                            </select>
                        </div>

                        <div>
                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Title</label>
                            <input type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="John" required />
                        </div>

                        <div>
                            <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Description</label>
                            <textarea type="text" id="description" className="bg-gray-50 h-44 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="John" required />
                        </div>

                        <div className="flex justify-end w-full">
                            <button type="submit" className="text-white bg-[#2693d0] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-10 py-2.5 text-center ">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </AdminWrapper>
        </>
    );
}

export default NotificationsScreen;