import React from "react";
import { Link } from "react-router-dom";

function CardEvents({ banner, type, id }) {

  return (
    <Link to={`/events/${id}`}>
      <div className="bg-transparent shadow rounded-t-3xl">
        {/* Image */}
        <div className="relative w-full cursor-pointer">
          <div className="absolute inset-0 bg-blue-950 opacity-0 hover:opacity-50 transition-opacity duration-500 rounded-t-3xl"></div>
          <img
            src={banner}
            alt="banner"
            className="w-full h-40 rounded-t-3xl"
          />
        </div>
        {/* Content Card */}
        <div className="w-full flex justify-between items-center gap-5 text-black/80 p-4">
          <div className="">
            <div className="font-semibold">
              <span className="absolute text-sm text-blue-700 font-bold">
                APR
              </span>
              <span className="ml-8 text-3xl">14</span>
            </div>
            <span className="text-sm ml-10 text-blue-700 font-bold">AU</span>
            <div className="font-semibold">
              <span className="absolute text-sm text-blue-700 font-bold">
                APR
              </span>
              <span className="ml-8 text-3xl">29</span>
            </div>
          </div>
          <div className="">
            <div className="font-semibold w-full flex justify-between gap-3 items-center">
              <div className="">
                <h1 className="text-base">CIF Inter Ecole</h1>
                <h1 className="text-base text-blue-900">Competition Page</h1>
              </div>
              {/* Buttons based on 'type' */}
              {/*  {type === 1 && (
                <button className="text-xs text-white p-2 bg-red-500">
                  Score Board
                </button>
              )}

              {type === 2 && (
                <button className="text-xs text-white p-2 bg-blue-900">
                  Register
                </button>
              )}

              {type === 3 && (
                <button className="text-xs text-white p-3 rounded-3xl bg-blue-700">
                  Play
                </button>
              )}
 */}            </div>
            <p className="w-60 text-sm text-justify text-black/60">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CardEvents;
