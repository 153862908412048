import React, { useContext, useEffect, useState } from "react";
import AdminWrapper from "../../components/admin/AdminWrapper";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import store from "../../store";
import { sendNotify } from "../../hooks/notify/notify";
import { deleteEvent, getAllEvents } from "../../store/actions/adminEventsActions";
import { getLocalStorage } from "../../utils/storage";
import moment from "moment";
import { UserContext } from "../../context/userContext";

const initialState = {
    query: '',
    events: [],
    perPage: 10,
    page: 1,
    totalRows: 0,
    order: 'asc',
}

function EventsScreen() {
    const navigate = useNavigate();
    let [state, setState] = useState(initialState);
    const [showModal, setShowModal] = useState(false);
    const { token } = useContext(UserContext);
    let [eventsToDelete, setEventsToDelete] = useState([]);
    const dispatch = useDispatch();

    const toggleModal = () => {
        setShowModal(!showModal); //
    }

    const onDismiss = (e) => {
        if (e.target === e.currentTarget) {
            toggleModal();
        }
    }

    const handleCheckbox = (e, id) => {
        if (e.target.checked) {
            setEventsToDelete([...eventsToDelete, id]);
        } else {
            setEventsToDelete(eventsToDelete.filter(event => event.id !== id));
        }
    }

    useEffect(() => {
        getEvents();
    }, []);

    async function getEvents(q = state.query, page = state.page, perPage = state.perPage, order = state.order) {
        dispatch(getAllEvents(token, q, page, perPage, order))
            .then(() => {
                setState({ ...state, events: store.getState().adminEvent.events, query: q, page: page, perPage: perPage, order: order, totalRows: store.getState().adminEvent.totalRows })
            })
            .catch(() => {
                sendNotify('error', 'Events error !', store.getState().adminEvent.message)
            })
    }

    async function deleteEvents() {
        eventsToDelete.map((id) => {
            dispatch(deleteEvent(token, id))
                .then(() => {
                    setEventsToDelete([]);
                    setShowModal(false);
                    getEvents();
                })
                .catch(() => {
                    sendNotify('error', 'Events error !', store.getState().adminEvent.message)
                })
        })
    }

    const handleQueryChange = (e) => {
        getEvents(e.target.value, 1);
    }

    const createArray = (n) => {
        var array = [];
        for (let i = 1; i <= n; i++) {
            array.push(i);
        }

        return array;
    }

    function nbrPage(perPage, totalRows) {
        perPage = parseFloat(perPage)
        totalRows = parseFloat(totalRows)
        let totalPages = totalRows / perPage

        if (parseInt(totalPages) === 0 || parseInt(totalPages) < parseFloat(totalPages)) {
            return parseInt(totalPages) + 1
        } else {
            return totalPages
        }
    }

    async function getEventsByPage(page) {
        if (page > 0 && page <= nbrPage(state.perPage, state.totalRows)) {
            await getEvents(state.query, page)
        }
    }

    return (
        <>
            <AdminWrapper>
                <div className=" h-40 flex items-center justify-center bg-[#2693d0]">
                    <div className="flex items-center space-x-2">
                        <h1 className="cursor-pointer select-none text-white text-5xl font-medium">Events</h1>
                    </div>
                </div>
                <div className="w-5/6 md:w-[70%] mx-auto my-10">
                    <form>
                        <div className="flex ">
                            <div className="relative w-full">
                                <input type="search" onChange={handleQueryChange} id="search-dropdown" className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-l-2 border border-gray-300 focus:border-none focus:outline-none" placeholder="Search..." required />
                                <div type="submit" className="absolute top-0 right-0 p-3 text-sm font-medium h-full text-gray-700 ">
                                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                    <span className="sr-only">Search</span>
                                </div>
                            </div>
                        </div>
                    </form>

                    <hr className="my-5 w-full" />

                    <div className="w-full flex justify-end space-x-4">
                        <div className="py-2 px-3 border border-red-400 text-red-500 hover:bg-red-500 hover:text-white rounded-md hover:cursor-pointer" onClick={() => {
                            if (eventsToDelete.length > 0)
                                setShowModal(true)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                        </div>

                        <Link to={"/admin/events/new"} className="flex items-center bg-green-500 px-8 space-x-2 rounded-md">
                            <h1 className="text-white text-lg font-medium">Add</h1>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-white select-none">
                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                            </svg>
                        </Link>
                    </div>

                    <hr className="my-5 w-full" />

                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
                        <table className="w-full text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-100 ">
                                <tr>
                                    <th scope="col" className="p-4">
                                        <div className="flex items-center">
                                            <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" />
                                            <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                        </div>
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Scope
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Type
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Teams Size
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Start Date
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        End Date
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    state.events.map((event, index) => {
                                        return (
                                            <tr className="bg-white border-b hover:bg-gray-50 cursor-pointer" key={index} onClick={(e) => {
                                                if (e.target.type !== "checkbox")
                                                    navigate('/admin/events/' + event._id);

                                            }}>
                                                <td className="w-4 p-4">
                                                    <div className="flex items-center">
                                                        <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" onChange={(e) => {
                                                            handleCheckbox(e, event._id)
                                                        }} />
                                                        <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                                                    </div>
                                                </td>
                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900">
                                                    {event.name}
                                                </th>
                                                <td className="px-6 py-4">
                                                    {event.scope}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {event.isSolo}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {event.minTeamSize} à {event.maxTeamSize}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {moment(event.startDate).format('LL')}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {moment(event.endDate).format('LL')}
                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    <nav className="flex items-center justify-between pt-5" aria-label="Table navigation">
                        <span className="text-sm font-normal text-gray-500">Affichage <span className="font-semibold text-gray-900">{state.events.length > 0 ? (state.page - 1) * state.perPage + 1 : "0"}-{(state.page - 1) * state.perPage + state.events.length}</span> de <span className="font-semibold text-gray-900">{state.totalRows}</span> event(s)</span>

                        {state.totalRows > state.perPage && <ul className="inline-flex -space-x-px text-sm h-8">
                            {state.page > 1 && <li>
                                <button onClick={() => {
                                    getEventsByPage(state.page - 1)
                                }} className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700">Previous</button>
                            </li>}

                            {
                                createArray(nbrPage(state.perPage, state.totalRows)).map((page) => {
                                    return (
                                        <li key={page}>
                                            <button onClick={() => {
                                                getEventsByPage(page)
                                            }} className={`flex items-center justify-center px-3 h-8 leading-tight ${page === state.page ? "text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700" : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"}`}>{page}</button>
                                        </li>
                                    )
                                })
                            }

                            {nbrPage(state.perPage, state.totalRows) !== state.page && <li>
                                <button onClick={() => {
                                    getEventsByPage(state.page + 1)
                                }} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700">Next</button>
                            </li>}
                        </ul>}
                    </nav>
                </div>

            </AdminWrapper>

            {showModal && <div className="h-screen bg-black absolute w-full z-10 top-0 bg-opacity-50 overflow-auto" onClick={(e) => onDismiss(e)}>
                <div className="w-1/5 mx-auto bg-white px-5 py-5 rounded-lg mt-10">
                    <p className="text-center text-lg">Souhaitez-vous vraiment supprimez {eventsToDelete.length} évènement(s) ?</p>

                    <div className="flex justify-center mt-8 space-x-4">
                        <div className="bg-red-500 px-3 py-1 rounded-md cursor-pointer" onClick={() => deleteEvents()}>
                            <p className="text-white text-base ">confirmer</p>
                        </div>

                        <div className="bg-gray-500 px-3 py-1 rounded-md cursor-pointer" onClick={() => setShowModal(false)}>
                            <p className="text-white text-base ">Annuler</p>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
};

export default EventsScreen;