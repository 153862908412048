import React, { useContext, useState } from "react";
import AdminWrapper from "../../components/admin/AdminWrapper";
import { getLocalStorage } from "../../utils/storage";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createEvent } from "../../store/actions/adminEventsActions";
import store from "../../store";
import { sendNotify } from "../../hooks/notify/notify";
import InputField2 from "../../components/ui/InputField2";
import TextArea from "../../components/ui/TextArea";
import ButtonUi from "../../components/ui/ButtonUi";
import { validateName, validatePeriode, valideTeamSize } from "../../utils/validationUtils";
import { eventMode } from "../../constants/admin/EventMode";
import { eventScope } from "../../constants/admin/EventScope";
import { UserContext } from "../../context/userContext";


function CreateEventScreen() {
    const [state, setState] = useState({});
    const { token } = useContext(UserContext);
    const dispatch = useDispatch();
    const [eventData, setEventData] = useState({
        name: "",
        description: "",
        isSolo: eventMode.SOLO,
        minTeamSize: "0",
        maxTeamSize: "0",
        scope: eventScope.EPITECH,
        startDate: "",
        endDate: ""
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function addEvent() {
        setLoading(true);
        dispatch(createEvent(token, eventData))
            .then(() => {
                setState({ ...state, event: store.getState().adminEvent.event })
                sendNotify('success', 'Create event !', "Event created successfully")
                setLoading(false);
                navigate("/admin/events/");
            }).catch((err) => {
                sendNotify('error', 'Create event error !', store.getState().adminEvent.message);
                setLoading(false);
            });
    }

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        if (name === "minTeamSize" || name === "maxTeamSize") {
            e.target.value = value.replace(/[^0-9]/g, '');
            setEventData((prevFields) => ({ ...prevFields, [name]: value.replace(/[^0-9]/g, '') }));
        } else {
            setEventData((prevFields) => ({ ...prevFields, [name]: value }));
            if (name === "isSolo" && value === "solo") {
                setEventData((prevFields) => ({ ...prevFields, minTeamSize: "0", maxTeamSize: "0", }));
            } else {
                setEventData((prevFields) => ({ ...prevFields, minTeamSize: "", maxTeamSize: "", }));
            }
        }
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleSubmit = async () => {
        const newErrors = {};

        // Validation des champs avec les fonctions de validation
        newErrors.name = validateName(eventData.name);
        newErrors.description = !eventData.description ? "Description is required" : null;
        newErrors.periode = validatePeriode(eventData.startDate, eventData.endDate);
        if(eventData.isSolo === eventMode.TEAM){
            newErrors.teamsize = valideTeamSize(eventData.minTeamSize, eventData.maxTeamSize);
        }
        setErrors(newErrors);

        // Vérifie si toutes les valeurs dans newErrors sont null
        const areAllErrorsNull = Object.values(newErrors).every(
            (error) => error === null
        );

        if (areAllErrorsNull) {
            addEvent();
        }
    };

    return (
        <>
            <AdminWrapper>

                <div className=" h-40 flex items-center justify-center bg-[#2693d0]">
                    <div className="flex items-center space-x-2">
                        <h1 className="cursor-pointer select-none text-white text-5xl font-medium">Create Event</h1>
                    </div>
                </div>

                <div className="w-5/6 md:w-2/5 mx-auto my-10">

                    <div className="flex flex-col space-y-5 w-full">
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="event_avatar">Cover Image</label>
                            {/* <div className="w-full bg-gray-100 h-44 my-4">
                            </div> */}
                            <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none " aria-describedby="event_avatar_help" id="event_avatar" type="file" />
                        </div>

                        <InputField2
                            label={"Nom"}
                            type={"text"}
                            placeholder={"Entrez le Nom "}
                            name={"name"}
                            value={eventData.name}
                            error={errors.name}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({ ...prevErrors, name: "" }))
                            }
                        />


                        <TextArea
                            label={"Descriptions"}
                            type={"text"}
                            placeholder={"Descriptions.. "}
                            name={"description"}
                            value={eventData.description}
                            error={errors.description}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({ ...prevErrors, name: "" }))
                            }
                        />

                        <div>
                            <label htmlFor="scope" className="block mb-2 text-sm font-medium text-gray-900">Scope</label>
                            <select name="scope" id="scope" defaultValue={eventScope.EPITECH} className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 " onChange={handleFieldChange}>
                                {
                                    Object.keys(eventScope).map((key, index) => {
                                        return <option key={index} value={eventScope[key]}>{eventScope[key].toUpperCase()}</option>
                                    })
                                }
                            </select>
                        </div>

                        <div>
                            <label htmlFor="periode" className="block mb-2 text-sm font-medium text-gray-900">Periode</label>
                            <div className="flex space-x-3 items-center">
                                <input type="date" name="startDate" className="w-full border-gray-300 text-gray-500 rounded-md " onChange={handleFieldChange} />
                                <p className="text-gray-500"> au</p>
                                <input type="date" name="endDate" className="w-full border-gray-300 text-gray-500 rounded-md " onChange={handleFieldChange} />
                            </div>
                            {errors.periode && <div className="text-[#ff0000]">{errors.periode}</div>}
                        </div>

                        <div>
                            <label htmlFor="isSolo" className="block mb-2 text-sm font-medium text-gray-900">Mode</label>
                            <select id="isSolo" name="isSolo" defaultValue={eventMode.SOLO} className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" onChange={handleFieldChange}>
                                {
                                    Object.keys(eventMode).map((key, index) => {
                                        return <option key={index} value={eventMode[key]}>{eventMode[key].toUpperCase()}</option>
                                    })
                                }
                            </select>
                        </div>

                        <div>
                            <label htmlFor="teammate" className="block mb-2 text-sm font-medium text-gray-900">Teammate</label>
                            <div className="flex space-x-3 items-center">
                                <div className="flex flex-col w-full space-y-1">
                                    <input id="min" disabled={eventData.isSolo === "solo"} value={eventData.minTeamSize} name="minTeamSize" type="text" className="w-full border-gray-300 text-gray-500 rounded-md "
                                        onChange={handleFieldChange}
                                    />
                                    <label className="text-xs text-gray-500" htmlFor="min">Min</label>
                                </div>
                                <div className="flex flex-col w-full space-y-1">
                                    <input id="max" disabled={eventData.isSolo === "solo"} value={eventData.maxTeamSize} name="maxTeamSize" type="text" className="w-full border-gray-300 text-gray-500 rounded-md "
                                        onChange={handleFieldChange}
                                    />
                                    <label className="text-xs text-gray-500" htmlFor="max">Max</label>
                                </div>
                            </div>
                            {errors.teamsize && <div className="text-[#ff0000]">{errors.teamsize}</div>}
                        </div>

                        <div className="flex justify-end w-full">

                            <div className="w-2/6">
                                <ButtonUi
                                    handleSubmit={handleSubmit}
                                    isLoading={loading}
                                    label={"Submit"}
                                />
                            </div>
                        </div>

                    </div>
                </div>

            </AdminWrapper>
        </>
    )
};

export default CreateEventScreen;