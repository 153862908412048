import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Card = ({ name, role, image }) => {
  const [hovered, setHovered] = useState(false);

  const socialIcons = [
    { icon: faTwitter, link: 'https://twitter.com/' },
    { icon: faFacebook, link: 'https://facebook.com/' },
    { icon: faLinkedin, link: 'https://linkedin.com/' },
  ];

  return (
    <div className="relative rounded-lg overflow-hidden bg-white shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer">
      <img src={image} alt={name} className="w-full h-64 object-cover" />

      <div
        className={`absolute inset-0 flex flex-col justify-between items-center p-6 text-white ${hovered ? 'bg-blue-950/60' : 'bg-black/40'
          } transition duration-300`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-2">{name}</h2>
          <p className="text-lg">{role}</p>
        </div>

        {hovered && (
          <div className="flex space-x-4">
            {socialIcons.map((item, index) => (
              <a
                key={index}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-200 transition duration-300"
              >
                <FontAwesomeIcon icon={item.icon} size="2x" />
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
