// eslint-disable-next-line no-unused-vars
import React from "react";
import { Route, Routes } from "react-router-dom";
import ErrorScreen from "../screens/common/ErrorScreen";
import UserRouterConstant from "../constants/user/routerConstant";

const UserRouter = () => {
    const routes = UserRouterConstant();
    return (
        <Routes>
            {
                routes.map((route, index) => {
                    return <Route key={`${route.name}` + index} path={route.path} element={<route.screen />} />
                })
            }
            <Route path='*' element={<ErrorScreen />} />
        </Routes>
    )
};

export default UserRouter;