// admin user
const GET_ALL_USERS = 'All users got'
const GET_ALL_USERS_FAIL = 'Failed to get all users'
const CREATE_USER = 'User created'
const CREATE_USER_FAIL = 'User creating failed'
const UPDATE_USER = 'User updated'
const UPDATE_USER_FAIL = 'User updating failed'
const DELETE_USER = 'User deleted'
const DELETE_USER_FAIL = 'User deleting failed'
const MAKE_ADMIN = 'User status modified'
const MAKE_ADMIN_FAIL = 'User status modifying failed'
const ENABLE_USER = 'User enable status modified'
const ENABLE_USER_FAIL = 'User enable status modifying failed'
const EXPORT_USERS_TABLE = 'Users table exported'
const EXPORT_USERS_TABLE_FAIL = 'Users table export failed'
const GET_USER = 'Get a user'
const GET_USER_FAIL = 'Getting a user failed'


//admin event 
const GET_ALL_EVENTS = 'All events fetched';
const GET_ALL_EVENTS_FAIL = 'Failed to fetch all events';
const GET_EVENT = 'Get an event';
const GET_EVENT_FAIL = 'Getting an event failed';
const CREATE_EVENT = 'Event created';
const CREATE_EVENT_FAIL = 'Event creation failed';
const UPDATE_EVENT = 'Event updated';
const UPDATE_EVENT_FAIL = 'Event updating failed';
const DELETE_EVENT = 'Event deleted';
const DELETE_EVENT_FAIL = 'Event deletion failed';


//admin category
const GET_ALL_CATEGORY = 'All categories fetched';
const GET_ALL_CATEGORY_FAIL = 'Failed to fetch all categories';
const GET_CATEGORY = 'Get an category';
const GET_CATEGORY_FAIL = 'Getting an category failed';
const CREATE_CATEGORY = 'Category created';
const CREATE_CATEGORY_FAIL = 'Category creation failed';
const UPDATE_CATEGORY = 'Category updated';
const UPDATE_CATEGORY_FAIL = 'Category updating failed';
const DELETE_CATEGORY = 'Category deleted';
const DELETE_CATEGORY_FAIL = 'Category deletion failed';

//admin challenge
const GET_ALL_CHALLENGES = 'All challenges fetched';
const GET_ALL_CHALLENGES_FAIL = 'Failed to fetch all challenges';
const GET_CHALLENGE_SOLVERS = 'Get challenge solvers';
const GET_CHALLENGE_SOLVERS_FAIL = 'Getting challenge solvers failed';
const GET_CHALLENGE = 'Get an challenge';
const GET_CHALLENGE_FAIL = 'Getting an challenge failed';
const CREATE_CHALLENGE = 'Challenge created';
const CREATE_CHALLENGE_FAIL = 'Challenge creation failed';
const UPDATE_CHALLENGE = 'Challenge updated';
const UPDATE_CHALLENGE_FAIL = 'Challenge updating failed';
const DELETE_CHALLENGE = 'Challenge deleted';
const DELETE_CHALLENGE_FAIL = 'Challenge deletion failed';
const DELETE_CHALLENGE_FILES = 'Challenge file deleted';
const DELETE_CHALLENGE_FILES_FAIL = 'Challenge file deletion failed';

//admin submission
const GET_ALL_SUBMISSIONS = "All submissions fetched"
const GET_ALL_SUBMISSIONS_FAIL = "Failed to fetch all submissions"
const GET_CHALLENGE_SUBMISSIONS = "Get challenge submissions"
const GET_CHALLENGE_SUBMISSIONS_FAIL = "Getting challenge submissions failed"
const GET_SUBMISSION = "Get an submission"
const GET_SUBMISSION_FAIL = "Getting an submission failed"


export {
    GET_ALL_USERS,
    GET_ALL_USERS_FAIL,
    CREATE_USER,
    CREATE_USER_FAIL,
    GET_USER,
    GET_USER_FAIL,
    UPDATE_USER,
    UPDATE_USER_FAIL,
    DELETE_USER,
    DELETE_USER_FAIL,
    MAKE_ADMIN,
    MAKE_ADMIN_FAIL,
    ENABLE_USER,
    ENABLE_USER_FAIL,
    EXPORT_USERS_TABLE,
    EXPORT_USERS_TABLE_FAIL,
    GET_ALL_EVENTS,
    GET_ALL_EVENTS_FAIL,
    CREATE_EVENT,
    CREATE_EVENT_FAIL,
    UPDATE_EVENT,
    UPDATE_EVENT_FAIL,
    DELETE_EVENT,
    DELETE_EVENT_FAIL,
    GET_EVENT,
    GET_EVENT_FAIL,
    GET_ALL_CATEGORY,
    GET_ALL_CATEGORY_FAIL,
    GET_CATEGORY,
    GET_CATEGORY_FAIL,
    CREATE_CATEGORY,
    CREATE_CATEGORY_FAIL,
    UPDATE_CATEGORY,
    UPDATE_CATEGORY_FAIL,
    DELETE_CATEGORY,
    DELETE_CATEGORY_FAIL,
    GET_ALL_CHALLENGES,
    GET_ALL_CHALLENGES_FAIL,
    GET_CHALLENGE,
    GET_CHALLENGE_FAIL,
    GET_CHALLENGE_SOLVERS,
    GET_CHALLENGE_SOLVERS_FAIL,
    CREATE_CHALLENGE,
    CREATE_CHALLENGE_FAIL,
    UPDATE_CHALLENGE,
    UPDATE_CHALLENGE_FAIL,
    DELETE_CHALLENGE,
    DELETE_CHALLENGE_FAIL,
    DELETE_CHALLENGE_FILES,
    DELETE_CHALLENGE_FILES_FAIL,
    GET_ALL_SUBMISSIONS,
    GET_ALL_SUBMISSIONS_FAIL,
    GET_CHALLENGE_SUBMISSIONS,
    GET_CHALLENGE_SUBMISSIONS_FAIL,
    GET_SUBMISSION,
    GET_SUBMISSION_FAIL,
}