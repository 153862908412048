import { faSchool } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext, useState } from "react";
import { getLocalStorage } from "../../utils/storage";
import { UserContext } from "../../context/userContext";


const EventSelect = ({
    type,
    name,
    placeholder,
    value,
    error,
    onChange,
    onBlur,
    password,
    label
}) => {

    const [events, setEvents] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const { token } = useContext(UserContext);

    let query = "";

    const getEvent = async () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/events?q=${query}&perPage=100&page=1`, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                }
            }).then((resp) => {
                if (resp.status === 200) {
                    const data = resp.data;
                    let events = [];
                    data.events.map((event) => {
                        events.push(event);
                    })
                    setEvents(events.length > 0 ? events : [query]);
                } else {
                    setEvents([query]);
                }
            });
        } catch (error) {
            setEvents([query]);
        }
    }

    const onItemClicked = (event) => {
        onChange(event);
        setEvents([]);
    }

    const onInputChange = (e) => {
        query = e.target.value;
        onChange(e.target.value);
        setEvents([query]);

        if (query !== "") {
            getEvent();
        } else {
            setEvents([]);
        }
    }

    return (
        <div className="w-full flex flex-col ">
            {label && <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900">{label}</label>}
            <div className="flex justify-center relative">
                <input
                    type={type}
                    name={name}
                    id={name}
                    className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 ${error ? " border-red-300 focus:ring-red-500 focus:border-red-500" : " border-gray-300 focus:ring-blue-500 focus:border-blue-500"}`}
                    placeholder={placeholder}
                    value={value}
                    onChange={onInputChange}
                    onBlur={(e) => {
                        onBlur();
                        setIsFocused(false);
                    }}
                    onFocus={(e) => {
                        setIsFocused(true);
                    }}
                />

                {isFocused && events.length !== 0 && <div className="max-h-[200px] w-full border border-gray-300 rounded-lg absolute z-10 bg-gray-50 top-12 mb-32 overflow-scroll">
                    {events.map((event, index) => {
                        return (
                            <div key={index} onMouseDown={(e) => onItemClicked(event)} className={`p-2 hover:bg-gray-200 ${index === 0 ? "rounded-t-lg" : ""} ${index === events.length - 1 ? "rounded-b-lg" : ""} cursor-pointer flex items-center space-x-3`}>
                                <span>{event.name}</span>
                            </div>
                        )
                    })}
                </div>}
            </div>

            {error && <div className="text-[#ff0000]">{error}</div>}
        </div>
    )
}


export default EventSelect;