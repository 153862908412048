import axios from 'axios';
//import "dotenv";

const API_URL = process.env.REACT_APP_API_URL;

const getAllEvents = (token, q, page, perPage, order) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/events?q=' + q + '&page=' + page + '&perPage=' + perPage + '&order=' + order, { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

const getEvent = (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/events/' + id, { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

const createEvent = (token, event) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
    };

    return axios.post(API_URL + '/events/', event, { headers })
        .then((response) => {
            return response.data
        }).catch((err) => {
            throw err.response
        })
}

const updateEvent = (token, event) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios.put(API_URL + '/events/' + event._id, event, { headers }).then((response) => {
        return response.data;
    }).catch((err) => {
        throw err.response;
    });
};

const deleteEvent = (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios.delete(API_URL + '/events/' + id, { headers })
        .then((response) => {
            return response.data
        }).catch((err) => {
            throw err.response
        });
};

const makeAdmin = (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/events/' + id + '/admin', { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

const enableEvent = (token, id) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/events/' + id + '/enable', { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

const exportEventsExcel = (token) => {
    const headers = {
        'Authorization': 'Bearer ' + token,
    };
    return axios
        .get(API_URL + '/events/excel', { headers })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            throw err.response
        });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAllEvents,
    getEvent,
    updateEvent,
    deleteEvent,
    exportEventsExcel,
    enableEvent,
    makeAdmin,
    createEvent
};