import Module1 from "../../assets/Pictures/module1.jpg";
import Module2 from "../../assets/Pictures/module2.jpg";
import Module3 from "../../assets/Pictures/module3.jpg";
import UserWrapper from "../../components/user/common/UserWrapper";
import CardEvents from "../../components/user/events/CardEvents";
import CardFilter from "../../components/user/events/CardFilter";

function EventsScreen() {
  return (
    <UserWrapper>
      <section id="events" className="w-full text-white px-16 pt-4">
        {/* Filter */}
        <CardFilter />
        {/* Events */}
        <div className="w-full flex justify-center items-center gap-10 flex-wrap">
          {/* Cards */}
          <CardEvents banner={Module1} type={1} id={1} />
          <CardEvents banner={Module2} type={2} id={2} />
          <CardEvents banner={Module3} type={3} id={3} />
        </div>
        <div className="w-full flex py-16 justify-center items-center">
          <button className="text-blue-600 border-2 border-blue-600 rounded-full py-2 px-16 hover:bg-blue-950 hover:text-white hover:border-white">
            Load more ...
          </button>
        </div>
      </section>
    </UserWrapper>
  );
}

export default EventsScreen;
