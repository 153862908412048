/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import AuthWrapper from "../../components/auth/AuthWrapper";
import { Link, useNavigate } from "react-router-dom";
import CountrySelect from "../../components/ui/CountriesSelect";
import InputField from "../../components/ui/InputField";
import {
  validateEmail,
  validateName,
  validatePassword,
  validatePasswordConfirm,
  validateSchool,
} from "../../utils/validationUtils";
import CheckBox from "../../components/ui/CheckBox";
import ButtonUi from "../../components/ui/ButtonUi";
import Logo from "../../assets/Logo/logo.png";
import authServices from "../../services/auth/authServices";
import { sendNotify } from "../../hooks/notify/notify";
import { AuthMessage } from "../../constants/common/AuthMessage";
import SchoolSelect from "../../components/ui/SchoolSelect";

function RegisterScreen() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({
    name: "",
    email: "",
    isStudent: "no",
    country: null,
    school: "",
    password: "",
    confirm_password: "",
  });

  // Checked isStudent
  const handleStudentChange = (isChecked) => {
    setFields((prevFields) => ({
      ...prevFields,
      isStudent: isChecked ? "yes" : "no",
    }));
  };

  // Select Country
  const handleCountrySelection = (selectedCountry) => {
    setFields((prevFields) => ({
      ...prevFields,
      country: selectedCountry.label,
    }));
  };

  // Save Fields
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSchoolChange = (school) => {
    setFields((prevFields) => ({
      ...prevFields,
      school: school,
    }));
  }

  // Soumission de formulaire
  const handleSubmit = async () => {
    const newErrors = {};

    // Validation des champs avec les fonctions de validation
    newErrors.country = !fields.country ? "Country is required" : null;
    newErrors.name = validateName(fields.name);
    newErrors.email = validateEmail(fields.email);
    newErrors.password = validatePassword(fields.password);
    newErrors.confirm_password = validatePasswordConfirm(
      fields.password,
      fields.confirm_password
    );

    if (fields.isStudent === "yes") {
      newErrors.school = validateSchool(fields.school);
    }

    setErrors(newErrors);

    // Vérifie si toutes les valeurs dans newErrors sont null
    const areAllErrorsNull = Object.values(newErrors).every(
      (error) => error === null
    );

    if (areAllErrorsNull) {
      setIsLoading(true); // Déclenche le chargement
      const { status } = await authServices.register(fields)
      switch (status) {
        case 200:
          sendNotify("success", "Register", AuthMessage.REGISTER_SUCCESS);
          sendNotify("success", "Register", AuthMessage.SEND_MAIL);
          navigate("/auth/login")
          break;
        case 409:
          setIsLoading(false)
          sendNotify("error", "Register", AuthMessage.REGISTER_EXIST);
          break;
        default:
          setIsLoading(false)
          sendNotify("error", "Register", AuthMessage.NOT_CONNEXION);
          break
      }
    }
  };

  return (
    <AuthWrapper>
      <div className="w-full h-[100vh] flex flex-col justify-center lg:w-[80%] lg:h-auto lg:rounded-xl shadow p-6 my-auto">
        {/* Logo Start */}
        <Link
          to="/"
          className="cursor-pointer flex flex-col justify-center items-center lg:hidden mb-10"
        >
          <img src={Logo} className="w-48" />
        </Link>
        {/* Logo End */}

        {/* Container Start */}
        <div className="w-full flex flex-col mb-2">
          <h3 className="text-2xl lg:text-3xl font-semibold mb-2">
            Register to your Account
          </h3>
          <p className="text-md lg:text-base mb-2">Welcome! Please enter your details.</p>

          {/* Fields */}
          <div className="w-full flex flex-col">
            {/* Username */}
            <InputField
              type="text"
              name="name"
              placeholder="Enter your name"
              value={fields.name}
              error={errors.name}
              onChange={handleFieldChange}
              onBlur={() =>
                setErrors((prevErrors) => ({ ...prevErrors, name: "" }))
              }
            />
            <InputField
              type="email"
              name="email"
              placeholder="Enter your email"
              value={fields.email}
              error={errors.email}
              onChange={handleFieldChange}
              onBlur={() =>
                setErrors((prevErrors) => ({ ...prevErrors, email: "" }))
              }
            />
            <InputField
              type="password"
              name="password"
              placeholder="Enter your password"
              value={fields.password}
              error={errors.password}
              onChange={handleFieldChange}
              onBlur={() =>
                setErrors((prevErrors) => ({ ...prevErrors, password: "" }))
              }
              password
            />
            <InputField
              type="password"
              name="confirm_password"
              placeholder="Enter your confirm password"
              value={fields.confirm_password}
              error={errors.confirm_password}
              onChange={handleFieldChange}
              onBlur={() =>
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  confirm_password: "",
                }))
              }
              password
            />

            {/* Country Select */}
            <CountrySelect
              onCountryChange={handleCountrySelection}
              error={errors.country}
            />

            {/* Checkbox for isStudent */}
            <CheckBox
              onChange={handleStudentChange}
              label={"Are you student ?"}
            />

            {/* Conditional rendering of school input */}
            {fields.isStudent === "yes" && (
              <SchoolSelect
                type="text"
                name="school"
                placeholder="Enter your school"
                value={fields.school}
                error={errors.school}
                onChange={handleSchoolChange}
                onBlur={() =>
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    school: "",
                  }))
                }
              />
            )}
          </div>

          <div className="w-full flex flex-col my-4">
            <ButtonUi
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              label={"Register"}
            />
          </div>
        </div>

        <div className="w-full flex items-center justify-center">
          <p className="text-sm font-normal text-[#060606]">
            Already have an account?{" "}
            <span className="font-semibold underline underline-offset-2 cursor-pointer hover:text-[#2693d0]">
              <Link to={"/auth/login"}>Sign in.</Link>
            </span>
          </p>
        </div>
      </div>
    </AuthWrapper>
  );
}

export default RegisterScreen;
