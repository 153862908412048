import React, { useState } from "react";
import AuthWrapper from "../../components/auth/AuthWrapper";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail } from "../../utils/validationUtils";
import InputField from "../../components/ui/InputField";
import ButtonUi from "../../components/ui/ButtonUi";
import Logo from "../../assets/Logo/logo.png";
import authServices from "../../services/auth/authServices";
import { sendNotify } from "../../hooks/notify/notify";
import { AuthMessage } from "../../constants/common/AuthMessage";

function ForgotPasswordScreen() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      const emailError = validateEmail(email);
      if (emailError) {
        setError(emailError);
        return;
      }

      setLoading(true);
      const res = await authServices.forgotPassword(email);

      if (res === 200) {
        sendNotify("success", "Forgot Password", AuthMessage.SEND_MAIL);
        navigate(`/auth/login`);
      } else if (res === 404) {
        sendNotify("error", "Forgot Password", AuthMessage.EMAIL_NOT_FOUND);
        navigate(`/auth/login`);
      } else {
        throw new Error(AuthMessage.NOT_CONNEXION);
      }
    } catch (error) {
      // Handle errors appropriately
    } finally {
      setLoading(false);
    }
  };


  return (
    <AuthWrapper>
      <div className="w-[80%] rounded-xl shadow p-6 my-auto flex flex-col items-center">
        {/* Logo Start */}
        <Link
          to="/"
          className="cursor-pointer flex flex-col justify-center items-center mb-10 lg:hidden"
        >
          <img src={Logo} alt="Logo" className="w-48" />
        </Link>
        {/* Logo End */}
        <div className="w-full flex flex-col max-w-[80%] pt-2">
          <div className="w-full flex flex-col mb-2">
            <h3 className="text-3xl font-semibold mb-2">Forgot Password</h3>
            <p className="text-base mb-2">
              Forgot your password? Complete the following field.
            </p>
          </div>

          <div className="w-full flex flex-col">
            <InputField
              type="email"
              name="email"
              placeholder="Enter your email"
              value={email}
              error={error}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => { setError("") }}
            />
          </div>

          <div className="w-full flex flex-col my-4">
            <ButtonUi
              handleSubmit={handleSubmit}
              isLoading={loading}
              label={"Recovery password"}
            />
          </div>
        </div>

        <div className="w-full flex items-center justify-center">
          <p className="text-sm font-normal text-[#060606]">
            Already have an account?{" "}
            <span className="font-semibold underline underline-offset-2 cursor-pointer hover:text-[#2693d0]">
              <Link to={"/auth/login"}>Sign in.</Link>
            </span>
          </p>
        </div>
      </div>
    </AuthWrapper>
  );
}

export default ForgotPasswordScreen;
