// eslint-disable-next-line no-unused-vars
import React from "react";
import { Route, Routes } from "react-router-dom";
import ErrorScreen from "../screens/common/ErrorScreen";
import HomeRouterConstant from "../constants/home/routerConstant";


const HomeRouter = () => {
    return (
        <Routes>
            {
                HomeRouterConstant.map((route, index) => {
                    return <Route key={`${route.name}` + index} path={route.path} element={<route.screen />} />
                })
            }
            <Route path='*' element={<ErrorScreen />} />
        </Routes>
    )
};

export default HomeRouter;