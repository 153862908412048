import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useDispatch } from "react-redux";
import store from "../../../store";
import { sendNotify } from "../../../hooks/notify/notify";
import { getChallengeSolvers } from "../../../store/actions/adminChallengesActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const initialState = {
    query: '',
    solvers: [],
    challenge: null,
    perPage: 10,
    page: 1,
    totalRows: 0,
    order: 'asc',
}

function DetailSectionSolver({ challenge }) {
    const { token } = useContext(UserContext);
    let [state, setState] = useState(initialState);
    const dispatch = useDispatch();

    async function getSolvers(q = state.query, page = state.page, perPage = state.perPage, order = state.order) {
        dispatch(getChallengeSolvers(token, q, page, perPage, order, challenge.challenge._id))
            .then(() => {
                setState({ ...state, solvers: store.getState().adminChallenge.solvers, query: q, page: page, perPage: perPage, order: order, totalRows: store.getState().adminChallenge.totalRows })
            })
            .catch(() => {
                sendNotify('error', 'Solvers error !', store.getState().adminChallenge.message)
            })
    }

    useEffect(() => {
        getSolvers();
    }, []);

    const handleQueryChange = (e) => {
        getSolvers(e.target.value, 1);
    }

    const createArray = (n) => {
        var array = [];
        for (let i = 1; i <= n; i++) {
            array.push(i);
        }

        return array;
    }

    function nbrPage(perPage, totalRows) {
        perPage = parseFloat(perPage)
        totalRows = parseFloat(totalRows)
        let totalPages = totalRows / perPage

        if (parseInt(totalPages) === 0 || parseInt(totalPages) < parseFloat(totalPages)) {
            return parseInt(totalPages) + 1
        } else {
            return totalPages
        }
    }

    async function getSolversByPage(page) {
        if (page > 0 && page <= nbrPage(state.perPage, state.totalRows)) {
            await getSolvers(state.query, page)
        }
    }


    const handleDownloadClick = (filePath, fileName) => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/${filePath}`)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Error downloading the file: ', error);
            });
    };

    return (
        <>
            <div className="mx-auto mt-20 ">
                <div className="flex justify-end ">
                    <div className="relative w-[95%] md:w-[40%] mr-2">
                        <input type="search" onChange={handleQueryChange} id="search-dropdown" className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-l-2 border border-gray-300 focus:border-none focus:outline-none" placeholder="Search..." required />
                        <div type="submit" className="absolute top-0 right-0 p-3 text-sm font-medium h-full text-gray-700 ">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                            <span className="sr-only">Search</span>
                        </div>
                    </div>
                </div>

                <hr className="my-5 w-full" />

                <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-100 ">
                            <tr>
                                <th scope="col" className="p-4">
                                    N°
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Email
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    School
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Country
                                </th>
                                {/* <th scope="col" className="px-6 py-3">
                                    WriteUp
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                state.solvers.map((solver, index) => {
                                    return (
                                        <tr className="bg-white border-b hover:bg-gray-50" key={index} >
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900">
                                                {index + 1}
                                            </th>
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900">
                                                {solver.user.name}
                                            </th>
                                            <td className="px-6 py-4">
                                                {solver.user.email}
                                            </td>
                                            <td className="px-6 py-4">
                                                {solver.user.school?.name}
                                            </td>
                                            <td className="px-6 py-4">
                                                {solver.user.country}
                                            </td>
                                            {/* <td className="px-6 py-4 flex ">
                                                <div className="cursor-pointer p-1" onClick={() => {
                                                    handleDownloadClick()
                                                }}>
                                                    <FontAwesomeIcon icon={faDownload} color="black" />
                                                </div>
                                            </td> */}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

                <nav className="flex items-center justify-between pt-5" aria-label="Table navigation">
                    <span className="text-sm font-normal text-gray-500">Affichage <span className="font-semibold text-gray-900">{state.solvers.length > 0 ? (state.page - 1) * state.perPage + 1 : 0}-{(state.page - 1) * state.perPage + state.solvers.length}</span> de <span className="font-semibold text-gray-900">{state.totalRows}</span> submission(s)</span>

                    {state.totalRows > state.perPage && <ul className="inline-flex -space-x-px text-sm h-8">
                        {state.page > 1 && <li>
                            <button onClick={() => {
                                getSolversByPage(state.page - 1)
                            }} className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700">Previous</button>
                        </li>}

                        {
                            createArray(nbrPage(state.perPage, state.totalRows)).map((page) => {
                                return (
                                    <li key={page}>
                                        <button onClick={() => {
                                            getSolversByPage(page)
                                        }} className={`flex items-center justify-center px-3 h-8 leading-tight ${page === state.page ? "text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700" : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"}`}>{page}</button>
                                    </li>
                                )
                            })
                        }

                        {nbrPage(state.perPage, state.totalRows) !== state.page && <li>
                            <button onClick={() => {
                                getSolversByPage(state.page + 1)
                            }} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700">Next</button>
                        </li>}
                    </ul>}
                </nav>
            </div>
        </>
    )
};

export default DetailSectionSolver;