export const AuthMessage = {
    REGISTER_SUCCESS: "Account Created Successfully...",
    SEND_MAIL: "Mail Sended. Please Checked!",
    REGISTER_FAILED: "failed",
    REGISTER_EXIST: "User Already Exists",

    LOGIN_SUCCESS: "Login Successfull...",
    LOGIN_NOT_VALID: "Invalid Email Or Password",
    MAIL_NOT_CONFIRM: "Email Not Confirmed",
    EMAIL_NOT_FOUND: "Email Not Found",
    NOT_CONNEXION: "Connexion Impossible!",

    ACCOUNT_ACTIVE: "Account Active Successfully...",
    INVALID_CODE: "Invalid Code",

    PASSWORD_RESET: 'Password Reset Successfully. A new password sending in yor mail!'


}