import { useEffect, useState } from "react"
import ChallengeSelect from "../../ui/ChallengeSelect";
import ButtonUi from "../../ui/ButtonUi";
import { useDispatch } from "react-redux";
import { deleteChallenge, deleteChallengeFiles, getChallenge, updateChallenge } from "../../../store/actions/adminChallengesActions";
import { sendNotify } from "../../../hooks/notify/notify";
import store from "../../../store";
import { useNavigate } from "react-router-dom";

const ChallengeModal = ({
    token,
    challenge,
    showModal,
    modalType,
    onDismiss,
    hideConfirmation,
    onUpdate
}) => {

    const [challengeData, setChallengeData] = useState({
        requirements: [],
        files: [],
        challenge: "",
        newFiles: []
    });
    const [loading, setLoading] = useState(false);
    const [requirementsObject, setRequirementsObject] = useState([]);
    const [challengeFiles, setChallengeFiles] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (challenge) {
            setChallengeFiles(challenge.files);
            getRequirementsId(challenge.challenge.requirements);
            setRequirementsObject(challenge.challenge.requirements)
        }
    }, [challenge])

    const getRequirementsId = (requirements) => {
        let requirementsId = [];

        requirements.map((requirement) => {
            requirementsId.push(requirement._id);
        })

        setChallengeData({
            ...challengeData,
            requirements: requirementsId
        });
    }


    const handleChallengeChange = (item) => {

        if (typeof item === 'string') {
            setChallengeData({
                ...challengeData,
                challenge: item
            })
        } else {
            const inArray = requirementsObject.some((element) => {
                return element._id === item._id;
            });

            if (!inArray && item._id !== challenge.challenge._id) {
                setRequirementsObject([
                    ...requirementsObject,
                    item
                ]);

                setChallengeData({
                    ...challengeData,
                    challenge: "",
                    requirements: [
                        ...challengeData.requirements,
                        item._id
                    ]
                })

            } else {
                setChallengeData({
                    ...challengeData,
                    challenge: ""
                })
            }
        }
    }

    const deleteRequirement = (item) => {
        const newRequirementsObject = requirementsObject.filter(requirement => requirement._id !== item._id);
        setRequirementsObject(newRequirementsObject);

        const newRequirementsId = challengeData.requirements.filter(id => id !== item._id);
        setChallengeData({
            ...challengeData,
            requirements: newRequirementsId
        })
    }

    async function updateRequirements() {
        setLoading(true);
        dispatch(updateChallenge(token, challenge.challenge._id, challengeData))
            .then(() => {
                sendNotify('success', 'Update challenge !', "Challenge updated successfully")
                setLoading(false);
            }).catch((err) => {
                sendNotify('error', 'Update challenge error !', store.getState().adminChallenge.message);
                setLoading(false);
            });
    }


    // file's functions
    const handleFileChange = (e) => {
        const files = e.target.files;
        setChallengeData({ ...challengeData, newFiles: files })
    }

    async function updateChallengeFiles() {
        setLoading(true);

        const formData = new FormData();

        for (const file of challengeData.newFiles) {
            formData.append("files", file);
        }

        dispatch(updateChallenge(token, challenge.challenge._id, formData))
            .then(() => {
                deleteFiles();
            }).catch((err) => {
                sendNotify('error', 'Update challenge error !', store.getState().adminChallenge.message);
                setLoading(false);
            });
    }

    const deleteFiles = () => {
        dispatch(deleteChallengeFiles(token, {files : filesToDelete}))
            .then(() => {
                sendNotify('success', 'Update challenge !', "Challenge updated successfully")
                setLoading(false);
            }).catch((err) => {
                sendNotify('error', 'Update challenge error !', store.getState().adminChallenge.message);
                setLoading(false);
            })
    }

    const addFileToDelete = (item) => {
        const newFiles = challengeFiles.filter(file => file._id !== item._id);
        setChallengeFiles(newFiles);

        setFilesToDelete([
            ...filesToDelete,
            item._id
        ]);
    }

    async function deleteOneChallenge() {
        setLoading(true);
        dispatch(deleteChallenge(token, challenge.challenge._id))
            .then(() => {
                hideConfirmation();
                navigate("/admin/challenges");
            })
            .catch(() => {
                sendNotify('error', 'Challenges error !', store.getState().adminChallenge.message)
            })
    }

    return (
        showModal &&
        <div className="h-screen bg-black absolute w-full z-10 top-0 bg-opacity-50" onClick={(e) => onDismiss(e)}>
            {
                modalType === "requirements" ?
                    <div className="w-[80%] sm:w-[70%] md:w-[40%] mx-auto my-10 bg-white p-5 md:p-10 rounded-lg ">
                        <div className="flex flex-col space-y-5">
                            <span className="text-gray-800 text-sm md:text-lg font-bold">Requirements</span>

                            <div className="flex flex-col space-y-1">
                                <span className="text-sm italic text-gray-400">Search for and add a challenge to do before this challenge !</span>
                                <ChallengeSelect
                                    type={"text"}
                                    name="challenge"
                                    placeholder={"required challenge"}
                                    value={challengeData.challenge}
                                    onChange={handleChallengeChange}
                                    onBlur={() => { }}
                                />
                            </div>

                            <div className="w-full flex flex-col space-y-1">
                                <span className="text-sm font-semibold text-gray-600">Required :</span>

                                <div className="w-full border border-gray-200 rounded-md max-h-[500px] overflow-auto">
                                    <table className="w-full text-sm text-left text-gray-500">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-100 rounded-md ">
                                            <tr>
                                                <th scope="col" className="px-6 py-2">
                                                    N°
                                                </th>
                                                <th scope="col" className="px-6 py-2">
                                                    Name
                                                </th>
                                                <th scope="col" className="px-6 py-2">
                                                    Category
                                                </th>
                                                <th scope="col" className="px-6 py-2">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                requirementsObject.map((item, index) => {
                                                    return (
                                                        <tr key={index} className="bg-white border-b hover:bg-gray-50 cursor-pointer">
                                                            <td className="px-6 py-2">
                                                                {index}
                                                            </td>
                                                            <td className="px-6 py-2">
                                                                {item.name}
                                                            </td>
                                                            <td className="px-6 py-2">
                                                                {item.category.name}
                                                            </td>
                                                            <td className="px-6 py-2">
                                                                <div className="cursor-pointer" onClick={() => {
                                                                    deleteRequirement(item)
                                                                }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                    </svg>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>

                                </div>
                            </div>

                            <div className="flex w-full justify-end">
                                <div className="w-2/6">

                                    <ButtonUi
                                        handleSubmit={() => { updateRequirements() }}
                                        isLoading={loading}
                                        label={"Update"}
                                    />
                                </div>
                            </div>

                        </div>
                    </div> :
                    modalType === "files" ?
                        <div className="w-[80%] sm:w-[70%] md:w-[40%] mx-auto my-10 bg-white p-5 md:p-10 rounded-lg ">
                            <div className="flex flex-col space-y-5">
                                <span className="text-gray-800 text-sm md:text-lg font-bold">Challenge files</span>

                                <div>
                                    <label htmlFor="newFiles" className="block mb-2 text-sm font-medium text-gray-900">Files</label>
                                    <input name="newFiles" multiple className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none " aria-describedby="user_avatar_help" id="files" type="file" onChange={handleFileChange} />
                                </div>

                                <div className="w-full flex flex-col space-y-1">
                                    <span className="text-sm font-semibold text-gray-600">Old files :</span>

                                    <div className="w-full border border-gray-200 rounded-md max-h-[500px] overflow-auto">
                                        <table className="w-full text-sm text-left text-gray-500">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-100 rounded-md ">
                                                <tr>
                                                    <th scope="col" className="px-6 py-2">
                                                        N°
                                                    </th>
                                                    <th scope="col" className="px-6 py-2">
                                                        Name
                                                    </th>
                                                    <th scope="col" className="px-6 py-2">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    challengeFiles.map((item, index) => {
                                                        return (
                                                            <tr key={index} className="bg-white border-b hover:bg-gray-50 cursor-pointer">
                                                                <td className="px-6 py-2">
                                                                    {index + 1}
                                                                </td>
                                                                <td className="px-6 py-2">
                                                                    {item.originalname}
                                                                </td>
                                                                <td className="px-6 py-2">
                                                                    <div className="cursor-pointer" onClick={() => {
                                                                        addFileToDelete(item)
                                                                    }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                        </svg>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                                <div className="flex w-full justify-end">
                                    <div className="w-2/6">

                                        <ButtonUi
                                            handleSubmit={() => {

                                                if (modalType === 'requirements') {
                                                    updateRequirements()
                                                } else {
                                                    updateChallengeFiles();
                                                }
                                            }}
                                            isLoading={loading}
                                            label={"Update"}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div> :
                        <div className="w-1/5 mx-auto bg-white px-5 py-5 rounded-lg mt-10">
                            <p className="text-center text-lg">Souhaitez-vous vraiment supprimez le challenge ?</p>

                            <div className="flex justify-center mt-8 space-x-4">
                                <div className={`bg-red-500 ${loading ? "px-10" : "px-3"} py-1 rounded-md cursor-pointer flex items-center`} onClick={() => { deleteOneChallenge() }}>
                                    <p className="text-white text-base ">{loading ? "..." : "confirmer"}</p>
                                </div>

                                <div className="bg-gray-500 px-3 py-1 rounded-md cursor-pointer" onClick={(e) => hideConfirmation()}>
                                    <p className="text-white text-base ">Annuler</p>
                                </div>
                            </div>
                        </div>
            }
        </div>
    )
}

export default ChallengeModal;