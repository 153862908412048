import InitialRedirect from "../../router/initialRedirect";
import CheckCodeAccount from "../../screens/auth/CheckCodeAccount";
import CheckCodePassword from "../../screens/auth/CheckCodePassword";
import ForgotPasswordScreen from "../../screens/auth/ForgotPasswordScreen";
import LoginScreen from "../../screens/auth/LoginScreen";
import RegisterScreen from "../../screens/auth/RegisterScreen";
import ComingSoonScreen from "../../screens/common/ComingSoonScreen";
import AboutUS from "../../screens/home/AboutUS";
import HomeScreen from "../../screens/home/HomeScreen";
import DetailEventsScreen from "../../screens/users/DetailEventsScreen";
import EventsScreen from "../../screens/users/EventsScreen";

const HomeRouterConstant = [
    {
        name: "Home",
        path: "/",
        screen: InitialRedirect
    },
    {
        name: "Home",
        path: "/home",
        screen: HomeScreen
    },
    {
        name: "About Us",
        path: "/about",
        screen: AboutUS
    },
    {
        name: "Competition",
        path: "/events",
        // screen: EventsScreen,
        screen: ComingSoonScreen,
    },
    {
        name: "Competition",
        path: "/events/:id",
        // screen: DetailEventsScreen,
        screen: ComingSoonScreen,
    },
    {
        name: "Bluelock",
        path: "/bluelock",
        screen: ComingSoonScreen,
    },
    {
        name: "Login",
        path: "/auth/login",
        screen: LoginScreen
    },
    {
        name: "Login",
        path: "/login",
        screen: LoginScreen
    },
    {
        name: "Login",
        path: "/challenges",
        screen: LoginScreen
    },
    {
        name: "Register",
        path: "/auth/register",
        screen: RegisterScreen
    },
    {
        name: "Forgot Password",
        path: "/auth/forgot-password",
        screen: ForgotPasswordScreen
    },
    {
        name: "Check Code Account",
        path: "/password-reset/:key",
        screen: CheckCodePassword
    },
    {
        name: "Check Code Account",
        path: "/confirm-account/:key",
        screen: CheckCodeAccount
    },

];

export default HomeRouterConstant;