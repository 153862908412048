import React, { useContext, useState } from "react";
import AdminWrapper from "../../components/admin/AdminWrapper";
import { getLocalStorage } from "../../utils/storage";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createCategory } from "../../store/actions/adminCategoryActions";
import store from "../../store";
import { sendNotify } from "../../hooks/notify/notify";
import InputField2 from "../../components/ui/InputField2";
import TextArea from "../../components/ui/TextArea";

import ButtonUi from "../../components/ui/ButtonUi";
import { validateName, validatePeriode, valideTeamSize } from "../../utils/validationUtils";
import { UserContext } from "../../context/userContext";

function CreateCategoryScreen() {
    const [state, setState] = useState({});
    const { token } = useContext(UserContext);
    const dispatch = useDispatch();
    const [categoryData, setCategoryData] = useState({
        name: "",
        description: "",
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function addCategory() {
        setLoading(true);
        dispatch(createCategory(token, categoryData))
            .then(() => {
                setState({ ...state, category: store.getState().adminCategory.category })
                sendNotify('success', 'Create category !', "Category created successfully")
                setLoading(false);
                navigate("/admin/categories/");
            }).catch((err) => {
                sendNotify('error', 'Create category error !', store.getState().adminCategory.message);
                setLoading(false);
            });
    }

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setCategoryData((prevFields) => ({ ...prevFields, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleSubmit = async () => {
        const newErrors = {};

        // Validation des champs avec les fonctions de validation
        newErrors.name = validateName(categoryData.name);
        newErrors.description = !categoryData.description ? "Description is required" : null;
        setErrors(newErrors);

        // Vérifie si toutes les valeurs dans newErrors sont null
        const areAllErrorsNull = Object.values(newErrors).every(
            (error) => error === null
        );

        if (areAllErrorsNull) {
            addCategory();
        }
    };

    return (
        <>
            <AdminWrapper>
                <div className=" h-40 flex items-center justify-center bg-[#2693d0]">
                    <div className="flex items-center space-x-2">
                        <h1 className="cursor-pointer select-none text-white text-5xl font-medium">Create Category</h1>
                    </div>
                </div>

                <div className="w-5/6 md:w-2/5 mx-auto my-10">

                    <div className="flex flex-col space-y-5 w-full">
                       
                        <InputField2
                            label={"Nom"}
                            type={"text"}
                            placeholder={"Entrez le Nom "}
                            name={"name"}
                            value={categoryData.name}
                            error={errors.name}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({ ...prevErrors, name: "" }))
                            }
                        />


                        <TextArea
                            label={"Descriptions"}
                            type={"text"}
                            placeholder={"Descriptions.. "}
                            name={"description"}
                            value={categoryData.description}
                            error={errors.description}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({ ...prevErrors, name: "" }))
                            }
                        />

                        <div className="flex justify-end w-full">

                            <div className="w-2/6">
                                <ButtonUi
                                    handleSubmit={handleSubmit}
                                    isLoading={loading}
                                    label={"Submit"}
                                />
                            </div>
                        </div>

                    </div>
                </div>

            </AdminWrapper>
        </>
    )
};

export default CreateCategoryScreen;