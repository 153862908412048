/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Update Info
const updateInfo = async (token, data) => {
    try {
        const response = await axios.put(`${API_URL}/users/me`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        // return response.data
        return { status: 200, message: "Profil Updated..." }

    } catch (error) {
        // console.error("An error occurred:", error);
        return { status: error.response.status, message: error.response.data.message }
    }
};

// Get All Challenges
const getAllChallenge = async (token) => {
    try {
        const response = await axios.get(`${API_URL}/users/me`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        return response.data
    } catch (error) {
        // console.error("An error occurred:", error);
    }
}

// GET Challenges by ID
const getChallenge = async (token, challenge_id) => {
    try {
        const response = await axios.get(`${API_URL}/challenges/${challenge_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        return response.data
        // return { status: 200, message: "Profil Updated..." }

    } catch (error) {
        // console.error("An error occurred:", error);
        throw error;
    }
};

// GET Hint a Challenge
const getHint = async (token, challenge_id) => {
    try {
        const response = await axios.get(`${API_URL}/challenges/${challenge_id}/hints`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        return response.data.content

    } catch (error) {
        // console.error("An error occurred:", error);
        return error.response.data.message

    }
};

// Send Submission
const sendSubmit = async (token, challenge_id, data) => {
    try {
        const response = await axios.post(`${API_URL}/submissions/${challenge_id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            }
        });
        // return response.data
        return { status: 200, message: "Profil Updated..." }

    } catch (error) {
        // console.error("An error occurred:", error);
        return { status: error.response.status, message: error.response.data.message }
    }
};

// check flag validity before Submission
const checkFlag = async (token, challenge_id, data) => {
    try {
        const response = await axios.put(`${API_URL}/submissions/${challenge_id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        return response;
    } catch (error) {
        // console.error("An error occurred:", error);
        return { status: error.response.status, message: error.response.data.message }
    }
};



// GET Notification
const getNotify = async (token) => {
    try {
        const response = await axios.get(`${API_URL}/notifications/news`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        return response.data

    } catch (error) {
        // console.error("An error occurred:", error);
        return error.response.data.message
    }
};

export default {
    updateInfo, getChallenge, getAllChallenge, getHint, sendSubmit, getNotify, checkFlag
};
