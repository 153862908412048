import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function CardHero() {
  return (
    <section className="bg-blue-900 text-white py-16 px-5">
      <div className="container mx-auto text-center">
        <FontAwesomeIcon
          icon={faShieldAlt}
          className="text-5xl text-yellow-500 mb-4"
        />
        <h1 className="text-2xl lg:text-4xl font-bold mb-4">
          Welcome to our cybersecurity challenge site
        </h1>
        <p className="text-lg mb-8">
          Take on our challenges and enhance your cybersecurity expertise.
        </p>
        <Link
          to="/challenges"
          className="bg-white text-blue-900 py-2 px-4 rounded-full text-lg font-semibold hover:bg-blue-100"
        >
          Get Started
        </Link>
      </div>
    </section>
  );
}

export default CardHero;
