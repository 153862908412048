import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

const InputField = ({
  type,
  name,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  password,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="w-full flex flex-col relative">
      <div className="flex justify-center">
        <input
          type={isPasswordVisible ? "text" : type} // Utilisez "text" pour afficher le mot de passe
          name={name}
          placeholder={placeholder}
          className={`w-full text-black py-3 my-2 bg-transparent border-t-0 border-x-0 border-b-1 outline-none focus:outline-none ${
            error ? "border-red-500" : "border-black"
          }`}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        <button
          type="button"
          className={`absolute transform -translate-y-1/2 focus:outline-none ${
            error ? "top-[35%] right-4" : "top-1/2 right-4"
          }`}
          onClick={togglePasswordVisibility}
        >
          {password &&
            (isPasswordVisible ? (
              <FontAwesomeIcon icon={faEyeSlash} /> // Icône pour masquer le mot de passe
            ) : (
              <FontAwesomeIcon icon={faEye} />
            ))}
        </button>
      </div>

      {error && <div className="text-[#ff0000]">{error}</div>}
    </div>
  );
};

export default InputField;
