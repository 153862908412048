import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";

const CountrySelect = ({ onCountryChange, error, onBlur, country, label }) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const formattedCountries = response.data.map((country) => ({
          value: country.cca3,
          label: country.name.common,
          flag: country.flags.png,
        }));
        setCountries(formattedCountries);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  useEffect(() => {
    // Update the selected country when the `country` prop changes
    if (country) {
      const selectedOption = countries.find((option) => option.label === country);
      setSelectedCountry(selectedOption);
    }
  }, [country, countries]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    onCountryChange(selectedOption);
  };

  return (
    <div className="w-full text-black py-3 my-2 bg-transparent">
      {label && <label className="block mb-2 text-sm font-medium text-gray-900">{label}</label>}
      <Select
        value={selectedCountry}
        onChange={handleCountryChange}
        options={countries}
        placeholder="Select a country"
        onBlur={onBlur}
        getOptionLabel={(option) => (
          <div className="w-full flex">
            <img
              src={option.flag}
              alt={`${option.label} flag`}
              style={{ width: "30px", marginRight: "10px" }}
            />
            <span> {option.label}</span>
          </div>
        )}
      />
      {error && <div className="text-[#ff0000]">{error}</div>}
    </div>
  );
};

export default CountrySelect;
