// eslint-disable-next-line no-unused-vars
import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminRouterConstant from "../constants/admin/routerConstant";
import ErrorScreen from "../screens/common/ErrorScreen";

const AdminRoute = () => {
    return (
        <Routes>
            {
                AdminRouterConstant.map((route, index) => {
                    return <Route key={`${route.name}` + index} path={route.path} element={<route.screen />} />
                })
            }
            <Route path='*' element={<ErrorScreen />} />
        </Routes>
    )
};

export default AdminRoute;