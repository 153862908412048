// eslint-disable-next-line no-unused-vars
import React, { useContext } from "react";
import { UserContext } from "../context/userContext";
import { UserRole } from "../constants/common/userRole";
import HomeRouter from "./homeRouter";
import AdminRoute from "./adminRouter";
import UserRouter from "./userRouter";
import Loading from "../components/ui/Loading";

const AppNavigation = () => {
    const { isLoading, user } = useContext(UserContext);

    const Navigator = () => {
        if (user) {
            switch (user.type) {
                case UserRole.ADMIN:
                    return <AdminRoute />

                case UserRole.USER:
                    return <UserRouter />;

                default:
                    return null;
            }
        } else {
            return <HomeRouter />
        }
    }

    return (
        <>
            {
                isLoading ? <div className="h-screen w-screen flex justify-center items-center">
                    <Loading />
                </div> : <Navigator />
            }
        </>
    )
};

export default AppNavigation;