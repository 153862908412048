import React from "react";
import AdminWrapper from "../../components/admin/AdminWrapper";

function ScoreboardScreen() {
    return (
        <>
            <AdminWrapper>
                <div className=" h-40 flex items-center justify-center bg-[#2693d0]">
                    <div className="flex items-center space-x-2">
                        <h1 className="cursor-pointer select-none text-white text-5xl font-medium">Scoreboard</h1>
                    </div>
                </div>

                <div className="w-5/6 md:w-[70%] mx-auto my-10">

                    <div className="w-3/5 mx-auto my-10">
                        <div>
                            <select id="Event" defaultValue={"event1"} className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 ">
                                <option value={"event1"}>Event 1</option>
                                <option value={"event2"}>Event 2</option>
                                <option value={"event3"}>Event 3</option>
                                <option value={"event4"}>Event 4</option>
                            </select>
                        </div>
                    </div>

                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
                        <table className="w-full text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-100 ">
                                <tr>
                                    <th scope="col" className="p-4">
                                        <div className="flex items-center">
                                            <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" />
                                            <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                        </div>
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Team/User
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        score
                                    </th>

                                </tr>
                            </thead>
                            <tbody>

                            </tbody>
                        </table>
                    </div>

                    <nav className="flex items-center justify-between pt-5" aria-label="Table navigation">
                        <span className="text-sm font-normal text-gray-500">Showing <span className="font-semibold text-gray-900">1-10</span> of <span className="font-semibold text-gray-900">1000</span></span>
                        <ul className="inline-flex -space-x-px text-sm h-8">
                            <li>
                                <button href="#" className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700">Previous</button>
                            </li>
                            <li>
                                <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">1</button>
                            </li>
                            <li>
                                <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">2</button>
                            </li>
                            <li>
                                <button href="#" aria-current="page" className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700">3</button>
                            </li>
                            <li>
                                <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">4</button>
                            </li>
                            <li>
                                <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">5</button>
                            </li>
                            <li>
                                <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700">Next</button>
                            </li>
                        </ul>
                    </nav>
                </div>

            </AdminWrapper>
        </>
    )
};

export default ScoreboardScreen;