import React, { useContext, useState } from "react";
import AdminWrapper from "../../components/admin/AdminWrapper";
import { useDispatch } from "react-redux";
import { createUser } from "../../store/actions/adminUsersActions";
import { sendNotify } from "../../hooks/notify/notify";
import store from "../../store";
import InputField2 from "../../components/ui/InputField2";
import CheckBox from "../../components/ui/CheckBox";
import CountrySelect2 from "../../components/ui/CountriesSelect2";
import SchoolSelect2 from "../../components/ui/SchoolSelect2";
import { validateEmail, validateName, validatePassword, validatePasswordConfirm, validateSchool } from "../../utils/validationUtils";
import ButtonUi from "../../components/ui/ButtonUi";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "../../utils/storage";
import { UserContext } from "../../context/userContext";


function CreateUserScreen() {
    const [state, setState] = useState({});
    const dispatch = useDispatch();
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        password: "",
        isStudent: "",
        school: "",
        country: "",
        confirm_password: "",
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const { token } = useContext(UserContext);
    const navigate = useNavigate();

    async function addUser() {
        setLoading(true);
        dispatch(createUser(token, userData))
            .then(() => {
                setState({ ...state, otherUser: store.getState().adminUser.users })
                sendNotify('success', 'Create user !', "User created successfully")
                setLoading(false);
                navigate("/admin/users/");
            }).catch((err) => {
                sendNotify('error', 'Create Users error !', store.getState().adminUser.message);
                setLoading(false);
            })
    }

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevFields) => ({ ...prevFields, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    // Select Country
    const handleCountrySelection = (selectedCountry) => {
        setUserData((prevFields) => ({
            ...prevFields,
            country: selectedCountry.label,
        }));
    };
    // Checked isStudent
    const handleStudentChange = (isChecked) => {
        setUserData((prevFields) => ({
            ...prevFields,
            isStudent: isChecked ? "yes" : "no",
        }));
    };

    const handleSchoolChange = (school) => {
        setUserData((prevFields) => ({
            ...prevFields,
            school: school,
        }));
    }

    const handleSubmit = async () => {
        const newErrors = {};

        // Validation des champs avec les fonctions de validation
        newErrors.country = !userData.country ? "Country is required" : null;
        newErrors.name = validateName(userData.name);
        newErrors.email = validateEmail(userData.email);
        newErrors.password = validatePassword(userData.password);
        newErrors.confirm_password = validatePasswordConfirm(
            userData.password,
            userData.confirm_password
        );

        if (userData.isStudent === "yes") {
            newErrors.school = validateSchool(userData.school);
        }

        setErrors(newErrors);

        // Vérifie si toutes les valeurs dans newErrors sont null
        const areAllErrorsNull = Object.values(newErrors).every(
            (error) => error === null
        );

        if (areAllErrorsNull) {
            addUser();
        }
    };

    return (
        <>
            <AdminWrapper>
                <div className=" h-40 flex items-center justify-center bg-[#2693d0]">
                    <div className="flex items-center space-x-2">
                        <h1 className="cursor-pointer select-none text-white text-5xl font-medium">Create User</h1>
                    </div>
                </div>

                <div className="w-5/6 md:w-2/5 mx-auto my-10">
                    <div className="flex flex-col space-y-5">
                        <InputField2
                            label={"Nom"}
                            type={"text"}
                            placeholder={"Entrez le Nom "}
                            name={"name"}
                            value={userData.name}
                            error={errors.name}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({ ...prevErrors, name: "" }))
                            }
                        />

                        <InputField2
                            label="Email"
                            type="email"
                            name="email"
                            placeholder="Entrez l'email"
                            value={userData.email}
                            error={errors.email}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({ ...prevErrors, email: "" }))
                            }
                        />
                        <InputField2
                            label="Mot de passe"
                            type="password"
                            name="password"
                            placeholder="Entrez le mot de passe"
                            value={userData.password}
                            error={errors.password}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({ ...prevErrors, password: "" }))
                            }
                            password
                        />
                        <InputField2
                            label="Confirmation du mot de passe"
                            type="password"
                            name="confirm_password"
                            placeholder="Confirmez le mot de passe"
                            value={userData.confirm_password}
                            error={errors.confirm_password}
                            onChange={handleFieldChange}
                            onBlur={() =>
                                setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    confirm_password: "",
                                }))
                            }
                            password
                        />

                        {/* Country Select */}
                        <CountrySelect2
                            onCountryChange={handleCountrySelection}
                            error={errors.country}
                            label="Pays"
                        />

                        {/* Checkbox for isStudent */}
                        <CheckBox
                            checked={userData.isStudent ? true : false}
                            onChange={handleStudentChange}
                            label={"Etudiant ?"}
                        />

                        {/* Conditional rendering of school input */}
                        {userData.isStudent === "yes" && (
                            <SchoolSelect2
                                type="text"
                                name="school"
                                placeholder="Enter your school"
                                value={userData.school}
                                error={errors.school}
                                onChange={handleSchoolChange}
                                onBlur={() =>
                                    setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        school: "",
                                    }))
                                }
                            />
                        )}

                        <div className="flex justify-end w-full">
                            <ButtonUi
                                handleSubmit={handleSubmit}
                                isLoading={loading}
                                label={"Submit"}
                            />
                        </div>
                    </div>
                </div>
            </AdminWrapper>
        </>
    )
};

export default CreateUserScreen;