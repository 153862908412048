import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteEvent, updateEvent } from "../../../store/actions/adminEventsActions";
import { sendNotify } from "../../../hooks/notify/notify";
import store from "../../../store";
import InputField2 from "../../ui/InputField2";
import { validateName, validatePeriode, valideTeamSize } from "../../../utils/validationUtils";
import TextArea from "../../ui/TextArea";
import ButtonUi from "../../ui/ButtonUi";
import { eventScope } from "../../../constants/admin/EventScope";
import { eventMode } from "../../../constants/admin/EventMode";

const EventModal = ({ token, showModal, modalType, onDismiss, hideConfirmation, event, onUpdate }) => {
    const [eventData, setEventData] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function updateOneEvent() {
        setLoading(true);
        dispatch(updateEvent(token, eventData))
            .then(() => {
                sendNotify('success', 'Update event !', "Event updated successfully")
                onUpdate({ user: store.getState().adminEvent.otherEvent })
                setLoading(false);
            }).catch((err) => {
                sendNotify('error', 'Update event error !', store.getState().adminEvent.message);
                setLoading(false);
            })
    }

    async function deleteOneEvent() {
        dispatch(deleteEvent(token, eventData._id))
            .then(() => {
                sendNotify('success', 'Delete event !', "Event deleted successfully");
                navigate("/admin/events");
            })
            .catch(() => {
                sendNotify('error', 'Delete error !', store.getState().adminUser.message)
            })
    }

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        if (name === "minTeamSize" || name === "maxTeamSize") {
            e.target.value = value.replace(/[^0-9]/g, '');
            setEventData((prevFields) => ({ ...prevFields, [name]: value.replace(/[^0-9]/g, '') }));
        } else {
            setEventData((prevFields) => ({ ...prevFields, [name]: value }));
        }
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleSubmit = async () => {
        const newErrors = {};

        // Validation des champs avec les fonctions de validation
        newErrors.name = validateName(eventData.name);
        newErrors.description = !eventData.description ? "Description is required" : null;
        newErrors.periode = validatePeriode(eventData.startDate, eventData.endDate);
        if(eventData.isSolo !== "solo"){
            newErrors.teamsize = valideTeamSize(eventData.minTeamSize, eventData.maxTeamSize);
        }
        setErrors(newErrors);

        // Vérifie si toutes les valeurs dans newErrors sont null
        const areAllErrorsNull = Object.values(newErrors).every(
            (error) => error === null
        );

        if (areAllErrorsNull) {
            updateOneEvent();
        }
    };

    useEffect(() => {
        setEventData(event);
    }, [event])


    return (
        showModal &&
        <div className="h-screen bg-black absolute w-full z-10 top-0 bg-opacity-50" onClick={(e) => onDismiss(e)}>

            {
                modalType === "edit" ?
                    <div className="w-5/6 md:w-2/5 mx-auto my-10 bg-white p-10 rounded-lg">
                        <div className="flex flex-col space-y-5">
                            <InputField2
                                label={"Nom"}
                                type={"text"}
                                placeholder={"Entrez le Nom "}
                                name={"name"}
                                value={eventData.name}
                                error={errors.name}
                                onChange={handleFieldChange}
                                onBlur={() =>
                                    setErrors((prevErrors) => ({ ...prevErrors, name: "" }))
                                }
                            />

                            <TextArea
                                label={"Descriptions"}
                                type={"text"}
                                placeholder={"Descriptions.. "}
                                name={"description"}
                                value={eventData.description}
                                error={errors.description}
                                onChange={handleFieldChange}
                                onBlur={() =>
                                    setErrors((prevErrors) => ({ ...prevErrors, name: "" }))
                                }
                            />

                            <div>
                                <label htmlFor="scope" className="block mb-2 text-sm font-medium text-gray-900">Scope</label>
                                <select name="scope" id="scope" defaultValue={eventScope.EPITECH} className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 " onChange={handleFieldChange}>
                                    {
                                        Object.keys(eventScope).map((key, index) => {
                                            return <option key={index} value={eventScope[key]}>{eventScope[key].toUpperCase()}</option>
                                        })
                                    }
                                </select>
                            </div>

                            <div>
                                <label htmlFor="periode" className="block mb-2 text-sm font-medium text-gray-900">Periode</label>
                                <div className="flex space-x-3 items-center">
                                    <input type="date" name="startDate" className="w-full border-gray-300 text-gray-500 rounded-md " onChange={handleFieldChange} />
                                    <p className="text-gray-500"> au</p>
                                    <input type="date" name="endDate" className="w-full border-gray-300 text-gray-500 rounded-md " onChange={handleFieldChange} />
                                </div>
                                {errors.periode && <div className="text-[#ff0000]">{errors.periode}</div>}
                            </div>

                            <div>
                                <label htmlFor="isSolo" className="block mb-2 text-sm font-medium text-gray-900">Mode</label>
                                <select id="isSolo" name="isSolo" defaultValue={eventMode.SOLO} className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" onChange={handleFieldChange}>
                                    {
                                        Object.keys(eventMode).map((key, index) => {
                                            return <option key={index} value={eventMode[key]}>{eventMode[key].toUpperCase()}</option>
                                        })
                                    }
                                </select>
                            </div>

                            <div>
                                <label htmlFor="teammate" className="block mb-2 text-sm font-medium text-gray-900">Teammate</label>
                                <div className="flex space-x-3 items-center">
                                    <div className="flex flex-col w-full space-y-1">
                                        <input id="min" disabled={eventData.isSolo === "solo"} value={eventData.minTeamSize} name="minTeamSize" type="text" className="w-full border-gray-300 text-gray-500 rounded-md "
                                            onChange={handleFieldChange}
                                        />
                                        <label className="text-xs text-gray-500" htmlFor="min">Min</label>
                                    </div>
                                    <div className="flex flex-col w-full space-y-1">
                                        <input id="max" disabled={eventData.isSolo === "solo"} value={eventData.maxTeamSize} name="maxTeamSize" type="text" className="w-full border-gray-300 text-gray-500 rounded-md "
                                            onChange={handleFieldChange}
                                        />
                                        <label className="text-xs text-gray-500" htmlFor="max">Max</label>
                                    </div>
                                </div>
                                {errors.teamsize && <div className="text-[#ff0000]">{errors.teamsize}</div>}
                            </div>

                            <div className="flex justify-end w-full">

                                <div className="w-2/6">
                                    <ButtonUi
                                        handleSubmit={handleSubmit}
                                        isLoading={loading}
                                        label={"Submit"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="w-1/5 mx-auto bg-white px-5 py-5 rounded-lg mt-10">
                        <p className="text-center text-lg">Souhaitez-vous vraiment supprimez l'évènement ?</p>

                        <div className="flex justify-center mt-8 space-x-4">
                            <div className="bg-red-500 px-3 py-1 rounded-md cursor-pointer" onClick={() => deleteOneEvent()}>
                                <p className="text-white text-base ">confirmer</p>
                            </div>

                            <div className="bg-gray-500 px-3 py-1 rounded-md cursor-pointer" onClick={(e) => hideConfirmation()}>
                                <p className="text-white text-base ">Annuler</p>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default EventModal;