import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import UserWrapper from "../../components/user/common/UserWrapper";
import ERROR404 from "../../assets/404.png";

function ErrorScreen() {
  const [countdown, setCountdown] = useState(7);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        clearInterval(timer);
        // Redirect to the specified route after the countdown.
        navigate("/");
      }
    }, 1000);

    return () => {
      clearInterval(timer); // Clean up the timer in case the component is unmounted.
    };
  }, [countdown, navigate]);

  return (
    // <UserWrapper>
    <div className="bg-white min-h-screen flex flex-col items-center justify-center text-white">
      <img
        src={ERROR404}
        alt="Error 404"
        // className="w-[60%] h-[250px]"
      />

      {/* <h1 className="text-blue-900 text-4xl font-semibold mb-4">404 Not Found</h1> */}
      <p className="text-blue-900 text-xl mb-2">
        The page you are looking for does not exist.
      </p>
      <p className="text-xl text-blue-900">
        You will be redirected to the home page in {countdown} seconds.
      </p>
      <Link to="/" className="mt-2 underline text-blue-900">
        Back to Home Page
      </Link>
    </div>
    // </UserWrapper>
  );
}

export default ErrorScreen;
