import React, { useContext, useEffect, useState } from "react";
import AdminWrapper from "../../components/admin/AdminWrapper";
import Tabs from "../../components/ui/tabs/Tabs";
import { useParams } from "react-router-dom";
import { getUser } from "../../store/actions/adminUsersActions";
import { useDispatch } from "react-redux";
import store from "../../store";
import { sendNotify } from "../../hooks/notify/notify";
import Loading from "../../components/ui/Loading";
import UserModal from "../../components/admin/user/UserModal";
import { getLocalStorage } from "../../utils/storage";
import { UserContext } from "../../context/userContext";

const initialState = {
    user: null,
    perPage: 10,
    page: 1,
    totalRows: 0,
    order: 'asc',
}

function DetailUserScreen(props) {
    let [state, setState] = useState(initialState);
    const [showModal, setshowModal] = useState(false);
    const [modalType, setModalType] = useState("edit");
    const [loading, setLoading] = useState(true);
    const { token } = useContext(UserContext);
    const dispatch = useDispatch();
    const userId = useParams()?.id;


    const toggleModal = (type) => {
        setModalType(type);
        setshowModal(!showModal); //
    }

    const onDismiss = (e) => {
        if (e && e.target === e.currentTarget) {
            setshowModal(false);
            return;
        }
    }

    async function getOneUser() {
        dispatch(getUser(token, userId))
            .then(() => {
                setState({ ...state, user: store.getState().adminUser.otherUser })
                setLoading(false);
            })
            .catch(() => {
                sendNotify('error', 'Users error !', store.getState().adminUser.message)
            })
    }

    useEffect(() => {
        getOneUser();
    }, [])

    return (
        <>
            <AdminWrapper>
                {!loading ? <>
                    <div className="pb-5 flex flex-col items-center justify-center bg-[#2693d0]">
                        <div className="flex flex-col items-center space-y-4">
                            <h1 className="cursor-pointer select-none text-white text-4xl font-medium">{state.user.name}</h1>
                            <div className="flex space-x-3">
                                <div className={`${state.user.active === "yes" ? "bg-green-500" : "bg-red-500"} text-white text-sm flex justify-center rounded-full py-0.5 px-5`}>
                                    {state.user.active === "yes" ? "Active" : "Inactive"}
                                </div>

                                <div className={`${state.user.enable === "enabled" ? "bg-green-500" : "bg-red-500"} text-white text-sm flex justify-center rounded-full py-0.5 px-5`}>
                                    {state.user.enable === "enabled" ? "Enabled" : "Disabled"}
                                </div>
                            </div>

                            <h1 className="text-2xl text-white font-medium">{state.user.email}</h1>
                        </div>
                        <div className="bg-white h-0.5 w-1/3 my-4 bg-opacity-40" />
                        <div className="flex space-x-3 items-center">
                            <div className="cursor-pointer" onClick={() => {
                                toggleModal("edit")
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                            </div>

                            <div className="cursor-pointer" onClick={() => {
                                toggleModal("delete")
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7 text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="w-5/6 md:w-[70%] mx-auto my-10">
                        <Tabs items={["Solves", "Fails"]} current={'Solves'} onChange={(tab) => {}}/>

                        <div className="w-full flex justify-end mt-5">
                            <div className="py-2 px-3 border border-red-400 text-red-500 hover:bg-red-500 hover:text-white rounded-md hover:cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </div>
                        </div>

                        <hr className="my-5 w-full" />

                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-10">
                            <table className="w-full text-sm text-left text-gray-500">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-100 ">
                                    <tr>
                                        <th scope="col" className="p-4">
                                            <div className="flex items-center">
                                                <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" />
                                                <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Event
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Challenge
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Category
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Type
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Value
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>

                        <nav className="flex items-center justify-between pt-5" aria-label="Table navigation">
                            <span className="text-sm font-normal text-gray-500">Showing <span className="font-semibold text-gray-900">1-10</span> of <span className="font-semibold text-gray-900">1000</span></span>
                            <ul className="inline-flex -space-x-px text-sm h-8">
                                <li>
                                    <button href="#" className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700">Previous</button>
                                </li>
                                <li>
                                    <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">1</button>
                                </li>
                                <li>
                                    <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">2</button>
                                </li>
                                <li>
                                    <button href="#" aria-current="page" className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700">3</button>
                                </li>
                                <li>
                                    <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">4</button>
                                </li>
                                <li>
                                    <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">5</button>
                                </li>
                                <li>
                                    <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700">Next</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </> :
                    <div className="w-full flex mt-24 justify-center">
                        <Loading />
                    </div>
                }

            </AdminWrapper>
            <UserModal
                token={token}
                user={state.user}
                showModal={showModal}
                onDismiss={onDismiss}
                modalType={modalType}
                onUpdate={(payload) => {
                    setState({ ...state, ...payload });
                }}
                hideConfirmation={() => setshowModal(false)}
            />

        </>
    )
};

export default DetailUserScreen;